import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const LightDebtorSchema = z.object({
  id: z.number(),
  fullName: z.string(),
});

const LightDebtorsSchema = z.array(LightDebtorSchema);

export type LightDebtors = z.infer<typeof LightDebtorsSchema>;

export type LightDebtor = z.infer<typeof LightDebtorSchema>;

type LightDebtorsVariables = {
  query: string;
};

export async function loadLightDebtorsQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;
  const { data } = await instance.get('/debtors/light', { params: queryKey[1] });

  return LightDebtorsSchema.parse(data);
}

export const useLoadLightDebtors = (variables: LightDebtorsVariables) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<LightDebtors, ApiError>({
    queryKey: ['light-debtors', variables],
    queryFn: (context) => loadLightDebtorsQueryFn(axiosInstance, context),
    placeholderData: keepPreviousData,
  });

  return addResourceNameToQueryResult<LightDebtors, unknown, 'lightDebtors'>(
    'lightDebtors',
    queryResult,
  );
};
