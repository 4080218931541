import {
  CreatePaymentPlanBody,
  CreatePaymentStepCalculationBody,
  PaymentStepCalculationResponse,
} from 'api/models';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { isDefined } from 'remeda';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import RecalculateDatesModal from 'shared/components/RecalculateDatesModal';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, DateSelector, Input } from 'shared/io';
import { currencySymbol } from 'shared/utils';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { CurrencyCode } from 'types/currency';

import { adjustNextAmounts, adjustNextDates } from '../PaymentPlan.utils';

import { PaymentPlanFormStepsProperties } from './PaymentPlanFormStepsProperties';
import { PaymentPlanFormWrapper } from './PaymentPlanFormWrapper';

import styleIdentifiers from './PaymentPlanForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export type PartialCreatePaymentPlanBody = Pick<CreatePaymentPlanBody, 'payment_steps_attributes'>;

type PaymentPlanFormStepsProps = {
  recalculateSteps: Function;
  paymentProperties: CreatePaymentStepCalculationBody;
  paymentSteps: PaymentStepCalculationResponse;
  onSubmit: SubmitHandler<PartialCreatePaymentPlanBody>;
};

export const PaymentPlanFormSteps = ({
  recalculateSteps,
  paymentProperties,
  paymentSteps,
  onSubmit,
}: PaymentPlanFormStepsProps) => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const canUseAdvancedPaymentPlans = company.package.can_use_advanced_payment_plans;

  const { due_date, delay } = paymentProperties;
  const { debtor, payment_steps_attributes, total_amount } = paymentSteps;

  const form = useForm<PartialCreatePaymentPlanBody>({
    shouldUnregister: true,
    defaultValues: {
      payment_steps_attributes,
    },
  });

  const {
    control,
    formState: { errors },
    watch,
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = form;

  const { append, fields, remove } = useFieldArray<PartialCreatePaymentPlanBody>({
    control,
    name: 'payment_steps_attributes',
  });

  const steps = watch('payment_steps_attributes') || [];

  const stepsTotal =
    Math.round(steps.reduce((acc, { amount }) => acc + Number(amount), 0) * 100) / 100;
  const doAmountsMatch = Number(total_amount) === stepsTotal;

  const getMinDate = (index: number) => {
    const lastStep = steps[index - 1];

    if (isDefined(lastStep)) return dayjs(lastStep.due_date).add(1, 'day').toDate();
    return dayjs(due_date).toDate();
  };

  const askRecalculation = (index) => {
    if (index === fields.length - 1) return;

    const { payment_steps_attributes: currentSteps, ...rest } = getValues();

    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      children: (
        <RecalculateDatesModal
          text={t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.ADJUST_NEXT_DATES)}
          initialDelay={paymentProperties.delay}
          onConfirm={(newDelay: number) => {
            reset({
              ...rest,
              payment_steps_attributes: adjustNextDates(currentSteps, index, newDelay),
            });
          }}
        />
      ),
    });
  };

  const handleAdjustNextAmounts = (index: number) => {
    const { payment_steps_attributes: currentSteps, ...rest } = getValues();

    reset({
      ...rest,
      payment_steps_attributes: adjustNextAmounts(currentSteps, index, Number(total_amount || 0)),
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PaymentPlanFormWrapper
          actions={
            <Button
              type="submit"
              label={t(i18nKeys.SAVE)}
              color={ButtonColor.MAIN}
              disabled={!doAmountsMatch}
              noMargin
            />
          }
        >
          <PaymentPlanFormStepsProperties
            recalculateSteps={recalculateSteps}
            paymentProperties={paymentProperties}
            paymentSteps={paymentSteps}
          />
          <Card
            className={styles('steps-details')}
            title={t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.PAYMENT_STEPS)}
            infosRight={
              <Button
                small
                noMargin
                noShadow
                title={!canUseAdvancedPaymentPlans ? t(i18nKeys.NOT_INCLUDED) : ''}
                label={t(i18nKeys.ACTIONS.ADD)}
                iconRight={IconName.PLUS}
                disabled={!canUseAdvancedPaymentPlans}
                onClick={() =>
                  append({
                    amount: Math.round((Number(total_amount) - stepsTotal) * 100) / 100,
                    due_date: dayjs(steps[steps.length - 1].due_date)
                      .add(delay, 'day')
                      .toISOString(),
                  })
                }
              />
            }
          >
            <div className={styles('headers')}>
              <div className={styles('header')}>{t(i18nKeys.DATE)}</div>
              <div className={styles('header')}>
                {t(i18nKeys.AMOUNT_CURRENCY, {
                  text: currencySymbol(debtor.attributes.currency),
                })}
              </div>
              <div className={styles('header')}> </div>
            </div>
            <div className={styles('steps')}>
              {fields.map((field, index) => {
                register(`payment_steps_attributes.${index}.due_date`);
                return (
                  <div key={field.id} className={styles('step')}>
                    <DateSelector
                      required
                      name={`payment_steps_attributes.${index}.due_date`}
                      className={styles('due-date-input')}
                      minDate={dayjs(getMinDate(index))}
                      noMargin
                      noBorder
                      disabled={!canUseAdvancedPaymentPlans}
                      withBorder={canUseAdvancedPaymentPlans}
                      centered
                      handleChange={(value) => {
                        setValue(`payment_steps_attributes.${index}.due_date`, value);
                        askRecalculation(index);
                      }}
                    />
                    <Input
                      className={styles('amount-input')}
                      disabled={!canUseAdvancedPaymentPlans}
                      withBorder={canUseAdvancedPaymentPlans}
                      register={register(`payment_steps_attributes.${index}.amount`, {
                        required: true,
                        min: 0.01,
                      })}
                      errorMessage={get(errors, `payment_steps_attributes.${index}.amount`)}
                      noMargin
                      type="number"
                      step="0.01"
                    />
                    <div className={styles('icons-wrapper')}>
                      {canUseAdvancedPaymentPlans && fields.length > 1 && (
                        <>
                          {index !== fields.length - 1 ? (
                            <Icon
                              title={t(
                                i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.ADJUST_NEXT_AMOUNTS,
                              )}
                              name={IconName.MINIMAL_DOWN}
                              onClick={() => handleAdjustNextAmounts(index)}
                            />
                          ) : (
                            <span className={styles('empty-icon')} />
                          )}
                          <Icon name={IconName.TRASH_SIMPLE} onClick={() => remove(index)} />
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles('steps-footer')}>
              <div className={styles('total', doAmountsMatch ? 'green' : 'red')}>
                <Amount value={stepsTotal} suffix={debtor.attributes.currency} />
                {doAmountsMatch ? (
                  ''
                ) : (
                  <Amount
                    value={Math.round((stepsTotal - Number(total_amount)) * 100) / 100}
                    suffix={debtor.attributes.currency}
                  />
                )}
              </div>
            </div>
          </Card>
          <div className={styles('bottom-actions')}>
            <Button
              type="submit"
              label={t(i18nKeys.SAVE)}
              color={ButtonColor.MAIN}
              disabled={!doAmountsMatch}
              noMargin
            />
          </div>
        </PaymentPlanFormWrapper>
      </form>
    </FormProvider>
  );
};
