import { useEffect, useState } from 'react';
import { Address, EmailAttributes, Id } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddContactPersonButton from 'shared/components/AddContactPersonButton/AddContactPersonButton';
import EmailRendering from 'shared/components/EmailRendering/EmailRendering';
import AddressPartialForm from 'shared/forms/partial/AddressPartialForm';
import { Button, Checkbox, CustomSelect } from 'shared/io';
import { email } from 'shared/utils/validation';
import { removeAttributes } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './formalNoticeForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues: any;
  debtorEmails?: EmailAttributes[];
  onChangeTemplate: (value: any) => void;
  setPreview?: any;
  customText?: string;
  batch?: boolean;
  addresses?: Address[];
  refetchSendingOptions?: () => void;
  debtorId?: Id;
};

export default function FormalNoticeForm({
  onSubmit,
  initialValues,
  debtorEmails,
  onChangeTemplate,
  setPreview,
  customText,
  batch,
  addresses,
  refetchSendingOptions,
  debtorId,
}: Props) {
  const { t } = useTranslation();
  const isLoading = useSelector((state: StoreState) => state.invoice.isLoading);
  const [formalNoticeTemplates, setFormalNoticeTemplates] = useState([]);
  const form = useForm({
    shouldUnregister: true,
    defaultValues: initialValues,
  });
  const { register, watch, handleSubmit } = form;

  useEffect(() => {
    settingsActions.templateListPage({
      step_type: 'formal_notice',
      callback: ({ data }) => {
        setFormalNoticeTemplates(data.map((item) => removeAttributes(item)));
      },
    });
  }, []);

  useEffect(() => {
    setPreview?.({ email: sendEmail });
  }, [setPreview == null]);

  const sendEmail = watch('send_email');

  const groupedTypes = [
    {
      description: t(i18nKeys.INVOICE.SELECTED_ONLY),
      value: '',
    },
    {
      description: t(i18nKeys.INVOICE.IN_PROGRESS),
      value: 'unpaid_invoices',
    },
    {
      description: t(i18nKeys.INVOICE.LATE_ONLY),
      value: 'overdue_invoices',
    },
    {
      description: t(i18nKeys.CLIENT_BALANCE),
      value: 'debtor_balance',
    },
  ];

  return (
    <FormProvider {...form}>
      <form
        className={styles('FormalNoticeForm', batch && 'batch')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <div className={styles('subtitle')}>
            {customText || t(i18nKeys.FORM.FORMAL_NOTICE.CAN_EDIT)}
          </div>
          <Controller
            rules={{ required: true }}
            defaultValue=""
            name="template_id"
            render={() => (
              <CustomSelect
                label={t(i18nKeys.FN_TEMPLATE)}
                keyText="name"
                keyValue="id"
                name="template_id"
                onValueChanged={onChangeTemplate}
                items={formalNoticeTemplates}
              />
            )}
          />
          {batch && (
            <Controller
              name="grouped_type"
              defaultValue=""
              render={() => (
                <CustomSelect
                  selectClassName={styles('input')}
                  keyText="description"
                  keyValue="value"
                  name="grouped_type"
                  items={groupedTypes}
                  label={t(i18nKeys.STATEMENT)}
                />
              )}
            />
          )}
          <Checkbox
            register={register('send_email')}
            watch={watch}
            label={t(i18nKeys.FORM.FORMAL_NOTICE.SEND_BY_MAIL_TOO)}
            onChange={() => setPreview?.({ email: !sendEmail })}
          />
          {!batch && refetchSendingOptions && (
            <AddContactPersonButton
              refetch={() => refetchSendingOptions()}
              debtorId={debtorId!}
              withMargin
            />
          )}
          {debtorEmails && sendEmail && (
            <Controller
              defaultValue=""
              rules={{ required: true }}
              name="emails"
              render={() => (
                <CustomSelect
                  className={styles('send-copy-email')}
                  multiple
                  canAdd
                  items={debtorEmails}
                  label={t(i18nKeys.FORM.FORMAL_NOTICE.EMAIL_TO_SEND)}
                  name="emails"
                  inputValidation={email}
                  keyValue="value"
                  itemRendering={({ value }) => <EmailRendering data={value} />}
                  registerMultipleInput={register('emailsinput' as any)}
                />
              )}
            />
          )}
          {!batch && <AddressPartialForm addresses={addresses} />}
        </div>
        <div className={styles('button-wrapper')}>
          <Button
            isLoading={isLoading}
            label={t(i18nKeys.SUBMIT)}
            type="submit"
            disabled={!batch && watch('addresses_attributes').length === 0}
          />
        </div>
      </form>
    </FormProvider>
  );
}
