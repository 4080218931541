import { i18nKeys, useTranslation } from 'locales';
import { PageTitle } from 'shared/layout';
import { useIsFeatureFlagEnabled } from 'shared/utils';

import { Grid } from '@mantine/core';

import { UpdatedAt } from '../components';
import { AgedBalanceCard } from '../components/cards';
import { CeiCard } from '../components/cards/CeiCard';
import { DsoCard } from '../components/cards/DsoCard';
import { ReminderEffectivenessCard } from '../components/cards/ReminderEffectivenessCard';
import { TickersBanner } from '../components/TickersBanner';

export const Overview = () => {
  const { t } = useTranslation();
  const canUseReminderPerformanceChart = useIsFeatureFlagEnabled('reminder-performance-chart');

  return (
    <>
      <PageTitle>
        {t(i18nKeys.ANALYTICS.TITLE)}
        <PageTitle.Actions>
          <UpdatedAt />
        </PageTitle.Actions>
      </PageTitle>
      <TickersBanner />
      <Grid style={{ overflow: 'visible' }}>
        <Grid.Col span={12}>
          <AgedBalanceCard />
        </Grid.Col>
        {canUseReminderPerformanceChart && (
          <Grid.Col span={12}>
            <ReminderEffectivenessCard />
          </Grid.Col>
        )}
        <Grid.Col span={6}>
          <CeiCard />
        </Grid.Col>
        <Grid.Col span={6}>
          <DsoCard />
        </Grid.Col>
      </Grid>
    </>
  );
};
