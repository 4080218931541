import { z } from 'zod';

export const REMINDER_TYPES = [
  'preventive_reminder',
  'first_reminder',
  'intermediate_reminder',
  'last_reminder',
  'additional_reminder',
  'formal_notice',
  'plan_creation',
  'plan_update',
  'plan_cancel',
] as const;

export type ReminderTypes = (typeof REMINDER_TYPES)[number];

export const ReminderTypesSchema = z.enum([...REMINDER_TYPES]);
