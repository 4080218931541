import { useEffect, useState } from 'react';
import { useSearchInvoicesInfinite } from 'api';
import { AttachedInvoice, CreateTaskBody, LightInvoice, SearchInvoicesParams } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { flat, map, pipe } from 'remeda';
import { CustomSelect } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { formatAmount, getNextPageParam } from 'shared/utils';

import styleIdentifiers from './TaskForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TaskFormInvoicesProps = {
  name?: 'invoice_ids';
  attachedInvoices?: AttachedInvoice[];
};

export const TaskFormInvoices = ({
  name = 'invoice_ids',
  attachedInvoices = [],
}: TaskFormInvoicesProps) => {
  const { t } = useTranslation();

  const { watch } = useFormContext<CreateTaskBody>();
  const debtor_id = watch('debtor_id');

  const [params, setParams] = useState<SearchInvoicesParams>({
    debtor_id,
    exclude: attachedInvoices.map((e) => e.id),
  });

  const { data: invoicesResponse, fetchNextPage } = useSearchInvoicesInfinite(params, {
    query: {
      getNextPageParam,
    },
  });

  useEffect(() => {
    if (debtor_id) {
      setParams((currentParams) => ({ ...currentParams, debtor_id }));
    }
  }, [debtor_id]);

  const pages = invoicesResponse?.pages ?? [];
  const items = pipe(
    pages,
    map((e) => e.data),
    flat(),
    map(treatInvoice<LightInvoice>),
  );

  const concatedInvoices = [
    ...attachedInvoices.map(treatInvoice<AttachedInvoice>),
    ...items.filter((e) => !attachedInvoices.some((el) => el.id === e.id)),
  ];

  return (
    <Controller
      name={name}
      render={() => (
        <CustomSelect
          selectClassName={styles('input')}
          filter
          withBorder
          multiple
          infiniteScroll
          load={fetchNextPage}
          onChangeFilter={(reference) => setParams((state) => ({ ...state, reference }))}
          items={concatedInvoices || []}
          keyValue="id"
          keyText="reference"
          name={name}
          label={t(i18nKeys.TASK.ATTRIBUTES.INVOICE)}
          itemRendering={({ item }) => {
            const { reference, total_tvac } = item;
            return (
              <div className={styles('invoice-item')}>
                <div className={styles('reference')}>{reference}</div>
                <div className={styles('comp')}>
                  {/* TODO: Missing in AttachedInvoice {debtor && <span>{debtor.full_name}</span>}
                    {debtor && total_tvac && <span> - </span>} */}
                  {total_tvac && <b>{formatAmount(total_tvac)}</b>}
                </div>
              </div>
            );
          }}
        />
      )}
    />
  );
};
