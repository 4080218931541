import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import TableFooter from 'shared/components/CustomTable/TableFooter';
import { Icon, IconName } from 'shared/components/Icon';
import ThirdpartyCaseListItem from 'shared/components/ThirdpartyCaseListItem';
import { ThirdPartyCasesFilters, useLoadThirdPartyCases, useProfile } from 'shared/hooks';

import { Center, Loader } from '@mantine/core';

import styleIdentifiers from './ThirdpartyCaseTable.module.scss';

const styles = classNames.bind(styleIdentifiers);

type State = {
  sort_order: 'asc' | 'desc' | null;
  sort_by: string | null;
  page_limit: number | null;
};

type ThirdpartyCaseTableProps = {};

export const ThirdPartyCaseTable = ({}: ThirdpartyCaseTableProps) => {
  const { t } = useTranslation();
  const profile = useProfile();

  const [sortData, setSortData] = useState<State>({
    sort_order: null,
    sort_by: null,
    page_limit: null,
  });
  const [params, setParams] = useState<ThirdPartyCasesFilters>({
    page_limit: profile.preferences.itemsPerPage,
    sort_order: 'asc',
    status: 'with_closed',
    page: 1,
  });
  const { thirdPartyCases, isThirdPartyCasesLoading } = useLoadThirdPartyCases(params);

  const THIRD_PARTY_CASES_TABLE_HEADERS = [
    {
      key: 'case_type',
      title: t(i18nKeys.CASE_TYPE),
      width: 140,
    },
    {
      key: 'case_reference',
      title: t(i18nKeys.FORM.REFERENCE),
      width: 160,
    },
    {
      key: 'created_at',
      title: t(i18nKeys.CREATION_DATE),
      width: 140,
    },
    {
      key: 'updated_at',
      title: t(i18nKeys.LAST_UPDATE),
      width: 140,
    },
    {
      key: 'status',
      title: t(i18nKeys.STATUS),
      width: 120,
    },
    {
      key: 'total_tvac',
      title: t(i18nKeys.TOTAL_VAT_PRICE),
    },
    {
      key: 'remaining_balance',
      title: t(i18nKeys.BALANCE),
    },
  ];

  const setPage = ({ selected }) => {
    setParams({
      ...params,
      page: selected + 1,
    });
  };

  const getOrderedInvoices = (key: string) => () => {
    const sortOrder = sortData.sort_order === 'asc' ? 'desc' : 'asc';

    setParams({
      ...params,
      sort_order: sortOrder,
      sort_by: key,
    });
    setSortData({
      ...sortData,
      sort_order: sortOrder,
      sort_by: key,
    });
  };

  const customKey = ({ case_type, id }) => case_type + id;

  const setPageLimit = (page_limit: number) => () => {
    setParams({
      ...params,
      page: 1,
      page_limit,
    });
    setSortData({
      ...sortData,
      page_limit,
    });
  };

  if (isThirdPartyCasesLoading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (!thirdPartyCases) {
    return null;
  }
  return (
    <div className={styles('thirdparty-case-table')}>
      <div className={styles('recovr-table')}>
        <table>
          <thead>
            <tr>
              <th className={styles('box-wrapper', 'small')} style={{ width: 30 }}>
                <div className={styles('not-sortable-head')} />
              </th>
              {THIRD_PARTY_CASES_TABLE_HEADERS.map((item, index) => (
                <th
                  key={index}
                  className={styles(
                    index === THIRD_PARTY_CASES_TABLE_HEADERS.length - 1 && 'last',
                    index === 0 && 'first',
                  )}
                >
                  {item.key ? (
                    <div className={styles('sortable-head')} onClick={getOrderedInvoices(item.key)}>
                      {item.title}
                      {sortData.sort_by === item.key && (
                        <Icon
                          name={IconName.TAILDOWN}
                          size="15px"
                          className={styles('arrow', sortData.sort_order === 'asc' && 'inverted')}
                        />
                      )}
                    </div>
                  ) : (
                    <div className={styles('not-sortable-head')}>{item.title}</div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {thirdPartyCases?.data.map((item) => (
              <ThirdpartyCaseListItem key={customKey(item)} item={item} noDebtorName />
            ))}
          </tbody>
        </table>
        {thirdPartyCases?.data.length === 0 && (
          <div className={styles('no-result')}>{t(i18nKeys.NO_RESULT)}</div>
        )}
      </div>
      {thirdPartyCases.metadata && (
        <TableFooter
          className={styles('table-footer')}
          pagination={thirdPartyCases.metadata.pagination}
          setPage={setPage}
          itemsLength={thirdPartyCases?.data.length}
          setPageLimit={setPageLimit}
        />
      )}
    </div>
  );
};
