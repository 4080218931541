import { nice, ticks } from 'd3-array';
import { i18nKeys, useTranslation } from 'locales';
import { clone, initial, last, mergeWith, subtract } from 'lodash-es';
import { first, isNullish } from 'remeda';
import { BarChartTooltip } from 'shared/charts';
import { AgedBalanceCompact, useAgedBalanceColors, useProfile } from 'shared/hooks';
import { formatAmount, useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils';

import { Box, useMantineTheme } from '@mantine/core';
import { ResponsiveBar } from '@nivo/bar';

const DESIRED_X_AXIS_TICKS = 6;

interface AgedBalanceChartProps {
  agedBalanceCompact?: AgedBalanceCompact;
}

export const AgedBalanceCompactChart = ({ agedBalanceCompact }: AgedBalanceChartProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const profile = useProfile();
  const colors = useAgedBalanceColors();
  const formatter = useSafeLocalizedCompactCurrencyFormatter();

  if (isNullish(agedBalanceCompact)) return null;

  const netAgedBalance = mergeWith(
    clone(agedBalanceCompact.debits),
    agedBalanceCompact.credits,
    subtract,
  );

  const barData = Object.entries(netAgedBalance).map(([key, value]) => ({
    value,
    label: key,
    color: colors[key],
  })) as Array<{ label: string; color: string; value: number }>;

  // Insert the total due amount which is not provided as such by the API
  const overdueAmount =
    netAgedBalance['0'] + netAgedBalance['30'] + netAgedBalance['60'] + netAgedBalance['90'];
  barData.splice(0, 0, { value: overdueAmount, label: 'totalDue', color: colors.totalDue });

  const barDataOrdered = [last(barData), ...initial(barData)].reverse() as typeof barData;

  const maxValue = Math.max(...barDataOrdered.map((bar) => bar.value));
  const minValue = Math.min(...barDataOrdered.map((bar) => bar.value));

  const [rangeStart, rangeEnd] = nice(minValue, maxValue, DESIRED_X_AXIS_TICKS);
  const xValues = ticks(rangeStart, rangeEnd, DESIRED_X_AXIS_TICKS);

  return (
    <Box w="100%" h={460}>
      <ResponsiveBar
        colors={(barDatum) => barDatum.data.color}
        margin={{ bottom: 25, left: profile.locale === 'nl' ? 150 : 120, right: 95 }}
        layout="horizontal"
        data={barDataOrdered}
        indexBy="label"
        keys={['value']}
        padding={0.42}
        isInteractive
        colorBy="indexValue"
        label={(d) => formatAmount(d.value, '.', ' ')}
        enableGridX
        enableGridY={false}
        labelSkipWidth={85}
        labelTextColor={theme.colors.gray[0]}
        tooltip={({ data: { value } }) => {
          // Fix for incorrect initial tooltip position, see https://github.com/plouc/nivo/issues/2161
          setTimeout(() => {
            const tooltip = document.getElementById('tooltip-fix');
            if (tooltip) tooltip.style.opacity = '1';
          }, 50);

          return (
            <BarChartTooltip
              id="tooltip-fix"
              style={{ opacity: 0, transition: 'all 0.1s ease-in-out' }}
            >
              {formatAmount(value, '.', ' ')}
            </BarChartTooltip>
          );
        }}
        axisBottom={{
          tickSize: 0,
          tickValues: xValues,
          format: (val) => formatter.format(val).replace(/(\.[0]+|,[0]+)$/, ''),
        }}
        axisLeft={{
          tickPadding: xValues[0] < 0 ? 25 : 15,
          tickSize: 0,
          format: (val) => t(i18nKeys.ANALYTICS.AGED_BALANCE[val]),
        }}
        animate={false}
        theme={{
          grid: {
            line: {
              stroke: theme.colors.gray[3],
              strokeDasharray: 7,
              strokeDashoffset: 15,
            },
          },
          labels: {
            text: {
              fontSize: 15,
              fontWeight: 500,
              fontFamily: 'Work Sans',
              fill: theme.colors.gray[6],
            },
          },
          text: {
            fontSize: 14,
            fontFamily: 'Work Sans',
            fill: theme.colors.gray[6],
          },
        }}
        gridXValues={xValues}
        layers={[
          'grid',
          'axes',
          'bars',
          ({ bars, innerWidth }) => {
            const [, secondBar, thirdBar] = [...bars].reverse();
            const secondBarEndPositionY = secondBar.y + secondBar.height;
            const halfwayPointY = (secondBarEndPositionY + thirdBar.y) / 2;

            return (
              <line
                x1={-65}
                y1={halfwayPointY}
                x2={innerWidth + 65}
                y2={halfwayPointY}
                stroke="#CED4DA"
                strokeWidth="1.5"
                strokeDasharray={7}
              />
            );
          },
          // See https://github.com/plouc/nivo/issues/146#issuecomment-1009184119
          ({ bars, labelSkipWidth }) => {
            const negativeBars = bars.filter((bar) => (bar.data.value ?? 0) < 0);
            const sortedNegativeBars = [...negativeBars].sort(
              (a, b) => (a.data.value ?? 0) - (b.data.value ?? 0),
            );
            const yAxisNegativeValuePadding = first(sortedNegativeBars)?.width ?? 0;

            return (
              <g>
                {bars.map(({ width, height, y, data }) =>
                  width < labelSkipWidth ? (
                    <text
                      key={data.value}
                      fontSize={16}
                      fontWeight={500}
                      fontFamily="Work Sans"
                      fill={theme.colors.gray[6]}
                      transform={`translate(${width + 10 + yAxisNegativeValuePadding}, ${
                        y + height / 2
                      })`}
                      textAnchor="left"
                      dominantBaseline="central"
                    >
                      {formatAmount(data.value, '.', ' ')}
                    </text>
                  ) : null,
                )}
              </g>
            );
          },
        ]}
      />
    </Box>
  );
};
