import { useEffect } from 'react';
import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  CompanyEdit,
  CustomPreferencesPartialForm,
  PostalPreferencesPartialForm,
} from 'shared/forms/PreferencesForm';
import { useLoadCompanyConfiguration } from 'shared/hooks';

import { Box, Stack, Title } from '@mantine/core';

import { MantineSettingsCard } from '../MantineSettingsCard';

type Props = {
  onSubmit: SubmitHandler<CompanyEdit>;
};

export const SendingSettings = ({ onSubmit }: Props) => {
  const { t } = useTranslation();

  const { company, isCompanyFetching } = useLoadCompanyConfiguration();

  const form = useForm<CompanyEdit>({
    defaultValues: company,
  });

  const {
    formState: { isDirty },
    reset,
    handleSubmit,
  } = form;

  useEffect(() => {
    if (!isCompanyFetching) {
      reset({
        ...company,
      });
    }
  }, [company, reset, isCompanyFetching]);

  return (
    <FormProvider {...form}>
      <MantineSettingsCard
        title={t(i18nKeys.FORM.PREFERENCES.PREFERENCES_SEND)}
        onSave={isDirty ? handleSubmit(onSubmit) : undefined}
      >
        <Title order={5}>{t(i18nKeys.EMAIL)}</Title>
        <Box ml="sm" mt="sm">
          <CustomPreferencesPartialForm />
        </Box>
        <Title mt="xl" mb="xs" order={5}>
          {t(i18nKeys.FORM.PREFERENCES.PREFERENCES_POSTAL_SEND)}
        </Title>
        <Box ml="sm">
          <PostalPreferencesPartialForm />
        </Box>
      </MantineSettingsCard>
    </FormProvider>
  );
};
