import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { CompanyConfiguration } from 'shared/hooks';
import { Button, CustomSelect } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { DescVal } from 'types/genericType';

import styleIdentifiers from './TemplateSendingOptions.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TemplateSendingOptionsProps = {
  state: any;
  setState: Function;
  lang: DescVal;
  customDomain: CompanyConfiguration['custom_domain'];
  defaultFrom: CompanyConfiguration['default_from'];
};

export default function TemplateSendingOptions({
  state,
  setState,
  lang,
  customDomain,
  defaultFrom,
}: TemplateSendingOptionsProps) {
  const { t } = useTranslation();
  const values = state[lang.value];
  const form = useForm({
    shouldUnregister: true,
    defaultValues: values,
  });

  const {
    register,
    getValues,
    formState: { isDirty },
  } = form;

  const submit = () => {
    const email = { ...state };
    email[lang.value] = {
      ...email[lang.value],
      ...getValues(),
      changed: true,
    };
    setState({
      email,
    });
    dialogHide(DialogShowId.CUSTOM);
  };

  const hasCustomDomainConfig = defaultFrom && customDomain;
  const customDomainAddress = hasCustomDomainConfig && `${defaultFrom}@${customDomain}`;
  const defaultEmail = hasCustomDomainConfig ? customDomainAddress : 'no-reply@recovr.eu';

  const fromEmailItems = [
    {
      value: 'default',
      description: `${t(i18nKeys.DEFAULT)} (${defaultEmail})`,
    },
  ];

  if (hasCustomDomainConfig) {
    const emailItems = [
      { value: 'no-reply@recovr.eu', description: 'no-reply@recovr.eu' },
      ...(customDomainAddress
        ? [{ value: customDomainAddress, description: customDomainAddress }]
        : []),
    ];

    fromEmailItems.push(...emailItems);
  }

  return (
    <div className={styles('template-sending-options')}>
      <div className={styles('email-form')}>
        <div className={styles('label-container')}>
          <div className={styles('subject-label')}>
            {t(i18nKeys.SUBJECT).toUpperCase()}
            <Tooltip
              icon={IconName.INFO}
              direction={DropdownDirection.RIGHT}
              contentClassName={styles('subject-tooltip')}
            >
              {t(i18nKeys.SUBJECT_VARIABLES)}:
              <div>
                <span>{t(i18nKeys.FORM.REFERENCE)}: </span>
                <span>{' {{reference}}'}</span>
              </div>
              <div>
                <span>{t(i18nKeys.COMPANY_NAME)}: </span>
                <span>{' {{company_name}}'}</span>
              </div>
              <div>
                <span>{t(i18nKeys.DEBTOR_NAME)}: </span>
                <span>{' {{debtor_name}}'}</span>
              </div>
              <div>
                <span>{t(i18nKeys.FORM.FIRSTNAME)}: </span>
                <span>{' {{first_name}}'}</span>
              </div>
              <div>
                <span>{t(i18nKeys.FORM.LASTNAME)}: </span>
                <span>{' {{last_name}}'}</span>
              </div>
              <div>
                <span>{t(i18nKeys.FORM.JOB_TITLE)}: </span>
                <span>{' {{job_title}}'}</span>
              </div>
              <div>
                <span>{t(i18nKeys.DEBTOR_EXTERNAL_ID)}: </span>
                <span>{' {{debtor_external_id}}'}</span>
              </div>
            </Tooltip>
          </div>

          <div className={styles('subject-label')}>
            FROM
            <Tooltip
              icon={IconName.INFO}
              direction={DropdownDirection.RIGHT}
              contentClassName={styles('subject-tooltip')}
            >
              {t(i18nKeys.TEMPLATE.CAN_USE_VARIABLE)} :
              <div>{t(i18nKeys.TEMPLATE.ACCOUNT_MANAGER_NAME_FIELD)}</div>
            </Tooltip>
          </div>
          <div className={styles('subject-label')}>
            REPLY TO
            <Tooltip
              icon={IconName.INFO}
              direction={DropdownDirection.RIGHT}
              contentClassName={styles('subject-tooltip')}
            >
              {t(i18nKeys.TEMPLATE.CAN_USE_VARIABLE)} :
              <div>{t(i18nKeys.TEMPLATE.ACCOUNT_MANAGER_NAME_FIELD)}</div>
              <div>{t(i18nKeys.TEMPLATE.ACCOUNT_MANAGER_EMAIL_FIELD)}</div>
            </Tooltip>
          </div>
        </div>
        <div className={styles('input-container')}>
          <div>
            <input
              type="text"
              className={styles(!values!.subject && 'error')}
              {...register('subject')}
            />
          </div>
          <div className={styles('double-field')}>
            <input
              type="text"
              className={styles(!values!.from_name && 'error', 'no-padding-right')}
              {...register('from_name')}
              placeholder={t(i18nKeys.NAME)}
            />
            <FormProvider {...form}>
              <Controller
                name="from_email"
                render={() => (
                  <CustomSelect
                    name="from_email"
                    keyText="description"
                    keyValue="value"
                    selectClassName={styles('from-email')}
                    valueClassName={styles('light-font')}
                    noBorder
                    notAllowed={!hasCustomDomainConfig}
                    items={fromEmailItems}
                    noMargin
                  />
                )}
              />
            </FormProvider>
          </div>
          <div className={styles('double-field')}>
            <input type="text" {...register('reply_to_name')} placeholder={t(i18nKeys.NAME)} />
            <input
              type="text"
              {...register('reply_to_email')}
              className={styles(values!.reply_to_email_error && 'error')}
              placeholder={t(i18nKeys.EMAIL)}
            />
          </div>
        </div>
      </div>
      <div className={styles('button-wrapper')}>
        <Button disabled={!isDirty} label={t(i18nKeys.CONFIRM)} onClick={submit} />
      </div>
    </div>
  );
}
