import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import moment from 'moment';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import { useHistory } from 'react-router';
import { isNonNullish } from 'remeda';
import { openEditClient } from 'shared/action-component/EditClientForm';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, CustomSelect, DateSelector, Input, MaskInput } from 'shared/io';
import { PageTitle } from 'shared/layout';
import { treatRecoveryPlan } from 'shared/serializer';
import { currencySymbol } from 'shared/utils';
import { useForceUpdate, useSearchDebtors } from 'shared/utils/hooks';
import { getCgv } from 'shared/utils/selectors';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { AVAILABLE_CURRENCIES } from 'types/currency';

import styleIdentifiers from './ImportPDFInvoice.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function ImportPdfInvoice() {
  const { t } = useTranslation();
  const history = useHistory();

  const forceUpdate = useForceUpdate();

  const { company } = useLoadCompanyConfiguration();
  const [files, setFiles] = useState<Array<File>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [currentFile, _setCurrentFile] = useState<number>(0);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [formValues, setFormValues] = useState<any[]>([]);
  const [recoveryPlans, setRecoveryPlans] = useState<any[]>([]);
  const [pdfMetadata, setPdfMetadata] = useState({
    pageNumber: 1,
    numberOfPages: 1,
  });

  const { debtors, getDebtors } = useSearchDebtors();

  const form = useForm({
    shouldUnregister: true,
    mode: 'onBlur',
    defaultValues: {
      invoice_params: {
        already_paid: '',
        currency: company.currency,
        debtor_id: '',
        due_date: '',
        issue_date: '',
        recovery_plan_id: 'auto',
        reference: '',
        structured_communication: '+++___/____/_____+++',
        total_tvac: '',
      },
    },
  });

  const {
    register,
    getValues,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
    trigger,
    setError,
  } = form;

  const { pageNumber, numberOfPages } = pdfMetadata;
  const issueDate = watch('invoice_params.issue_date');
  const totalInclVat = watch('invoice_params.total_tvac');
  const amountAlreadyPaid = watch('invoice_params.already_paid');
  const debtorId = watch('invoice_params.debtor_id');
  const currency = watch('invoice_params.currency');
  const structuredComm = watch('invoice_params.structured_communication');

  const setPdfView = (newFileUrl?: File | false) => {
    if (fileUrl) {
      const url = fileUrl;
      URL.revokeObjectURL(url);
    }

    if (newFileUrl) setFileUrl(URL.createObjectURL(newFileUrl));
    else setFileUrl('');
  };

  const setCurrentFile = (nextCurrentFile: number, formValuesToEdit?: any[]) => {
    if (nextCurrentFile === currentFile) setPdfView(files[currentFile] && files[currentFile]);

    if (formValuesToEdit) setFormValues(formValuesToEdit);
    else {
      const newFormValues = formValues;
      newFormValues[currentFile] = getValues();
      setFormValues(newFormValues);
    }

    _setCurrentFile(nextCurrentFile);
    let newFormValues = { invoice_params: { structured_communication: '' } };
    if (formValues[nextCurrentFile]?.invoice_params) {
      newFormValues = {
        invoice_params: {
          ...formValues[nextCurrentFile].invoice_params,
          structured_communication:
            formValues[nextCurrentFile].invoice_params?.structured_communication || '',
        },
      };
    }

    reset(newFormValues);
  };

  const companyCgv = getCgv(company);

  const setDueDate = (value: Date | string) => {
    setValue('invoice_params.due_date', moment.utc(value).toISOString());
  };

  const setIssueDate = (newIssueDate: Date) => {
    const date = moment.utc(newIssueDate);
    setValue('invoice_params.issue_date', date.toISOString());
    setDueDate(date.add(companyCgv?.minimum_payment_terms_delay, 'days').toISOString());
  };

  useEffect(() => {
    if (files.length !== 0) setPdfView(files[0]);
    else fileInputRef.current?.click();
  }, [files.length]);

  useEffect(() => {
    setPdfView(files[currentFile] && files[currentFile]);
  }, [files, currentFile]);

  const deleteCurrentInvoice = () => {
    const nextCurrentFile =
      currentFile !== 0 && files.length - 1 === currentFile ? currentFile - 1 : currentFile;
    const newFiles = files;
    newFiles.splice(currentFile, 1);
    setFiles(newFiles);

    const newFormValues = formValues;
    newFormValues.splice(currentFile, 1);

    if (newFiles.length > 0) setCurrentFile(nextCurrentFile, newFormValues);
    else forceUpdate();
  };

  const importInvoice = async () => {
    const isValid = await trigger();

    if (isValid) {
      const currentValues = getValues();
      const data = {};

      for (const key in currentValues.invoice_params) {
        if (Object.hasOwn(currentValues.invoice_params, key))
          data[`invoice_params.${key}`] = currentValues.invoice_params[key];
      }

      if (typeof data['invoice_params.debtor_id'] === 'object') {
        data['invoice_params.debtor_id'] = data['invoice_params.debtor_id'].id;
      }

      if (data['invoice_params.structured_communication']) {
        data['invoice_params.structured_communication'] = data[
          'invoice_params.structured_communication'
        ].replace(/[\+\/\_]/g, '');
      }

      const formData = new FormData();
      formData.append('file', files[currentFile]);

      Object.keys(data).forEach((el) => {
        formData.append(`${el.replace('.', '[')}]`, data[el]);
      });

      const isLastFile = files.length === 1;

      setIsLoading(true);
      invoiceActions.createInvoicePdf({
        data: formData,
        callback: () => {
          setIsLoading(false);
          deleteCurrentInvoice();
          form.reset();
          if (isLastFile) history.push('/invoices/listing');
        },
      });
    } else if (!debtorId) setError('invoice_params.debtor_id', { type: 'required' });
  };

  const onDocumentLoadSuccess = (pdf) => {
    setPdfMetadata({
      pageNumber: 1,
      numberOfPages: pdf._pdfInfo.numPages,
    });
  };

  const addClient = () => {
    openEditClient({
      callback: (newDebtor) => {
        setValue('invoice_params.debtor_id', newDebtor);
      },
    });
  };

  const getRecoveryPlans = () => {
    settingsActions.recoveryPlanList({
      noLoading: true,
      callback: ({ data }) => {
        setRecoveryPlans(data.map((recoveryPlan) => treatRecoveryPlan(recoveryPlan)));
      },
    });
  };

  const currentDebtor = debtors.find((e) => Number(e.id) === Number(debtorId));

  useEffect(() => {
    if (currentDebtor?.currency) {
      setValue('invoice_params.currency', currentDebtor.currency);
    }
  }, [currentDebtor]);

  const frenchStructuredCommError = (() => {
    if (!company.isFrenchClient) return undefined;
    if (structuredComm == null || structuredComm.length === 0) return undefined;

    if (!structuredComm.startsWith('RF')) return { type: 'test', message: 'ERROR.START_RF' };
    if (structuredComm.replace(' ', '').length < 5) return { type: 'test', message: 'ERROR.MIN_5' };
    if (structuredComm.replace(' ', '').length > 25)
      return { type: 'test', message: 'ERROR.MAX_25' };

    return undefined;
  })();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files: uploadedFiles } = event.target;
    if (isNonNullish(uploadedFiles)) {
      setFiles(Array.from(uploadedFiles));
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        multiple
        accept="application/pdf"
        onChange={handleFileChange}
      />
      <div className={styles('import-PDF-invoice')}>
        <PageTitle>
          {t(i18nKeys.INVOICING.IMPORT.PDF_INVOICES)}
          <PageTitle.Actions>
            <Button
              onClick={deleteCurrentInvoice}
              color={ButtonColor.GREY}
              iconLeft={IconName.TRASH_SIMPLE}
              noMargin
              label={t(i18nKeys.DELETE)}
            />
            <Button
              onClick={importInvoice}
              color={ButtonColor.MAIN}
              iconLeft={IconName.CHECK}
              noMargin
              isLoading={isLoading}
              label={t(i18nKeys.IMPORT)}
              disabled={frenchStructuredCommError != null}
            />
          </PageTitle.Actions>
        </PageTitle>
        <div className={styles('body')}>
          <div className={styles('pdf-actions')}>
            {numberOfPages > 1 && (
              <div className={styles('container-actions')}>
                {pageNumber > 1 && (
                  <Icon
                    name={IconName.MINIMAL_UP}
                    onClick={() => {
                      setPdfMetadata({
                        ...pdfMetadata,
                        pageNumber: pageNumber - 1,
                      });
                    }}
                  />
                )}
                {numberOfPages > pageNumber && (
                  <Icon
                    name={IconName.MINIMAL_DOWN}
                    onClick={() => {
                      setPdfMetadata({
                        ...pdfMetadata,
                        pageNumber: pageNumber + 1,
                      });
                    }}
                  />
                )}
              </div>
            )}
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={540}
                pageNumber={pageNumber || 1}
              />
            </Document>
          </div>
          <FormProvider {...form}>
            <form className={styles('fill-data-container')}>
              <div className={styles('title')}>
                <div>
                  {currentFile - 1 >= 0 && (
                    <Icon
                      name={IconName.CIRCLE_LEFT}
                      size="30px"
                      onClick={() => setCurrentFile(currentFile - 1)}
                    />
                  )}
                </div>
                <h2>
                  {t(i18nKeys.COMMON.INVOICE)} {currentFile + 1}/{files.length}
                </h2>
                <div>
                  {currentFile + 1 < files.length && (
                    <Icon
                      name={IconName.CIRCLE_RIGHT}
                      size="30px"
                      onClick={() => setCurrentFile(currentFile + 1)}
                    />
                  )}
                </div>
              </div>
              <div className={styles('input-button')}>
                <Controller
                  defaultValue=""
                  name="invoice_params.debtor_id"
                  rules={{ required: true }}
                  render={() => (
                    <CustomSelect
                      noMargin
                      className={styles('invoice-select')}
                      errorClassName={styles('invoice-select-error')}
                      noArrow
                      filter
                      load={getDebtors}
                      items={debtors}
                      keyText="full_name"
                      keyValue="id"
                      name="invoice_params.debtor_id"
                      placeholder={t(i18nKeys.INVOICING.CREATE.SELECT_CLIENT)}
                      placeholderFilter={t(i18nKeys.FORM.RESEARCH)}
                    />
                  )}
                />
                <Button className={styles('add-client')} label="+" onClick={addClient} noMargin />
              </div>
              <Controller
                defaultValue="auto"
                name="invoice_params.recovery_plan_id"
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    className={styles('invoice-select')}
                    errorClassName={styles('invoice-select-error')}
                    noArrow
                    filter
                    load={getRecoveryPlans}
                    items={[{ name: t(i18nKeys.AUTOMATIC), id: 'auto' }, ...recoveryPlans]}
                    keyText="name"
                    keyValue="id"
                    name="invoice_params.recovery_plan_id"
                    placeholder={t(i18nKeys.RECOVERY_PLAN)}
                    placeholderFilter={t(i18nKeys.FORM.RESEARCH)}
                  />
                )}
              />
              <Input
                register={register('invoice_params.reference', {
                  required: true,
                })}
                placeholder="INV-123"
                // @ts-ignore incorrectly typed
                errorMessage={errors.invoice_params?.reference && 'ERROR.FIELD_REQUIRED'}
                label={t(i18nKeys.FORM.INVOICE_REFERENCE)}
              />
              <div className={styles('oneline')}>
                <Controller
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  name="invoice_params.issue_date"
                  render={() => (
                    <DateSelector
                      name="invoice_params.issue_date"
                      className={styles('input')}
                      noMinDate
                      label={t(i18nKeys.ISSUE_DATE)}
                      handleChange={setIssueDate}
                    />
                  )}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  name="invoice_params.due_date"
                  render={() => (
                    <DateSelector
                      className={styles('input')}
                      label={t(i18nKeys.DUE_DATE)}
                      minDate={dayjs(issueDate, 'DD/MM/YYYY')}
                      handleChange={setDueDate}
                      name="invoice_params.due_date"
                    />
                  )}
                />
              </div>
              <Controller
                defaultValue={company?.currency}
                rules={{ required: true }}
                name="invoice_params.currency"
                render={() => (
                  <CustomSelect
                    name="invoice_params.currency"
                    items={AVAILABLE_CURRENCIES as any}
                    keyText="description"
                    keyValue="value"
                    label={t(i18nKeys.FORM.CLIENT.CURRENCY)}
                  />
                )}
              />

              <Input
                register={register('invoice_params.total_tvac', {
                  required: true,
                })}
                // @ts-ignore incorrectly typed
                errorMessage={errors.invoice_params?.total_tvac && 'ERROR.FIELD_REQUIRED'}
                type="number"
                label={t(i18nKeys.INVOICING.TOTAL_VAT_PRICE_CURRENCY, {
                  text: currencySymbol(currency, company),
                })}
              />
              {company.isFrenchClient ? (
                <Input
                  register={register('invoice_params.structured_communication')}
                  errorMessage={frenchStructuredCommError}
                  placeholder="RF__ ____ ____ ____ ____ ____ _"
                  label={t(i18nKeys.FORM.INVOICING.STRUCTURED_COMMUNICATION)}
                />
              ) : (
                <Controller
                  control={control}
                  defaultValue=""
                  name="invoice_params.structured_communication"
                  rules={{
                    validate: (val: string) =>
                      val?.indexOf('_') > -1 && val !== '+++___/____/_____+++'
                        ? 'ERROR.NOT_FULLY_FILLED'
                        : true,
                  }}
                  render={({ field: { ref, ...values } }) => (
                    <MaskInput
                      {...values}
                      name="invoice_params.structured_communication"
                      error={errors.invoice_params?.structured_communication}
                      className={styles('cash_account_input')}
                      label={t(i18nKeys.FORM.INVOICING.STRUCTURED_COMMUNICATION)}
                      guide
                      numberOnly
                      mask="+++___/____/_____+++"
                    />
                  )}
                />
              )}
              <Input
                register={register('invoice_params.already_paid', {
                  max: totalInclVat,
                })}
                // @ts-ignore incorrectly typed
                errorMessage={
                  (errors.invoice_params?.already_paid &&
                    (Number(amountAlreadyPaid) > Number(totalInclVat)
                      ? 'INVOICING.IMPORT.MUST_BE_LOWER_EQUAL'
                      : 'ERROR.FIELD_REQUIRED')) ??
                  undefined
                }
                type="number"
                label={t(i18nKeys.INVOICING.IMPORT.AMOUNT_ALREADY_PAID_CURRENCY, {
                  text: currencySymbol(currency, company),
                })}
              />
              <div className={styles('total')}>
                {t(i18nKeys.FORM.CHOOSE_INVOICES.AMOUNT_CLAIMED)} :{' '}
                <Amount
                  suffix={currency}
                  value={totalInclVat ? Number(totalInclVat) - Number(amountAlreadyPaid) : 0}
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
}
