import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Redirect, Switch } from 'react-router';
import { Route } from 'react-router-dom';
import {
  Chift,
  Codabox,
  CompanyWeb,
  Digiteal,
  Exact,
  Horus,
  Integration,
  INTEGRATION_ROUTE,
  INTEGRATIONS_ROUTE,
  isEnabledIntegration,
  Ponto,
  Teamleader,
  Yuki,
} from 'shared/components/Integrations';
import { DBasics } from 'shared/components/Integrations/components/DBasics/DBasics';
import { useProfile } from 'shared/hooks';
import { PageTitle } from 'shared/layout/PageTitle';

import AutomaticAssignationDetail from '../AutomaticAssignations/Detail/AutomaticAssignationDetail';
import EditDebtCollectionCustomization from '../Templates/EditDebtCollectionCustomization';
import TemplateEditor from '../Templates/EditDebtCollectionCustomization/TemplateEditor';
import { WorkflowFormAdd, WorkflowFormEdit } from '../Workflows';

import { CollaboratorsSettings } from './CollaboratorsSettings/CollaboratorsSettings';
import { CompanySettings } from './CompanySettings/CompanySettings';
import { CustomizationSettings } from './CustomzationsSettings/CustomizationsSettings';
import { ExternalMailboxSettings } from './ExternalMailboxSettings/ExternalMailboxSettings';
import { IntegrationsSettings } from './IntegrationsSettings/IntegrationsSettings';
import { InvoiceSettings } from './InvoiceSettings/InvoiceSettings';
import { PackageSettings } from './PackageSettings/PackageSettings';
import { PreferenceSettings } from './PreferenceSettings/PreferenceSettings';
import { TemplatesSettings } from './TemplatesSettings/TemplatesSettings';
import { WorkflowsSettings } from './WorkflowsSettings/WorkflowsSettings';
import { SettingsCard } from './SettingsCard';
import { UserSettings } from './UserSettings';

import styleIdentifiers from './Settings.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function Settings() {
  const { t } = useTranslation();
  const profile = useProfile();

  const canEditWorkflows = profile.roles.includes('admin') || profile.roles.includes('workflow');

  return (
    <div className={styles('Settings')}>
      <Switch>
        <Route path="/settings/user" component={UserSettings} />
        <Route path="/settings/company" component={CompanySettings} />
        <Route path="/settings/preferences" component={PreferenceSettings} />
        <Route path="/settings/package" component={PackageSettings} />
        <Route path="/settings/external-mailbox" component={ExternalMailboxSettings} />
        <Route path="/settings/billing" component={InvoiceSettings} />
        {canEditWorkflows && (
          <Route path="/settings/document-customization/edit/:id" component={TemplateEditor} />
        )}
        {canEditWorkflows && (
          <Route path="/settings/document-customization" component={TemplatesSettings} />
        )}
        {canEditWorkflows && (
          <Route
            path="/settings/workflows/recovery-plans/create"
            component={EditDebtCollectionCustomization}
          />
        )}
        {canEditWorkflows && (
          <Route
            path="/settings/workflows/recovery-plans/edit/:id"
            component={EditDebtCollectionCustomization}
          />
        )}
        {canEditWorkflows && (
          <Route path="/settings/workflows/:planType/create" component={WorkflowFormAdd} />
        )}
        {canEditWorkflows && (
          <Route path="/settings/workflows/:planType/edit/:id" component={WorkflowFormEdit} />
        )}
        {canEditWorkflows && (
          <Route
            exact
            path="/settings/workflows"
            render={() => <Redirect to="/settings/workflows/recovery-plans" />}
          />
        )}
        {canEditWorkflows && <Route path="/settings/workflows" component={WorkflowsSettings} />}
        <Route path="/settings/automatic-assignation/:id" component={AutomaticAssignationDetail} />
        <Route path="/settings/collaborators" component={CollaboratorsSettings} />
        <Route path={INTEGRATIONS_ROUTE} exact component={IntegrationsSettings} />
        <Route
          exact
          path="/settings/customization"
          render={() => <Redirect to="/settings/customization/custom-variables" />}
        />
        <Route path="/settings/customization" component={CustomizationSettings} />
        <Route
          path="/settings/integration"
          render={() => (
            <>
              <PageTitle>{t(i18nKeys.SETTINGS.INTEGRATIONS.TITLE)}</PageTitle>
              <SettingsCard>
                {isEnabledIntegration(Integration.YUKI) && (
                  <Route path={INTEGRATION_ROUTE[Integration.YUKI]} component={Yuki} />
                )}
                {isEnabledIntegration(Integration.HORUS) && (
                  <Route path={INTEGRATION_ROUTE[Integration.HORUS]} component={Horus} />
                )}
                {isEnabledIntegration(Integration.TEAMLEADER) && (
                  <Route path={INTEGRATION_ROUTE[Integration.TEAMLEADER]} component={Teamleader} />
                )}
                {isEnabledIntegration(Integration.EXACT) && (
                  <Route path={INTEGRATION_ROUTE[Integration.EXACT]} component={Exact} />
                )}
                {isEnabledIntegration(Integration.DBASICS) && (
                  <Route path={INTEGRATION_ROUTE[Integration.DBASICS]} component={DBasics} />
                )}
                {isEnabledIntegration(Integration.CODABOX) && (
                  <Route path={INTEGRATION_ROUTE[Integration.CODABOX]} component={Codabox} />
                )}
                {isEnabledIntegration(Integration.PONTO) && (
                  <Route path={INTEGRATION_ROUTE[Integration.PONTO]} component={Ponto} />
                )}
                {isEnabledIntegration(Integration.DIGITEAL) && (
                  <Route path={INTEGRATION_ROUTE[Integration.DIGITEAL]} component={Digiteal} />
                )}
                {isEnabledIntegration(Integration.COMPANYWEB) && (
                  <Route path={INTEGRATION_ROUTE[Integration.COMPANYWEB]} component={CompanyWeb} />
                )}
                {isEnabledIntegration(Integration.CHIFT) && (
                  <Route path={INTEGRATION_ROUTE[Integration.CHIFT]} component={Chift} />
                )}
              </SettingsCard>
            </>
          )}
        />
      </Switch>
    </div>
  );
}
