import { AxiosInstance } from 'axios';
import { isDefined } from 'remeda';

import { useQuery } from '@tanstack/react-query';

import {
  addResourceNameToQueryResult,
  ApiError,
  useAxiosInstance,
} from '../../../shared/hooks/utils';

import { Reminders, RemindersSchema } from './use-load-reminders';

export type LoadFailedRemindersVariables = {
  page: number;
  pageLimit: number;
};

export async function loadFailedRemindersQueryFn(
  axiosInstance: Promise<AxiosInstance>,
  { queryKey },
) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/payment_requests/with_failed_emails', {
    params: queryKey[2],
  });
  return RemindersSchema.parse(data);
}

export const useLoadFailedReminders = (variables: LoadFailedRemindersVariables) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Reminders, ApiError>({
    queryKey: ['reminders', 'failed', variables],
    queryFn: (context) => loadFailedRemindersQueryFn(axiosInstance, context),
    placeholderData: (previousData) => {
      if (!isDefined(previousData)) {
        return {
          data: [],
          pagination: {
            totalObjects: 0,
            totalPages: 0,
            pageLimit: 0,
          },
        };
      }

      return previousData;
    },
  });

  return addResourceNameToQueryResult<Reminders, unknown, 'failedRemindersIndex'>(
    'failedRemindersIndex',
    queryResult,
  );
};
