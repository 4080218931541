import { i18nKeys, useTranslation } from 'locales';
import { useLocalizedCurrencyFormatter } from 'shared/utils';

import { Box, Group, Stack, Text } from '@mantine/core';
import { upperFirst } from '@mantine/hooks';

import { KanbanInvoice } from '../../api/use-load-invoice-column';
import { ThirdpartyIcon } from '../ThirdpartyIcon';

interface InvoiceKanbanCardProps {
  invoice: KanbanInvoice;
}

export const InvoiceKanbanCard = ({ invoice }: InvoiceKanbanCardProps) => {
  const { t } = useTranslation();
  const formatter = useLocalizedCurrencyFormatter();
  const { reference, debtor, remainingBalance, daysLate } = invoice;

  return (
    <Stack pos="relative">
      <Box pos="absolute" top={0} right={0}>
        <ThirdpartyIcon invoice={invoice} />
      </Box>
      <Stack gap={4}>
        <Text size="sm" fw={600}>
          {reference}
        </Text>
        <Text size="xs" c="dimmed">
          {debtor.fullName}
        </Text>
      </Stack>
      <Group justify="space-between">
        <Stack gap={0}>
          <Text size="xs" c="dimmed" fw={350}>
            {t(i18nKeys.BALANCE)}
          </Text>
          <Text size="xs" c="blue.7" fw={600}>
            {formatter.format(remainingBalance)}
          </Text>
        </Stack>
        <Stack gap={0} align="flex-end">
          <Text size="xs" c="dimmed" fw={350}>
            {upperFirst(t(i18nKeys.LATE))}
          </Text>
          <Text size="xs" fw={600}>
            {t(i18nKeys.FOLLOW_UP.SUMMARY.DELAY_J, {
              count: daysLate,
            })}
          </Text>
        </Stack>
      </Group>
    </Stack>
  );
};
