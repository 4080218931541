import { produce } from 'immer';
import { atomWithReducer } from 'jotai/utils';
import { LightReminder } from 'modules/Reminders/api';
import { isNonNullish } from 'remeda';

export type ResendReminderModalState = {
  reminder: LightReminder | null;
  modalStep: 'preview' | 'input' | 'confirmIgnore';
};

type ResendReminderModalAction =
  | { type: 'open'; reminder: LightReminder }
  | { type: 'close' }
  | { type: 'ignore'; reminder?: LightReminder }
  | { type: 'confirmPreview' };

const reminderModalInitialState: ResendReminderModalState = {
  reminder: null,
  modalStep: 'preview',
};

function reminderModalReducer(
  prev: ResendReminderModalState,
  action: ResendReminderModalAction,
): ResendReminderModalState {
  return produce(prev, (draft) => {
    switch (action.type) {
      case 'open':
        draft.reminder = action.reminder;
        draft.modalStep = 'preview';
        break;
      case 'close':
        Object.assign(draft, reminderModalInitialState);
        break;
      case 'ignore':
        if (isNonNullish(action.reminder)) draft.reminder = action.reminder;
        draft.modalStep = 'confirmIgnore';
        break;
      case 'confirmPreview':
        draft.modalStep = 'input';
        break;
      default:
        break;
    }
  });
}

export const resendReminderModalAtom = atomWithReducer<
  ResendReminderModalState,
  ResendReminderModalAction
>(reminderModalInitialState, reminderModalReducer);
