import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from './utils';

const ChiftConfigurationLinkSchema = z.object({
  link: z.string().url(),
});

export type ChiftConfigurationLink = z.infer<typeof ChiftConfigurationLinkSchema>;

export async function loadChiftConfigurationLink(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/chift_configurations/link');
  return ChiftConfigurationLinkSchema.parse(data);
}

export const useLoadChiftConfigurationLink = (
  options?: Omit<UseQueryOptions<ChiftConfigurationLink, ApiError>, 'queryKey' | 'queryFn'>,
) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<ChiftConfigurationLink, ApiError>({
    queryKey: ['chift-configurations-link'],
    queryFn: () => loadChiftConfigurationLink(axiosInstance),
    enabled: options?.enabled ?? false,
  });

  return addResourceNameToQueryResult<ChiftConfigurationLink, unknown, 'chiftConfigurationLink'>(
    'chiftConfigurationLink',
    queryResult,
  );
};
