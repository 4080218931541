import React, { useState } from 'react';
import { i18nKeys, useTranslation } from 'locales/index';
import { isEmpty, isNullish } from 'remeda';
import { useUploadImageToS3 } from 'shared/hooks';
import ImageResize from 'tiptap-extension-resize-image';
import { v4 } from 'uuid';

import { FileButton, Group, Loader, Overlay, Pill, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Link, RichTextEditor } from '@mantine/tiptap';
import { IconPaperclip, IconPhoto } from '@tabler/icons-react';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

type RichTextAreaProps = {
  content?: string;
  onChange: (content: string) => void;
  style?: React.CSSProperties;
  onFileChange?: (file: File[]) => void;
};

export const RichTextArea = ({ content, onChange, onFileChange, ...props }: RichTextAreaProps) => {
  const { t } = useTranslation();
  const { uploadImageToS3, isUploadImageToS3Loading } = useUploadImageToS3();

  const [files, setFiles] = useState<File[]>([]);

  const editorInstance = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Image,
      ImageResize,
    ],
    content,
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    onFileChange?.(updatedFiles);
    setFiles(updatedFiles);
  };

  const handleFileChange = (newFiles: File[]) => {
    const updatedFiles = [...files, ...newFiles];
    onFileChange?.(updatedFiles);
    setFiles(updatedFiles);
  };

  const handleUploadImage = (file: File | null) => {
    if (isNullish(file)) return;

    if (file.size > 1048576) {
      notifications.show({
        title: t(i18nKeys.ERROR.ERROR),
        message: t(i18nKeys.FILE_LIMIT_EXCEEDED, { limit: '1MB' }),
        color: 'red',
      });
      return;
    }

    uploadImageToS3(
      { file },
      {
        onSuccess: (response) => {
          editorInstance?.chain().focus().setImage({ src: response.request.responseURL }).run();
        },
      },
    );
  };

  return (
    <RichTextEditor editor={editorInstance} {...props} h="auto">
      {isUploadImageToS3Loading && (
        <Overlay center color="gray.1">
          <Loader size={44} />
        </Overlay>
      )}
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <Stack>
          <Group>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Highlight />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Control
                bg={isEmpty(files) ? '' : 'blue.1'}
                aria-label={t(i18nKeys.EXTERNAL_MAIL.ATTACHMENTS_LABEL)}
                title={t(i18nKeys.EXTERNAL_MAIL.ATTACHMENTS_LABEL)}
              >
                <FileButton onChange={handleFileChange} accept="image/*,.pdf" multiple>
                  {(fileButtonProps) => (
                    <IconPaperclip stroke={1.5} size={16} {...fileButtonProps} />
                  )}
                </FileButton>
              </RichTextEditor.Control>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Control>
                <FileButton onChange={handleUploadImage} accept="image/png, image/jpeg, image/jpg">
                  {(fileButtonProps) => <IconPhoto stroke={1.5} size={16} {...fileButtonProps} />}
                </FileButton>
              </RichTextEditor.Control>
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignJustify />
              <RichTextEditor.AlignRight />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Undo />
              <RichTextEditor.Redo />
            </RichTextEditor.ControlsGroup>
          </Group>

          {!isEmpty(files) && (
            <Group>
              <Pill.Group>
                {files.map((file, index) => (
                  <Pill key={index} onRemove={() => handleRemoveFile(index)} withRemoveButton>
                    {file.name}
                  </Pill>
                ))}
              </Pill.Group>
            </Group>
          )}
        </Stack>
      </RichTextEditor.Toolbar>
      <RichTextEditor.Content />
    </RichTextEditor>
  );
};
