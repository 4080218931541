import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import { i18nKeys, useTranslation } from 'locales';
import { isDefined } from 'remeda';
import { STEP_TO_STEP_THEME } from 'shared';
import { CollectionStep, CollectionStepIndex } from 'shared/components/CollectionStep';
import { PageTitle } from 'shared/layout';
import { useLocalizedCurrencyFormatter } from 'shared/utils';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { Badge, Box, Button, Drawer, Stack, Text } from '@mantine/core';
import { useDisclosure, useHotkeys } from '@mantine/hooks';
import { IconFilter, IconFilterOff } from '@tabler/icons-react';

import { useLoadKanbanInvoices } from '../api';
import { InvoiceKanbanCard, InvoiceKanbanFilters, Kanban, KanbanLoadingState } from '../components';
import { countActiveFilters } from '../utils/filters';

export const InvoicesKanban = () => {
  const { t } = useTranslation();
  const formatter = useLocalizedCurrencyFormatter();
  const { columns, filtersForm } = useLoadKanbanInvoices();
  const [isFiltersDrawerOpen, filtersDrawerHandlers] = useDisclosure(false);

  useHotkeys([['f', filtersDrawerHandlers.toggle]]);

  const isFetching = Object.values(columns).some((column) => column.isInvoiceColumnFetching);
  const areAllColumnsPopulated = Object.values(columns).every((column) =>
    isDefined(column.invoiceColumn),
  );
  if (!areAllColumnsPopulated)
    return <KanbanLoadingState title={t(i18nKeys.KANBAN.DEBTOR.TITLE)} columnCount={6} />;

  const activeFiltersCount = countActiveFilters(filtersForm.values, [
    'column',
    'remainingBalance.operator',
  ]);

  // TODO Update to mantine modal
  const openInvoiceModal = (invoiceId: number) => {
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail id={invoiceId} modal />,
    });
  };

  const cols = Object.values(columns).map((column, colIndex) => {
    const cards = column.invoiceColumn?.invoices.map((invoice, invoiceIndex) => (
      <Kanban.Card key={invoiceIndex} onClick={() => openInvoiceModal(invoice.id)}>
        <InvoiceKanbanCard invoice={invoice} />
      </Kanban.Card>
    ));

    return (
      <Kanban.Column
        isLoading={isFetching}
        key={colIndex}
        title={
          <Stack gap="sm">
            <CollectionStep step={colIndex as CollectionStepIndex} />
            <Text fw="bold" size="16px" c="gray.7">
              {formatter.format(column.invoiceColumn?.total.totalInEur ?? 0)}
            </Text>
          </Stack>
        }
        color={STEP_TO_STEP_THEME[colIndex].color}
      >
        {cards}
      </Kanban.Column>
    );
  });

  return (
    <>
      {/* Fixed height necessary to constrain Kanban columns, This is the natural height of the component without actions */}
      <Box h="60px">
        <PageTitle>
          {t(i18nKeys.KANBAN.INVOICE.TITLE)}
          <PageTitle.Actions>
            <Button.Group>
              <Button
                variant="default"
                onClick={filtersDrawerHandlers.open}
                leftSection={<IconFilter stroke={1.5} size={18} />}
                rightSection={
                  <Badge color={activeFiltersCount > 0 ? 'blue' : 'gray'}>
                    {activeFiltersCount}
                  </Badge>
                }
              >
                {t(i18nKeys.FILTER)}
              </Button>
              {activeFiltersCount > 0 && (
                <Button variant="default" onClick={filtersForm.reset}>
                  <IconFilterOff stroke={1.5} size={18} />
                </Button>
              )}
            </Button.Group>
          </PageTitle.Actions>
        </PageTitle>
      </Box>
      <Box h="calc(100% - 60px)">
        <Kanban>{cols}</Kanban>
      </Box>
      <Drawer
        position="right"
        opened={isFiltersDrawerOpen}
        onClose={filtersDrawerHandlers.close}
        title={t(i18nKeys.FILTERS)}
        keepMounted
        padding="lg"
        styles={{
          title: {
            fontSize: '1.5rem',
          },
        }}
      >
        <InvoiceKanbanFilters form={filtersForm} />
      </Drawer>
    </>
  );
};
