import { Id } from 'api/models';
import { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from 'shared/hooks/utils';
import { IgnoredReasonSchema, ReminderStatusSchema } from 'types/reminder-status';
import { SendingMediaSchema } from 'types/sending-media';
import { EmailDeliveryStatus } from 'types/store/activity';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

export const ReminderSchema = z.object({
  id: z.number(),
  sentAt: z.string().datetime().transform(dayjs),
  debtorFullName: z.string(),
  debtorId: z.string(),
  reference: z.string(),
  reminderType: z.string(),
  sentMedia: z.array(SendingMediaSchema),
  invoiceCount: z.number(),
  resentVia: z.number().nullable(),
  original: z.number().nullable(),
  status: ReminderStatusSchema.nullable(),
  ignoredReason: IgnoredReasonSchema.nullable(),
  letters: z.array(
    z.object({
      url: z.string(),
      trackingLink: z.string().nullable(),
    }),
  ),
  emails: z.array(
    z.object({
      body: z.string(),
      email: z.string(),
      sentAt: z.string(),
      statuses: z.record(z.nativeEnum(EmailDeliveryStatus), z.array(z.string())),
      failed: z.boolean(),
    }),
  ),
});

export type Reminder = z.infer<typeof ReminderSchema>;

export async function loadReminderQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get(`/payment_requests/${queryKey[1]}`);

  return ReminderSchema.parse(data);
}

export const useLoadReminder = (id: Id) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Reminder, ApiError>({
    queryKey: ['reminders', id] as const,
    queryFn: (context) => loadReminderQueryFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<Reminder, unknown, 'reminder'>('reminder', queryResult);
};
