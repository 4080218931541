import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import CustomTable from 'shared/components/CustomTable';
import ThirdpartyCaseListItem from 'shared/components/ThirdpartyCaseListItem';
import ThirdpartyCasesFiltersForm from 'shared/forms/ThirdpartyCasesFiltersForm';
import {
  LightThirdPartyCase,
  ThirdPartyCasesFilters,
  useLoadThirdPartyCases,
  useProfile,
} from 'shared/hooks';

import styleIdentifiers from './ThirdpartyCaseListing.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EnhancedTable = CustomTable(ThirdpartyCaseListItem, ThirdpartyCasesFiltersForm);

export default function ThirdpartyCaseList() {
  const { t } = useTranslation();
  const profile = useProfile();

  const [params, setParams] = useState<ThirdPartyCasesFilters>({
    page_limit: profile.preferences.itemsPerPage,
    page: 1,
  });

  const { thirdPartyCases, isThirdPartyCasesLoading, isThirdPartyCasesFetching } =
    useLoadThirdPartyCases(params);

  useEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<ThirdPartyCasesFilters>(Object.fromEntries(new URLSearchParams(location.search)), [
        'account_manager_id',
        'case_type',
        'debtor_name',
        'filenumber',
        'invoice_reference',
        'page_limit',
        'page',
        'remaining_balance_operator',
        'remaining_balance',
        'sent_date_after',
        'sent_date_before',
        'sort_by',
        'sort_order',
        'status',
        'total_tvac_operator',
        'total_tvac',
        'unread_messages',
        'update_date_after',
        'update_date_before',
      ]),
    }));
  }, []);

  const THIRD_PARTY_CASES_TABLE_HEADERS = [
    {
      key: 'case_type',
      title: t(i18nKeys.CASE_TYPE),
      width: 140,
    },
    {
      key: 'case_reference',
      title: t(i18nKeys.FORM.REFERENCE),
      width: 160,
    },
    {
      key: 'created_at',
      title: t(i18nKeys.CREATION_DATE),
      width: 140,
    },
    {
      key: 'updated_at',
      title: t(i18nKeys.LAST_UPDATE),
      width: 140,
    },
    {
      key: 'debtor_name',
      title: t(i18nKeys.COMMON.CLIENT),
      width: 160,
    },
    {
      key: 'status',
      title: t(i18nKeys.STATUS),
      width: 120,
    },
    {
      key: 'total_tvac',
      title: t(i18nKeys.TOTAL_VAT_PRICE),
    },
    {
      key: 'remaining_balance',
      title: t(i18nKeys.BALANCE),
    },
  ];

  const handleFilters = (filters: ThirdPartyCasesFilters) => {
    const newFilters = {
      ...filters,
    };

    // need to be linked
    if (
      (newFilters.total_tvac_operator && !newFilters.total_tvac) ||
      (!newFilters.total_tvac_operator && newFilters.total_tvac)
    )
      return false;

    // need to be linked
    if (
      (newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
      (!newFilters.remaining_balance_operator && newFilters.remaining_balance)
    )
      return false;

    setParams(newFilters);
    return newFilters;
  };

  const onSortChange = (newParams: ThirdPartyCasesFilters) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']),
    }));
  };

  const orderField = (field: string) => (field.indexOf('debtor') >= 0 ? 'debtor_name' : field);

  const customKey = (tp_case: LightThirdPartyCase) => tp_case.case_type + tp_case.id;

  return (
    <div className={styles('Listing')}>
      <EnhancedTable
        title={t(i18nKeys.THIRD_PARTY_CASES.THIRD_PARTY_CASES_LIST)}
        showShadow
        orderField={orderField}
        onSortChange={onSortChange}
        loading={isThirdPartyCasesLoading && isThirdPartyCasesFetching}
        loaded={!isThirdPartyCasesFetching && !isThirdPartyCasesLoading}
        pagination={thirdPartyCases?.metadata && thirdPartyCases.metadata.pagination}
        headers={THIRD_PARTY_CASES_TABLE_HEADERS}
        handleFilters={handleFilters}
        items={thirdPartyCases?.data || []}
        customKey={customKey}
        noCheckbox
      />
    </div>
  );
}
