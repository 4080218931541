import { PropsWithChildren } from 'react';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';

import { ActionIcon, Button, Group, Stack, Text, Tooltip } from '@mantine/core';
import { IconArrowLeft, IconInfoCircle, IconRefresh } from '@tabler/icons-react';

import { Integration } from '../Integrations.constants';
import { isAccountingIntegration } from '../Integrations.utils';

import { useSynchroniseIntegration } from './Integration.hooks';
import { ResetButton } from './ResetButton';

type IntegrationHeaderProps = {
  name: Integration;
  goBack: () => void;
  className?: string;
  isActive?: boolean;
  isConnected?: boolean;
};

export const IntegrationHeader = ({
  name,
  goBack,
  children,
  isActive = false,
  isConnected = false,
  ...props
}: PropsWithChildren<IntegrationHeaderProps>) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { synchronize, canSynchronize, nextPossibleSynchronizeDate, lastImportDate } =
    useSynchroniseIntegration();

  return (
    <Group justify="space-between" mb="xl" {...props}>
      <Group>
        <ActionIcon
          onClick={() => history.push('/settings/integrations')}
          size="md"
          variant="light"
        >
          <IconArrowLeft stroke={1.5} />
        </ActionIcon>
        <Text size="lg" fw="bold">
          {name}
        </Text>
      </Group>
      <Group justify="end">
        {isActive && (
          <>
            <Stack gap={0}>
              {lastImportDate && (
                <Text size="sm" c="gray">
                  {t(i18nKeys.FORM.INTEGRATION.LAST_IMPORT_THE, {
                    date: dayjs(lastImportDate).format('LLL').toString(),
                  })}
                </Text>
              )}
              {canSynchronize && nextPossibleSynchronizeDate && (
                <Text size="sm" c="gray">
                  {t(i18nKeys.FORM.INTEGRATION.NEXT_IMPORT_THE, {
                    date: dayjs(nextPossibleSynchronizeDate).format('LLL').toString(),
                  })}
                </Text>
              )}
            </Stack>
            <Tooltip
              withArrow
              multiline
              offset={{ mainAxis: 10 }}
              w={250}
              arrowPosition="center"
              label={t(i18nKeys.FORM.INTEGRATION.MANUAL_SYNC_DESCRIPTION)}
            >
              <Group>
                <IconInfoCircle size={20} />
              </Group>
            </Tooltip>
            <Button
              disabled={!canSynchronize}
              onClick={synchronize}
              rightSection={<IconRefresh size={16} />}
              variant="default"
              c="grey"
            >
              {t(i18nKeys.SYNCHRONIZE)}
            </Button>
          </>
        )}
        {isAccountingIntegration(name) && isConnected ? <ResetButton /> : null}
      </Group>
    </Group>
  );
};
