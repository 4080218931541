import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { INTEGRATIONS_ROUTE } from 'shared/components/Integrations';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';

import {
  IconAdjustmentsAlt,
  IconBuilding,
  IconEdit,
  IconFileDollar,
  IconMailCog,
  IconMapDollar,
  IconPackage,
  IconPlugConnected,
  IconSettings,
  IconTemplate,
  IconUser,
  IconUsers,
} from '@tabler/icons-react';

import { Menu, MenuItem } from '../types';

export function useBuildSettingMenu(): Menu {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const profile = useProfile();

  const canEditWorkflows = profile.roles.includes('admin') || profile.roles.includes('workflow');

  return useMemo(() => {
    const items: Array<Menu | MenuItem> = [
      {
        path: '/settings/user',
        label: t(i18nKeys.SETTINGS.PROFILE.TITLE),
        Icon: IconUser,
      },
      {
        path: '/settings/company',
        label: t(i18nKeys.SETTINGS.COMPANY.TITLE),
        Icon: IconBuilding,
      },
      {
        path: '/settings/preferences',
        label: t(i18nKeys.PREFERENCES),
        Icon: IconAdjustmentsAlt,
      },
      {
        path: '/settings/package',
        label: t(i18nKeys.SETTINGS.PACKAGE.TITLE),
        Icon: IconPackage,
      },
      {
        path: '/settings/external-mailbox',
        label: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE),
        Icon: IconMailCog,
        showUpsell: !company?.package?.can_use_external_mails,
      },
      {
        path: '/settings/billing',
        label: t(i18nKeys.COMMON.INVOICING),
        Icon: IconFileDollar,
      },
      {
        path: '/settings/workflows',
        label: t(i18nKeys.AUTOMATED_PLANS),
        Icon: IconMapDollar,
        isHidden: !canEditWorkflows,
        isExactRouteMatch: false,
      },
      {
        path: '/settings/document-customization',
        label: t(i18nKeys.TEMPLATES),
        Icon: IconTemplate,
        isHidden: !canEditWorkflows,
      },
      {
        path: '/settings/customization',
        label: t(i18nKeys.NAV.CUSTOMIZATION),
        Icon: IconEdit,
        isExactRouteMatch: false,
      },
      {
        path: '/settings/collaborators',
        label: t(i18nKeys.SETTINGS.STAFF.TITLE),
        Icon: IconUsers,
      },
      {
        path: INTEGRATIONS_ROUTE,
        label: t(i18nKeys.SETTINGS.INTEGRATIONS.TITLE),
        Icon: IconPlugConnected,
      },
    ];

    return {
      parent: null,
      label: t(i18nKeys.NAV.SETTINGS),
      Icon: IconSettings,
      items,
    };
  }, [t]);
}
