import { useEffect } from 'react';
import { vercelEdgeConfigClient } from 'init';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import apiService from 'shared/service/api.service';
import { z } from 'zod';

import { captureMessage } from '@sentry/react';

import { isDemo, isProduction } from './environment';

const FeatureFlagConfigSchema = z.record(z.string(), z.array(z.string()));

type FeatureFlagConfig = z.infer<typeof FeatureFlagConfigSchema>;

const featureFlagsAtom = atom<FeatureFlagConfig | null>(null);

export const useIsFeatureFlagEnabled = (slug: string): boolean => {
  const featureFlags = useAtomValue(featureFlagsAtom);

  if (!featureFlags) return false;
  if (!featureFlags[slug]) return false;

  if (isProduction() || isDemo()) return featureFlags[slug].includes(apiService.tenant);

  return true;
};

export const useLoadFeatureFlags = () => {
  const setFeatureFlags = useSetAtom(featureFlagsAtom);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const response = await vercelEdgeConfigClient.get('featureFlags');
      const parseResult = FeatureFlagConfigSchema.safeParse(response);

      if (parseResult.success) {
        setFeatureFlags(parseResult.data);
      } else {
        captureMessage(
          "WARNING: Couldn't parse feature flags object from Vercel Edge Config. Zod error attached to sentry event.",
          { extra: { zodError: parseResult.error } },
        );
      }
    };

    fetchFeatureFlags();
  }, [setFeatureFlags]);
};
