import { AxiosInstance } from 'axios';
import { isDefined } from 'remeda';
import { PaginationSchema } from 'types/pagination';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import {
  addResourceNameToQueryResult,
  ApiError,
  useAxiosInstance,
} from '../../../shared/hooks/utils';

import { ReminderSchema } from './use-load-reminder';

const LightReminderSchema = ReminderSchema.omit({
  letters: true,
  emails: true,
  resentVia: true,
  original: true,
  status: true,
  ignoredReason: true,
});

export const RemindersSchema = z.object({
  data: z.array(LightReminderSchema),
  pagination: PaginationSchema,
});

export type LightReminder = z.infer<typeof LightReminderSchema>;
export type Reminders = z.infer<typeof RemindersSchema>;

export type LoadRemindersVariables = {
  page: number;
  pageLimit: number;
  reference?: string;
  startDate?: Date;
  endDate?: Date;
  order: string;
};

export async function loadRemindersQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/payment_requests', { params: queryKey[1] });
  return RemindersSchema.parse(data);
}

export const useLoadReminders = (variables: LoadRemindersVariables) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Reminders, ApiError>({
    queryKey: ['reminders', variables],
    queryFn: (context) => loadRemindersQueryFn(axiosInstance, context),
    placeholderData: (previousData) => {
      if (!isDefined(previousData)) {
        return {
          data: [],
          pagination: {
            totalObjects: 0,
            totalPages: 0,
            pageLimit: 0,
          },
        };
      }

      return previousData;
    },
  });

  return addResourceNameToQueryResult<Reminders, unknown, 'reminderIndex'>(
    'reminderIndex',
    queryResult,
  );
};
