import { AxiosInstance, AxiosResponse } from 'axios';
import { i18nKeys, useTranslation } from 'locales';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, useLegacyAxiosInstance } from './utils';

const CreateExternalMailboxVariablesSchema = z.discriminatedUnion('is_microsoft', [
  z.object({
    is_microsoft: z.literal(false),
    host: z.string().nullable(),
    login: z.string().email(),
    password: z.string(),
    port: z.coerce.number(),
    ssl: z.boolean(),
    signature: z.string().nullable().optional(),
  }),
  z.object({
    is_microsoft: z.literal(true),
    login: z.string().email(),
    signature: z.string().nullable().optional(),
  }),
]);

export type CreateExternalMailboxVariables = z.infer<typeof CreateExternalMailboxVariablesSchema>;

export async function createExternalMailbox(
  axiosInstance: Promise<AxiosInstance>,
  data: CreateExternalMailboxVariables,
) {
  const instance = await axiosInstance;

  return instance.post(
    'external_mailbox_configurations',
    CreateExternalMailboxVariablesSchema.parse(data),
  );
}

type CreateExternalMailboxResponse = AxiosResponse<{ redirect_uri: string }>;

export function useCreateExternalMailbox() {
  const { t } = useTranslation();
  const client = useQueryClient();
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation<
    CreateExternalMailboxResponse,
    ApiError,
    CreateExternalMailboxVariables
  >({
    mutationFn: (data) => createExternalMailbox(axiosInstance, data),
    onSuccess: () => {
      notifications.show({
        message: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.CREATE_SUCCESS),
        color: 'green',
      });
      client.invalidateQueries({ queryKey: ['externalMailboxes'] });
    },
    onError: () => {
      notifications.show({
        message: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.CREATE_ERROR),
        color: 'red',
      });
    },
  });

  return addActionNameToMutationResult<
    CreateExternalMailboxResponse,
    ApiError,
    CreateExternalMailboxVariables,
    'createExternalMailbox'
  >('createExternalMailbox', mutation);
}
