import { Route, Switch } from 'react-router';

import { ReminderList } from './pages/RemindersIndex';
import { FailedRemindersList, Reminder } from './pages';

interface RemindersProps {}

export const Reminders = ({}: RemindersProps) => (
  <Switch>
    <Route path="/reminders/failed/:id" component={Reminder} />
    <Route path="/reminders/failed" component={FailedRemindersList} />
    <Route path="/reminders/:id" component={Reminder} />
    <Route path="/reminders" component={ReminderList} />
  </Switch>
);
