import { CleanActivity } from 'api/models';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import ActivityDetail from 'shared/components/ActivityDetail';
import { EmailDeliveryStatusCompact } from 'shared/components/ActivityDetail/EmailDeliveryStatusCompact';
import HTML from 'shared/components/HTML/HTML';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import styleIdentifiers from './ClientDetailTabsTimeline.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientDetailTabsTimelineItemActivityProps = {
  activity: CleanActivity;
};

export const ClientDetailTabsTimelineItemActivity = ({
  activity,
}: ClientDetailTabsTimelineItemActivityProps) => {
  const {
    attributes: { description, author, date, email_statuses },
  } = activity;

  const openModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      children: <ActivityDetail activity={activity.attributes as any} />,
    });
  };

  return (
    <div onClick={openModal}>
      <span className={styles('date')}>{dayjs(date).fromNow()}</span>
      <div className={styles('from')}>{author}</div>
      <div className={styles('message')}>
        <HTML html={description} />
      </div>
      {email_statuses != null && (
        <div style={{ marginTop: '10px' }}>
          <EmailDeliveryStatusCompact statuses={email_statuses} />
        </div>
      )}
    </div>
  );
};
