import AttachedInvoicesList from 'app/Private/ThirdpartyCases/AttachedInvoices';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { upperFirst } from 'lodash-es';
import { useQueryClient } from 'react-query';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { Icon, IconName } from 'shared/components/Icon';
import { LightThirdPartyCase, useLoadThirdPartyCase } from 'shared/hooks';

import { Center, Loader } from '@mantine/core';

import Conversation from '../ActivityDetail/Conversation';
import debtCollectorIcon from '../InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';

import styleIdentifiers from './TpCaseDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  item: LightThirdPartyCase;
};

export default function TpCaseDetail({ item }: Props) {
  const { t, currentLang } = useTranslation();
  const queryClient = useQueryClient();

  const { thirdPartyCase, isThirdPartyCaseLoading } = useLoadThirdPartyCase(item.id);
  const caseTypeTranslated = t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE[item.case_type.toUpperCase()]);

  const getIcon = () => {
    switch (item.case_type) {
      case 'lawyer':
        return <Icon name={IconName.LAW} size="18px" className={styles('icon')} />;
      case 'bailiff':
        return <Icon name={IconName.BALANCE} size="18px" className={styles('icon')} />;
      case 'debt_collector':
        return <img width="24px" height="24px" src={debtCollectorIcon} alt="Debt collector" />;
      case 'callcenter':
      default:
        return <Icon name={IconName.PHONE_CALL} size="18px" className={styles('icon')} />;
    }
  };

  const onSubmitMessage = () => {
    queryClient.invalidateQueries({ queryKey: ['third-party-case'] });
  };

  if (isThirdPartyCaseLoading) {
    return (
      <Center mih={450}>
        <Loader />
      </Center>
    );
  }

  return (
    <div className={styles('tp-case-detail')}>
      {thirdPartyCase && (
        <div className={styles('content')}>
          <div className={styles('left-container')}>
            <div className={styles('top')}>
              <div className={styles('left')}>
                {getIcon()}
                <div className={styles('text')}>
                  {thirdPartyCase.tp_owner && (
                    <div>
                      <b>{caseTypeTranslated} : </b>
                      <span>{upperFirst(thirdPartyCase.tp_owner)}</span>
                    </div>
                  )}
                  {thirdPartyCase.case_id && (
                    <div>
                      <b>{t(i18nKeys.THIRD_PARTY_CASES.REFERENCE)} : </b>
                      <span>{upperFirst(thirdPartyCase.case_id)}</span>
                    </div>
                  )}
                  <div>
                    <b>{t(i18nKeys.CREATION_DATE)} : </b>
                    <DateItem lg={currentLang} date={new Date(thirdPartyCase.created_at)} />
                  </div>
                  <div>
                    <b>{t(i18nKeys.COMMON.CLIENT)} : </b>
                    <span>{thirdPartyCase.invoice_debtor_name}</span>
                  </div>
                  <div>
                    <b>{t(i18nKeys.FORM.CHOOSE_INVOICES.AMOUNT_CLAIMED)} : </b>
                    <Amount value={thirdPartyCase.total_tvac} />
                  </div>
                  <div>
                    <b>{t(i18nKeys.INVOICING.TOTAL_OUTSTANDING_PAYMENTS)} : </b>
                    <Amount value={thirdPartyCase.total_remaining} />
                  </div>
                </div>
              </div>
              <div className={styles('right')}>
                <span className={styles('text')}>
                  {t(i18nKeys.LAST_UPDATE)}:
                  <br />
                  {dayjs(thirdPartyCase.last_update).fromNow()}
                </span>
              </div>
            </div>
            <div className={styles('bottom')}>
              {thirdPartyCase.attached_invoices && (
                <AttachedInvoicesList
                  invoices={thirdPartyCase.attached_invoices}
                  multiple={thirdPartyCase.attached_invoices.details.length > 1}
                />
              )}
            </div>
          </div>
          {thirdPartyCase.invoice_feedback?.conversation && (
            <div className={styles('right-container')}>
              <div className={styles('conversation-card')}>
                <div className={styles('head')}>
                  <h3>{t(i18nKeys.THIRD_PARTY_CASES.CONVERSATION)}</h3>
                </div>
                <Conversation
                  caseId={thirdPartyCase.id}
                  className={styles('conversation')}
                  invoiceId={thirdPartyCase.invoice_id}
                  invoiceFeedback={thirdPartyCase.invoice_feedback}
                  onSubmit={onSubmitMessage}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
