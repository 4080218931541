import { z } from 'zod';

export const PaginationSchema = z.object({
  totalObjects: z.number(),
  totalPages: z.number(),
  pageLimit: z.number(),
});

export const LegacyPaginationSchema = z.object({
  total_objects: z.number(),
  page_limit: z.number(),
  current_page: z.number(),
  last_page: z.number(),
});

export type Pagination = z.infer<typeof PaginationSchema>;
export type LegacyPagination = z.infer<typeof LegacyPaginationSchema>;
