import { useState } from 'react';
import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { useLoadInvoiceLifeCycle } from 'shared/hooks';

import { Badge, Box, Group, LoadingOverlay, SegmentedControl, Text } from '@mantine/core';
import { DefaultLink, ResponsiveSankey, SankeyLinkDatum } from '@nivo/sankey';
import { IconArrowNarrowRight } from '@tabler/icons-react';

import { AnalyticsCard } from '../AnalyticsCard';

type Layout = 'horizontal' | 'vertical';

type CustomLinkTooltipProps = {
  link: SankeyLinkDatum<{ id: string }, DefaultLink>;
};

const CustomLinkTooltip = ({ link: { source, target, value } }: CustomLinkTooltipProps) => (
  <Box bg="white" px={8} py={4} radius="sm" shadow="xs" style={{ borderRadius: '4px' }}>
    <Group gap={4}>
      <Box mx={2} bg={source.color} h="sm" w="sm" style={{ borderRadius: '2px' }} />
      <Text>{source.label}</Text>
      <IconArrowNarrowRight stroke={1.5} />
      <Text>{target.label}</Text>
      <Box mx={2} bg={target.color} h="sm" w="sm" style={{ borderRadius: '2px' }} />
      <Text>{((value / source.value) * 100).toFixed(2)}%</Text>
      <Text>({value})</Text>
    </Group>
  </Box>
);

const CustomNodeToolip = ({ node }) => (
  <Box bg="white" px={8} py={4} radius="sm" shadow="xs" style={{ borderRadius: '4px' }}>
    <Group gap={4}>
      <Box mx={2} bg={node.color} h="sm" w="sm" style={{ borderRadius: '2px' }} />
      <Text>{node.label}</Text>
    </Group>
  </Box>
);

const ChartHelpContent = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text fw="bold">{t(i18nKeys.ANALYTICS.REMINDER_CHART.HELPER_TITLE)}</Text>
      <br />
      <Text>{t(i18nKeys.ANALYTICS.REMINDER_CHART.HELPER_TEXT)}</Text>
    </Box>
  );
};

export const ReminderEffectivenessCard = () => {
  const { t } = useTranslation();
  const { invoiceLifeCycle, isInvoiceLifeCycleLoading } = useLoadInvoiceLifeCycle();
  const [layout, setLayout] = useState<Layout>('vertical');

  return (
    <AnalyticsCard
      title={
        <Group gap="0">
          {t(i18nKeys.ANALYTICS.REMINDER_CHART.TITLE)}
          <Badge ml="lg" size="sm" variant="light" radius="sm">
            alpha
          </Badge>
        </Group>
      }
      actions={
        <SegmentedControl
          value={layout}
          onChange={(value) => setLayout(value as Layout)}
          size="sm"
          data={[
            { label: 'vertical', value: 'vertical' },
            { label: 'horizontal', value: 'horizontal' },
          ]}
        />
      }
      helpContent={<ChartHelpContent />}
    >
      <Box p={30} w="100%" h="400px">
        {isInvoiceLifeCycleLoading ? (
          <LoadingOverlay visible />
        ) : (
          <ResponsiveSankey
            enableLinkGradient
            labelPosition="outside"
            labelPadding={15}
            nodeSpacing={4}
            colors={{ scheme: 'category10' }}
            layout={layout}
            margin={{ bottom: 22, top: 19, right: layout === 'vertical' ? 60 : 140 }}
            align="justify"
            data={invoiceLifeCycle!}
            linkTooltip={CustomLinkTooltip}
            nodeTooltip={CustomNodeToolip}
          />
        )}
      </Box>
    </AnalyticsCard>
  );
};
