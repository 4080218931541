import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import Amount from 'shared/components/Amount';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import { useUseMatching } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { formatAmount } from 'shared/utils';
import { formattedDate } from 'shared/utils/view';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { transactionActions } from 'store/transaction/transaction.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  success,
} from 'store/view/view.actions';
import { CreditNote } from 'types/store/credit-note-state';
import { ExternalPayment, Invoice, ManualPayment } from 'types/store/invoice-state';

import styleIdentifiers from './UnmatchPayment.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  reload: Function;
  invoice: Invoice;
};

export default function UnmatchPayment({ reload, invoice }: Props) {
  const { t, currentLang } = useTranslation();
  const isMatchingActive = useUseMatching();
  const [payments, setPayments] = useState<any>();

  const { manual_payments, external_payments } = payments || invoice;

  useEffect(() => {
    if (!invoice.external_payments) {
      invoiceActions.detail({
        id: invoice.id,
        callback: ({ data }) => {
          setPayments(treatInvoice(data));
        },
      });
    }
  }, []);

  const submit = (isExternalPayment, payment, steps_switch?) => {
    (isExternalPayment
      ? transactionActions.unmatchExternalPayment
      : transactionActions.unmatchManualPayment)({
      id: payment.id,
      keepMountOnExit: true,
      data: {
        recovery_step_id: steps_switch ? steps_switch[0].to : undefined,
      },
      callback: () => {
        dialogHide(DialogShowId.CUSTOM);
        reload();
        success({
          text: isExternalPayment
            ? 'INVOICE.UNMATCH_PAYMENT.SUCCESSFULLY_UNMATCH_PAYMENT'
            : 'INVOICE.UNMATCH_PAYMENT.SUCCESSFULLY_DELETE_PAYMENT',
        });
      },
    });
  };

  const unmatchPayment =
    (payment: ManualPayment | ExternalPayment, isExternalPayment?: boolean) => () => {
      if (invoice.paid) {
        showDialog({
          id: DialogShowId.CONFIRM,
          size: DialogShowSize.SMALL,
          title: t(i18nKeys.CONFIRM),
          children: (
            <AYSModal
              text={t(i18nKeys.AYS.INVOICE_BACK_IN_PLAN)}
              confirmButtonText={t(i18nKeys.YES)}
              cancelButtonText={t(i18nKeys.NO)}
              onConfirm={() => {
                MoveRecoveryPlanInvoices({
                  title: t(i18nKeys.PLAN.INVOICES),
                  newPlanId: invoice.recovery_plan_id,
                  oldPlanId: invoice.recovery_plan_id,
                  currentStep: -1,
                  onSubmit: (steps_switch) => {
                    submit(isExternalPayment, payment, steps_switch);
                  },
                });
              }}
              onCancel={() => {
                submit(isExternalPayment, payment);
              }}
            />
          ),
        });
      } else {
        showDialog({
          id: DialogShowId.CONFIRM,
          size: DialogShowSize.SMALL,
          title: t(i18nKeys.CONFIRM),
          children: (
            <AYSModal
              text={
                <div>
                  <div>
                    {isMatchingActive &&
                      t(i18nKeys.INVOICE.UNMATCH_PAYMENT.RECONCILIATION_NOT_ACTIVE_ANYMORE)}
                  </div>
                  {t(
                    isExternalPayment
                      ? i18nKeys.INVOICE.UNMATCH_PAYMENT.AYS_TO_UNMATCH
                      : i18nKeys.INVOICE.UNMATCH_PAYMENT.AYS_TO_DELETE,
                  )}
                </div>
              }
              onConfirm={() => {
                submit(isExternalPayment, payment);
              }}
            />
          ),
        });
      }
    };

  const unlinkCn = (cn: CreditNote) => () => {
    creditNoteActions.unlinkToInvoices({
      id: cn.id,
      data: {
        unlink_invoice: invoice.id,
      },
      callback: () => {
        dialogHide(DialogShowId.CUSTOM);
        reload();
      },
    });
  };

  return (
    <div className={styles('UnmatchPayment')}>
      {external_payments?.length > 0 && (
        <>
          <h3>{t(i18nKeys.INVOICE.UNMATCH_PAYMENT.RECONCILED_PAYMENTS)}</h3>
          <div className="recovr-table">
            <table>
              <thead>
                <tr>
                  <th>
                    <div>{t(i18nKeys.FORM.HISTORY.SENDER)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.DATE)}</div>
                  </th>
                  <th style={{ width: 120 }}>
                    <div>{t(i18nKeys.INVOICE.UNMATCH_PAYMENT.PAYMENT_AMOUNT)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.INVOICE.UNMATCH_PAYMENT.RECONCILIATION_AMOUNT)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.COMMUNICATION)}</div>
                  </th>
                  <th style={{ width: 150 }}>
                    <div />
                  </th>
                </tr>
              </thead>
              <tbody>
                {external_payments.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.payment.counterparty_name || payment.payment.external_id}</td>
                    <td>{formattedDate(payment.payment.value_date, currentLang)}</td>
                    <td>
                      <Amount
                        localizedValue={payment.payment.amount}
                        value={payment.payment.localized_money_object.amount}
                        suffix={payment.payment.currency}
                      />
                    </td>
                    <td>
                      <Amount
                        localizedValue={payment.amount}
                        value={payment.localized_money_object?.amount}
                        suffix={payment.currency}
                      />
                    </td>
                    <td>{payment.payment.communication}</td>
                    <td className="actions">
                      <Button
                        className={styles('delete-payment')}
                        label={t(i18nKeys.INVOICE.UNMATCH_PAYMENT.CANCEL_PAYMENT)}
                        onClick={unmatchPayment(payment, true)}
                        small
                        noMargin
                        noShadow
                        color={ButtonColor.GREY}
                        iconLeft={IconName.UNLINK}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {manual_payments?.length > 0 && (
        <>
          <h3>{t(i18nKeys.INVOICE.UNMATCH_PAYMENT.PAYMENTS_ADDED_MANUALLY)}</h3>
          <div className="recovr-table">
            <table>
              <thead>
                <tr>
                  <th>
                    <div>{t(i18nKeys.MODALITY)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.AMOUNT)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.COMMENT)}</div>
                  </th>
                  <th style={{ width: 150 }}>
                    <div />
                  </th>
                </tr>
              </thead>
              <tbody>
                {manual_payments.map((payment) => (
                  <tr key={payment.id}>
                    <td>{payment.payment_modality_translated}</td>
                    <td>{formattedDate(payment.received_at, currentLang)}</td>
                    <td>
                      <Amount
                        localizedValue={payment.amount}
                        value={payment.localized_money_object?.amount}
                        suffix={payment.currency}
                      />
                    </td>
                    <td>{payment.notes}</td>
                    <td className="actions">
                      <Button
                        className={styles('delete-payment')}
                        label={t(i18nKeys.DELETE)}
                        onClick={unmatchPayment(payment)}
                        small
                        noMargin
                        color={ButtonColor.GREY}
                        iconLeft={IconName.TRASH_SIMPLE}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {invoice.credit_notes_attributes.length > 0 && (
        <>
          <h3>{t(i18nKeys.INVOICE.UNMATCH_PAYMENT.CREDIT_NOTES)}</h3>
          <div className="recovr-table">
            <table>
              <thead>
                <tr>
                  <th>
                    <div>{t(i18nKeys.FORM.REFERENCE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.ISSUE_DATE)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.AMOUNT)}</div>
                  </th>
                  <th>
                    <div>{t(i18nKeys.STATUS)}</div>
                  </th>
                  <th style={{ width: 150 }}>
                    <div />
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoice.credit_notes_attributes.map((cn) => {
                  const matched = cn.matched_payments_attributes.filter(
                    (e) => e.invoice_attributes.id === invoice.id,
                  );
                  return (
                    <React.Fragment key={cn.id}>
                      {matched.map((matchedPayment) => (
                        <tr key={matchedPayment.id}>
                          <td>{cn.reference}</td>
                          <td>{formattedDate(cn.issue_date, currentLang)}</td>
                          <td>{formatAmount(matchedPayment.amount)}</td>
                          <td>{cn.status}</td>
                          <td>
                            <Button
                              className={styles('delete-payment')}
                              label={t(i18nKeys.DELETE)}
                              onClick={unlinkCn(cn)}
                              small
                              noMargin
                              color={ButtonColor.GREY}
                              iconLeft={IconName.TRASH_SIMPLE}
                            />
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
