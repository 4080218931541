import { AttachedInvoices } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { CurrencyCode } from 'types/currency';

import styleIdentifiers from '../PaymentPlanDetailDebtor.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  withFees: boolean;
  currency?: CurrencyCode;
  invoices: AttachedInvoices;
};

export default function PaymentPlanInvoicesDebtor({ withFees, invoices, currency }: Props) {
  const { t } = useTranslation();

  const renderInvoice = (reference, remainingBalance) => (
    <div className={styles('key-text')}>
      <div className={styles('attribute')}>{reference}</div>
      <div className={styles('value')}>
        <Amount localizedValue={remainingBalance} value={remainingBalance} suffix={currency} />
      </div>
    </div>
  );

  return (
    <>
      <h3>{t(i18nKeys.INVOICES)}</h3>
      {invoices.details.map(
        ({ attributes: { remaining_balance, remaining_balance_with_fees, reference } }) => (
          <>
            {renderInvoice(reference, withFees ? remaining_balance_with_fees : remaining_balance)}
          </>
        ),
      )}
    </>
  );
}
