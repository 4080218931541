import { useState } from 'react';
import { BalanceTypes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import {
  chunk,
  concat,
  filter,
  flat,
  isEmpty,
  isNullish,
  map,
  pipe,
  sort,
  sum,
  unique,
} from 'remeda';
import Amount from 'shared/components/Amount';
import {
  DebtorBalanceCreditNote,
  DebtorBalanceInvoice,
  DebtorBalancePayment,
  useLoadDebtorBalance,
  useProfile,
} from 'shared/hooks';

import {
  ActionIcon,
  Box,
  Checkbox,
  Group,
  Loader,
  LoadingOverlay,
  MultiSelect,
  Pagination,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconMinus, IconPlus } from '@tabler/icons-react';

import { ClientDetailTabProps } from '../ClientDetail.types';

import { CreditNoteRow } from './CreditNoteRow';
import { DetailRow } from './DetailRow';
import { InvoiceRow } from './InvoiceRow';
import { PaymentRow } from './PaymentRow';

import styleIdentifiers from './ClientDetailTabsBalance.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const BalanceTab = ({ debtor }: ClientDetailTabProps) => {
  const { t } = useTranslation();
  const filters = useForm({
    initialValues: {
      startDate: null,
      endDate: null,
      types: [] as Array<string>,
      statuses: [] as Array<string>,
      showSettled: true,
    },
  });
  const [isForceUnfoldActive, { toggle: toggleForceUnfold }] = useDisclosure(false);
  const [pageIndex, setPageIndex] = useState(1);
  const {
    debtorBalance: _debtorBalance,
    isDebtorBalanceLoading,
    isDebtorBalanceFetching,
    debtorBalanceError,
  } = useLoadDebtorBalance(Number(debtor.id), true);
  const profile = useProfile();

  filters.watch('statuses', ({ value: statuses }) => {
    if (!isEmpty(statuses)) filters.setFieldValue('types', ['invoice']);
  });

  filters.watch('types', ({ value: types, previousValue: previousTypes }) => {
    if (previousTypes.includes('invoice') && !types.includes('invoice')) {
      filters.setFieldValue('statuses', []);
    }
  });

  if (isDebtorBalanceLoading && _debtorBalance == null) {
    return (
      <Box w="100%" h="60vh">
        <LoadingOverlay h="100%" visible />
      </Box>
    );
  }

  if (debtorBalanceError != null) {
    return <span>Error</span>;
  }

  const debtorBalance = _debtorBalance!;

  const statuses = pipe(
    debtorBalance.invoices,
    concat(debtorBalance.creditNotes),
    map((invoice) => invoice.status),
    unique(),
    sort((a, b) => a.localeCompare(b)),
  );

  const allItems = [
    ...debtorBalance.invoices.map((item) => ({ ...item, type: 'invoice' })),
    ...debtorBalance.creditNotes.map((item) => ({ ...item, type: 'credit_note' })),
    ...debtorBalance.payments.map((item) => ({ ...item, type: 'payment' })),
  ].sort((a, b) => (a.issueDate.isBefore(b.issueDate) ? 1 : -1));

  const allItemsFilteredByStatus = allItems.filter((item) => {
    if (filters.values.statuses.length === 0) return true;
    if (item.type === 'payment') return false;
    return filters.values.statuses.includes((item as { status: string }).status);
  });

  const allItemsFilteredByType = allItemsFilteredByStatus.filter((item) => {
    if (filters.values.types.length === 0) return true;
    return filters.values.types.includes(item.type);
  });

  const allItemsFilteredBySettledAmount = allItemsFilteredByType.filter((item) => {
    if (filters.values.showSettled) return true;

    switch (item.type) {
      case 'invoice':
        return item.remainingBalance + (item as DebtorBalanceInvoice).remainingLateFees !== 0;
      case 'credit_note':
        return item.remainingBalance !== 0;
      default:
        return true;
    }
  });

  const allItemsFilteredByTypeAndDate = (() => {
    if (isNullish(filters.values.startDate) && isNullish(filters.values.endDate)) {
      return allItemsFilteredBySettledAmount;
    }

    return allItemsFilteredBySettledAmount.filter((item) => {
      if (filters.values.startDate && filters.values.endDate) {
        return (
          item.issueDate.isSameOrAfter(filters.values.startDate) &&
          item.issueDate.isSameOrBefore(filters.values.endDate)
        );
      }

      if (filters.values.startDate) {
        return item.issueDate.isSameOrAfter(filters.values.startDate);
      }

      return item.issueDate.isSameOrBefore(filters.values.endDate);
    });
  })();

  const totalCreditNotesSum = debtorBalance.creditNotes.reduce(
    (acc, creditNote) => acc + creditNote.totalTvac * -1,
    0,
  );

  const remainingLateFees = debtorBalance.invoices.reduce(
    (acc, invoice) => acc + invoice.remainingLateFees,
    0,
  );

  const totalLateFeesBilled = debtorBalance.invoices.reduce(
    (acc, invoice) => acc + invoice.lateFees,
    0,
  );

  const totalBilled = debtorBalance.invoices.reduce((acc, invoice) => acc + invoice.totalTvac, 0);

  const unmatchedPaymentsSum = debtorBalance.payments.reduce(
    (acc, payment) => acc + payment.remainingBalance,
    0,
  );

  const matchedPaymentsSum =
    debtorBalance.invoices.reduce((acc, invoice) => {
      // HACK: In some cases, we receive a payment that includes late fees, but the late fees are later
      // discarded. To avoid overcontributing to the total payments sum, we only contribute the initial total
      // of the invoice if the remaining balance is 0.

      // Hack 2: It turns out that in some cases, the invoice will have both payments and credit notes.
      // We thus must remove the credit note amount in those cases.
      if (invoice.remainingBalance + invoice.lateFees === 0 && !isEmpty(invoice.matchedPayments)) {
        return (
          acc +
          invoice.totalTvac -
          invoice.creditNotes.reduce((acc, creditNote) => acc + creditNote.amount, 0)
        );
      }

      const total = invoice.matchedPayments.reduce(
        (totalAcc, payment) => totalAcc + (payment.isLoss ? 0 : payment.amount),
        0,
      );

      return acc + total;
    }, 0) +
    debtorBalance.creditNotes.reduce((acc, invoice) => {
      const total =
        invoice.payments?.reduce((totalAcc, payment) => totalAcc + payment.amount, 0) ?? 0;

      return acc + total;
    }, 0);

  const totalPaymentsSum = unmatchedPaymentsSum + matchedPaymentsSum;

  const lostAmount = pipe(
    debtorBalance.invoices,
    map((invoice) => invoice.matchedPayments),
    flat(),
    filter((payment) => payment.isLoss),
    map((payment) => payment.amount),
    sum(),
  );

  const remainingBalance =
    totalBilled + totalLateFeesBilled - totalPaymentsSum - totalCreditNotesSum;

  const Icon = isForceUnfoldActive ? IconMinus : IconPlus;

  const types = Object.values(BalanceTypes).map((type) => ({
    value: type,
    label:
      type === BalanceTypes.invoice ? t(i18nKeys.COMMON.INVOICE) : t(i18nKeys[type.toUpperCase()]),
  }));

  const pages = chunk(allItemsFilteredByTypeAndDate, profile.preferences.itemsPerPage);

  const getAmount = (item: any) => {
    const res = (() => {
      if (item.type === 'payment') return Number(item.remainingBalance) * -1;
      if (item.type === 'credit_note') return Number(item.remainingBalance);
      if (item.type === 'invoice')
        return Number(item.remainingBalance) + Number(item.remainingLateFees);
      return 0;
    })();
    return res;
  };

  const tableTotal = pipe(pages, flat(), map(getAmount), sum());

  return (
    <>
      <Stack mb="xl">
        <Title ta="center" order={3}>
          {t(i18nKeys.DEBTOR.SUMMARY)}
        </Title>
        <DetailRow
          left={<Text>{t(i18nKeys.AMOUNT_BILLED)}</Text>}
          right={
            <Text fw="bold" c="blue">
              <Amount value={totalBilled} />
            </Text>
          }
        />
        <DetailRow
          left={<Text>{t(i18nKeys.CLAIMED_LATE_FEES)}</Text>}
          right={
            <Text c="blue" fw="bold">
              <Amount value={totalLateFeesBilled} />
            </Text>
          }
        />
        <DetailRow
          left={<Text>{t(i18nKeys.NAV.CREDIT_NOTES)}</Text>}
          right={
            <Text c="red">
              <Amount value={totalCreditNotesSum} />
            </Text>
          }
        />
        <DetailRow
          left={<Text>{t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)}</Text>}
          right={
            <Text c="red">
              <Amount value={totalPaymentsSum} />
            </Text>
          }
        />
        <DetailRow
          left={<Text>{t(i18nKeys.LOST_SUM)}</Text>}
          right={
            <Text c="red">
              <Amount value={lostAmount} />
            </Text>
          }
        />
        <DetailRow
          left={
            <Stack gap={0}>
              <Text>{t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE)}</Text>
              <Text size="sm" c="gray.6">
                {t(i18nKeys.WITH_FEES)}
              </Text>
            </Stack>
          }
          right={
            <Text mb={20} c="blue" fw="bold">
              <Amount value={remainingBalance} />
            </Text>
          }
        />
        <DetailRow
          left={<Text>{t(i18nKeys.REMAINING_LATE_FEES)}</Text>}
          right={
            <Text c="blue" fw="bold">
              <Amount value={remainingLateFees} />
            </Text>
          }
        />
        <DetailRow
          left={
            <Stack gap={0}>
              <Text>{t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE)}</Text>
              <Text size="sm" c="gray.6">
                {t(i18nKeys.WITHOUT_FEES)}
              </Text>
            </Stack>
          }
          right={
            <Text mb={20} c="blue" fw="bold">
              <Amount value={remainingBalance - remainingLateFees} />
            </Text>
          }
        />
      </Stack>

      <Group mb="xl" justify="flex-end">
        {isDebtorBalanceFetching && <Loader size="xs" />}
        <DatePickerInput
          miw={110}
          valueFormat="DD/MM/YY"
          clearable
          placeholder={t(i18nKeys.FORM.START_DATE)}
          popoverProps={{ shadow: 'md' }}
          {...filters.getInputProps('startDate')}
        />
        <DatePickerInput
          miw={110}
          valueFormat="DD/MM/YY"
          clearable
          placeholder={t(i18nKeys.FORM.END_DATE)}
          popoverProps={{ shadow: 'md' }}
          {...filters.getInputProps('endDate')}
        />
        <MultiSelect
          miw={210}
          clearable
          {...filters.getInputProps('statuses')}
          data={statuses}
          comboboxProps={{ shadow: 'md' }}
          placeholder={isEmpty(filters.values.statuses) ? 'Status' : ''}
        />
        <MultiSelect
          miw={210}
          clearable
          {...filters.getInputProps('types')}
          data={types}
          comboboxProps={{ shadow: 'md' }}
          placeholder={isEmpty(filters.values.types) ? t(i18nKeys.CLIENT.DETAIL.TYPES) : ''}
        />
        <Checkbox
          {...filters.getInputProps('showSettled', { type: 'checkbox' })}
          label={t(i18nKeys.CLIENT.DETAIL.SHOW_SETTLED)}
        />
      </Group>

      <table className={styles('balance-table')}>
        <thead>
          <tr>
            <th />
            <th onClick={toggleForceUnfold}>
              <Group>
                <ActionIcon variant="transparent" onClick={toggleForceUnfold}>
                  <Icon color="black" />
                </ActionIcon>
                <Text fw={600}>{t(i18nKeys.FORM.REFERENCE)}</Text>
              </Group>
            </th>
            <th>
              <Text fw={600}>{t(i18nKeys.STATUS)}</Text>
            </th>
            <th>
              <Text fw={600}>{t(i18nKeys.ISSUE_DATE_SHORT)}</Text>
            </th>
            <th>
              <Text fw={600}>{t(i18nKeys.DUE_DATE_SHORT)}</Text>
            </th>
            <th>
              <Text fw={600} c="blue">
                {t(i18nKeys.DEBIT)}
              </Text>
            </th>
            <th>
              <Text fw={600} c="red">
                {t(i18nKeys.CREDIT)}
              </Text>
            </th>
            <th>
              <Text fw={600}>{t(i18nKeys.BALANCE)}</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(allItemsFilteredByTypeAndDate) ? (
            pages[pageIndex - 1]?.map((item) => {
              switch (item.type) {
                case 'invoice': {
                  return (
                    <InvoiceRow
                      // @ts-ignore
                      key={item.id}
                      invoice={item as DebtorBalanceInvoice}
                      isForceUnfoldActive={isForceUnfoldActive}
                    />
                  );
                }
                case 'credit_note': {
                  return (
                    <CreditNoteRow
                      // @ts-ignore
                      key={item.id}
                      creditNote={item as DebtorBalanceCreditNote}
                      isForceUnfoldActive={isForceUnfoldActive}
                    />
                  );
                }
                case 'payment': {
                  return (
                    <PaymentRow
                      key={(item as DebtorBalancePayment).id}
                      payment={item as DebtorBalancePayment}
                    />
                  );
                }
                default: {
                  return null;
                }
              }
            })
          ) : (
            <tr>
              <td colSpan={7} className={styles('no-result')}>
                {t(i18nKeys.NO_RESULT)}
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={7} />
            <td>
              <Text fw="bold" ta="right">
                <Amount value={tableTotal} />
              </Text>
            </td>
          </tr>
        </tfoot>
      </table>
      <Group w="100%" justify="flex-end" mt="md">
        <Pagination size="sm" total={pages.length} value={pageIndex} onChange={setPageIndex} />
      </Group>
    </>
  );
};
