import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Currency } from 'api/models';
import { Location } from 'history';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { treatInvoice } from 'shared/serializer';
import { clientActions } from 'store/client/client.actions';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { CURRENCY_CODES, CurrencyCode } from 'types/currency';
import { LightInvoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import { captureMessage } from '@sentry/react';

import { reducerState } from './view';

//! THIS FILE IS FORSAKEN AND NO CODE SHOULD BE ADDED HERE

// todo: shouldn't be needed
export function useForceUpdate() {
  const [, setTick] = useState(0);

  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);

  return update;
}

// todo: shouldn't be needed
export function useRefresh() {
  const [refresh, setRefresh] = useState(0);

  const doRefresh = () => {
    setRefresh((prev) => prev + 1);
  };
  return { refresh, doRefresh };
}

export function usePrevious<T>(value: any): T | null {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useCombinedRefs(...refs): any {
  const targetRef = useRef(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

type FilterFormProps = {
  initialValues: any;
  fieldToRegister: any;
  onSubmit: Function;
  submitParser?: Function;
  shouldUnregister?: boolean;
};

export function useFilterForm({
  initialValues,
  fieldToRegister,
  onSubmit,
  submitParser = (values) => values,
  shouldUnregister = true,
}: FilterFormProps) {
  const [{ submit, form }, setState] = useReducer(reducerState, {
    form: useForm({ shouldUnregister }),
  });
  const { getValues } = form;
  const resetForm = () => {
    for (const key in getValues()) {
      form.setValue(key, '');
    }
    onSubmit(submitParser(form.getValues()));
  };

  useEffect(() => {
    fieldToRegister?.forEach((field) => {
      form.register(field);
    });
    for (const key in initialValues) {
      form.setValue(key, initialValues[key]);
    }
    setState({
      submit: () => onSubmit(submitParser(form.getValues())),
    });
  }, []);

  const formData = getValues();
  return {
    formData,
    form: form as UseFormReturn<any, any>,
    submit,
    resetForm,
  };
}

export function useMediaQuery(queryString: string) {
  const query = useMemo(() => window.matchMedia(queryString), [queryString]);

  const [doesMatch, setMatches] = useState(query.matches);

  useEffect(() => {
    const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
    if (query.addEventListener != null) {
      query.addEventListener('change', listener);
      return () => query.removeEventListener('change', listener);
    }

    captureMessage('In useMediaQuery, query.addEventListener is null.', {
      extra: { query, queryType: typeof query, queryKeys: Object.keys(query) },
    });

    return () => {};
  }, [query]);

  return doesMatch;
}

export type UseSearchParams = {
  page_limit?: number;
  callback?: (data: any) => void;
};

export type UseSearchInvoiceParams = UseSearchParams & {
  reference?: string | string[];
  debtor_id?: string | number;
  currency?: CurrencyCode;
  exclude?: string[] | number[];
};

export const getSearchInvoicesUrlParams = ({ search }: Location) => {
  const params = new URLSearchParams(search);
  return {
    reference: params.has('reference') ? params.getAll('reference') : undefined,
    exclude: params.has('exclude') ? params.getAll('exclude') : undefined,
    currency: (params.get('currency') as CurrencyCode) || undefined,
    debtor_id: params.get('debtor_id') || undefined,
  };
};

export const useSearchInvoices = () => {
  const invoices = useSelector(
    (state: StoreState) => (state.creditNote.invoiceSearch?.data || []) as LightInvoice[],
  ).map((e) => treatInvoice<LightInvoice>(e));

  const getInvoices = (
    params?: UseSearchInvoiceParams,
    callback?: ({ data }: { data: LightInvoice[] }) => void,
  ) => {
    creditNoteActions.invoiceSearch({
      ...params,
      callback,
    });
  };

  return { invoices, getInvoices };
};

// Debtors
export type UseSearchDebtorParams = UseSearchParams & {
  name?: string | string[];
};
export const useSearchDebtors = () => {
  const debtors = useSelector((state: StoreState) => state.client?.search?.data) || [];

  const getDebtors = (params?: UseSearchDebtorParams) => {
    clientActions.search(params);
  };

  return { debtors, getDebtors };
};
