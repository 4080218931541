import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import {
  addResourceNameToQueryResult,
  ApiError,
  useAxiosInstance,
} from '../../../shared/hooks/utils';

const FailedReminderPreviewSchema = z.object({
  emailPreview: z.string(),
});

type FailedReminderPreview = z.infer<typeof FailedReminderPreviewSchema>;

export type LoadFailedReminderPreviewVariables = {
  id: String;
};

export async function loadFailedReminderPreviewQueryFn(
  axiosInstance: Promise<AxiosInstance>,
  { queryKey },
) {
  const instance = await axiosInstance;

  const { data } = await instance.get(`/payment_requests/${queryKey[2].id}/preview`, {
    params: queryKey[2],
  });
  return FailedReminderPreviewSchema.parse(data);
}

export const useLoadFailedReminderPreview = (
  variables: LoadFailedReminderPreviewVariables,
  options: { enabled: boolean } = { enabled: true },
) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<FailedReminderPreview, ApiError>({
    queryKey: ['reminders', 'failed', variables, 'preview'],
    queryFn: (context) => loadFailedReminderPreviewQueryFn(axiosInstance, context),
    enabled: options.enabled,
  });

  return addResourceNameToQueryResult<FailedReminderPreview, ApiError, 'failedReminderPreview'>(
    'failedReminderPreview',
    queryResult,
  );
};
