import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { isNonNullish } from 'remeda';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { LightThirdPartyCase, ThirdPartyCase } from 'shared/hooks';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { Center, Indicator } from '@mantine/core';

import { useMarkConversationAsRead } from '../../hooks';
import { Icon, IconName } from '../Icon';
import debtCollectorIcon from '../InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';
import TpCaseDetail from '../TpCaseDetail';
import TpCaseStatusItem from '../TpCaseStatusItem';

import styleIdentifiers from './thirdpartyCaseListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  // The name of this field is imposed by the CustomTable component which injects a prop with this name
  item: LightThirdPartyCase;
  noDebtorName?: boolean;
};

export default function ThirdpartyCaseListItem({ item: caseDetail, noDebtorName }: Props) {
  const { t, currentLang } = useTranslation();
  const { markConversationAsRead } = useMarkConversationAsRead();

  const status = (() => {
    if (caseDetail.closed) return 'close';
    if (caseDetail.started) return 'open';
    return 'notOpen';
  })();

  if (caseDetail.case_type == null) return null;

  // Sentry 15C
  const caseTypeTranslated = t(
    i18nKeys.THIRD_PARTY_CASES.CASE_TYPE[caseDetail.case_type.toUpperCase()],
  );

  const openModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.FULL,
      title: t(i18nKeys.THIRD_PARTY_CASES.MODAL_TITLE, {
        case_type: caseTypeTranslated,
        case_reference: caseDetail.case_id || caseDetail.invoice_reference,
      }),
      children: <TpCaseDetail item={caseDetail} />,
      onClose: () => {
        if (isNonNullish(caseDetail.conversation))
          markConversationAsRead({ id: caseDetail.conversation.id });
      },
    });
  };

  // todo: duplicated elsewhere, extract to utils
  const getIcon = () => {
    switch (caseDetail.case_type) {
      case 'lawyer':
        return <Icon name={IconName.LAW} size="18px" className={styles('icon')} />;
      case 'bailiff':
        return <Icon name={IconName.BALANCE} size="18px" className={styles('icon')} />;
      case 'debt_collector':
        return <img width="24px" height="24px" src={debtCollectorIcon} alt="Debt collector" />;
      case 'callcenter':
      default:
        return <Icon name={IconName.PHONE_CALL} size="18px" className={styles('icon')} />;
    }
  };

  return (
    <tr className={styles('thirdparty-case-list-item')} onClick={openModal}>
      <td className={styles('check-box-container', 'small')}>
        <Center>
          <Indicator color="red" size={5} disabled={!caseDetail?.conversation?.unread} zIndex={1}>
            {getIcon()}
          </Indicator>
        </Center>
      </td>
      <td>{caseTypeTranslated}</td>
      <td className={styles('InvoiceNumber')}>
        {caseDetail.case_id || t(i18nKeys.THIRD_PARTY_CASES.NOT_COMMUNICATED)}
      </td>
      <td className={styles('Date')}>
        <DateItem lg={currentLang} date={new Date(caseDetail.created_at)} />
      </td>
      <td className={styles('Date')}>{dayjs(caseDetail.last_update).fromNow()}</td>
      {!noDebtorName && <td className={styles('Client')}>{caseDetail.invoice_debtor_name}</td>}
      <td className={styles('StatusContainer')}>
        <TpCaseStatusItem status={status} />
      </td>
      <td className={styles('Amount')}>
        <Amount value={caseDetail.total_tvac} />
      </td>
      <td className={styles('Amount')}>
        <Amount value={caseDetail.total_remaining} />
      </td>
    </tr>
  );
}
