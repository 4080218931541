import { EmailAttributes, Notification } from 'api/models';

type EmailRenderingProps = {
  data: EmailAttributes | string;
};

export default function EmailRendering({ data }: EmailRenderingProps) {
  if (typeof data === 'string') return <span>{data}</span>;

  const { description, value, notifications } = data;
  const isDebtor = notifications.includes(Notification.Debtor);

  return (
    <div>
      {!isDebtor && (
        <>
          <span>
            <b>{description}</b>
          </span>
          <br />
        </>
      )}
      <span>{value}</span>
    </div>
  );
}
