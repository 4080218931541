import { Id, PaymentPlanAttributes } from 'api/models';
import { PaymentPlanDetail } from 'app/Private/PaymentPlans';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import AddressBlock from 'shared/components/AddressBlock';
import BankAccount from 'shared/components/BankAccount';
import ChooseCollaboratorModal from 'shared/components/ChooseCollaboratorModal';
import CopyToClipboard from 'shared/components/CopyToClipboard';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import TvaNumber from 'shared/components/TvaNumber';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';
import { currencySymbol, formatAmount } from 'shared/utils';
import { useGetConstants } from 'shared/utils/selectors';
import { formattedDate } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import { Debtor } from 'types/store/client-state';

import styleIdentifiers from './TabInfoDebtor.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientInfoProps = {
  debtor: Debtor;
  reload: Function;
};

type UglyPaymentPlan = PaymentPlanAttributes & { id: Id };

export const TabInfoDebtor = ({ debtor, reload }: ClientInfoProps) => {
  const { t, currentLang } = useTranslation();

  const { company } = useLoadCompanyConfiguration();
  const constants = useGetConstants();
  const profile = useProfile();

  const editAccountManager = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CHOOSE_ACCOUNT_MANAGER),
      children: (
        <ChooseCollaboratorModal
          initialValues={debtor.account_manager}
          onSubmit={(values) => {
            clientActions.assignToAccManager({
              id: debtor.id,
              data: values,
              callback: () => {
                reload();
                dialogHide(DialogShowId.CUSTOM);
              },
            });
          }}
        />
      ),
    });
  };

  const goToPlan = (planId) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(i18nKeys.PAYMENT_PLAN.TITLE.ONE),
      children: <PaymentPlanDetail planId={planId} inModal />,
    });
  };

  return (
    <div className={styles('ClientInfo', 'card')}>
      <h3>{t(i18nKeys.CLIENT.DETAIL.INFORMATIONS)} </h3>
      <div className={styles('info-wrapper')}>
        <div className={styles('left')}>
          {debtor.external_id && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.EXTERNAL_ID)}</span>
              <span className={styles('value')}>{debtor.external_id}</span>
            </div>
          )}
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}> {t(i18nKeys.ADDRESS)}</span>
            <AddressBlock className={styles('value')} address={debtor.address_attributes} />
          </div>

          {debtor.vat_number && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.VAT_NUMBER)}</span>
              <TvaNumber className={styles('value')} value={debtor.vat_number} />
            </div>
          )}
          {debtor.currency && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.DEBTOR.CURRENCY)}</span>
              <div className={styles('value')}>{debtor.currency}</div>
            </div>
          )}
          {debtor.reference && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.DEBTOR.REFERENCE)}</span>
              <div className={styles('value')}>{debtor.reference}</div>
            </div>
          )}
          {debtor.debtor_bank_accounts_attributes &&
            debtor.debtor_bank_accounts_attributes.length > 0 && (
              <div className={styles('subtitle-value')}>
                <span className={styles('subtitle')}>{t(i18nKeys.COMMON.BANK_ACCOUNT)}</span>
                {debtor.debtor_bank_accounts_attributes.map((bankAccount) => (
                  <div className={styles('value')} key={bankAccount.id}>
                    <BankAccount className={styles('iban')} value={bankAccount.iban} />
                    {bankAccount.bic && (
                      <div className={styles('bic')}>{`BIC : ${bankAccount.bic}`}</div>
                    )}
                  </div>
                ))}
              </div>
            )}

          {debtor.default_invoice_template && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.DEFAULT_INVOICE_TEMPLATE)}</span>
              <div className={styles('value')}>{debtor.default_invoice_template.name}</div>
            </div>
          )}
          {debtor.default_credit_note_template && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.DEFAULT_CN_TEMPLATE)}</span>
              <div className={styles('value')}>{debtor.default_credit_note_template.name}</div>
            </div>
          )}
        </div>
        <div className={styles('right')}>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.EMAIL)}</span>
            {debtor.emails.map((email) => (
              <a key={email} href={`mailto:${email}`} className={styles('value', 'block')}>
                <span>{email}</span>
              </a>
            ))}
          </div>
          {debtor.locale != null && AVAILABLE_LANGUAGES != null && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.FORM.CLIENT.CONTACT_LANG)}</span>
              <span className={styles('value')}>
                {AVAILABLE_LANGUAGES.find((lang) => lang.value === debtor.locale)!.description}
              </span>
            </div>
          )}
          {debtor.phones_attributes.length > 0 && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.PHONES)}</span>
              {debtor.phones_attributes.map((phone, index) => (
                <a key={index} href={`tel:${phone.number}`} className={styles('value')}>
                  <div className={styles('value')}>{phone.number}</div>
                </a>
              ))}
            </div>
          )}
          {debtor.co_contractor && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.DETAIL.COCONTRACTOR)}</span>
              <div className={styles('value')}>{t(i18nKeys.CLIENT.DETAIL.APPLIED_VAT_0)}</div>
            </div>
          )}
          {debtor.intracom && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}> {t(i18nKeys.INVOICE.INTRACOMMUNITY)}</span>
              <div className={styles('value')}>{t(i18nKeys.CLIENT.DETAIL.APPLIED_VAT_0)}</div>
            </div>
          )}
          {debtor.sending_media_attributes.length > 0 && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.DEBTOR.SENDING_MEDIUMS)}</span>
              <div className={styles('value')}>
                {debtor.sending_media_attributes.map(
                  (sendingMedium, index) =>
                    `${index !== 0 ? ' - ' : ''}${
                      constants.sending_medium.find((item) => sendingMedium.name === item.value)!
                        .description
                    }`,
                )}
              </div>
            </div>
          )}
          {debtor.recovery_plan && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}> {t(i18nKeys.RECOVERY_PLAN)}</span>
              <div className={styles('value')}>{debtor.recovery_plan.name}</div>
            </div>
          )}
          {debtor.days_between_reminder && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>
                {' '}
                {t(i18nKeys.CLIENT.DETAIL.MIN_DAYS_BETWEEN_REMINDER)}
              </span>
              <div className={styles('value')}>
                {t(i18nKeys.DAY_S, { count: debtor.days_between_reminder })}
              </div>
            </div>
          )}
          {debtor.minimum_saldo_sending !== 0 && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>
                {' '}
                {t(i18nKeys.FORM.PREFERENCES.DONT_SEND_REMINDER_TO_DEBTOR_UNDER, {
                  text: currencySymbol(undefined, company),
                })}
              </span>
              <div className={styles('value')}>{formatAmount(debtor.minimum_saldo_sending)}</div>
            </div>
          )}
        </div>
        <div className={styles('right')}>
          {debtor.has_payment_plan && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)}</span>
              {debtor.payment_plans_attributes.map((payment_plan) => (
                <div
                  key={(payment_plan as UglyPaymentPlan).id}
                  className={styles('value', 'payment-plan')}
                >
                  <p>#{(payment_plan as UglyPaymentPlan).id}</p>
                  <Icon
                    name={IconName.EXTERNAL_LINK}
                    color={IconColor.BLUE}
                    onClick={() => goToPlan((payment_plan as UglyPaymentPlan).id)}
                  />
                </div>
              ))}
            </div>
          )}
          {debtor.paused && (
            <>
              <div className={styles('subtitle-value')}>
                <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.PAUSED_REASON)}</span>
                <div className={styles('value')}>{debtor.reason}</div>
              </div>
              {debtor.end_pause_at && (
                <div className={styles('subtitle-value')}>
                  <span className={styles('subtitle')}>{t(i18nKeys.END_OF_PAUSE)}</span>
                  <div className={styles('value')}>
                    {formattedDate(new Date(debtor.end_pause_at), currentLang)}
                  </div>
                </div>
              )}
            </>
          )}
          {debtor.disputed && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.CLIENT.DISPUTED_REASON)}</span>
              <div className={styles('value')}>{debtor.reason}</div>
            </div>
          )}
          {debtor.csv_id && (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>csv id</span>
              <div className={styles('value')}>{debtor.csv_id}</div>
            </div>
          )}
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.ACCOUNT_MANAGER)}</span>
            <div className={styles('value')}>
              {debtor.account_manager
                ? `${debtor.account_manager.first_name} ${debtor.account_manager.last_name}`
                : t(i18nKeys.NOT_ASSIGNED)}
              {profile.isAdmin && (
                <Icon
                  name={IconName.PENCIL}
                  className={styles('ml-10')}
                  onClick={editAccountManager}
                />
              )}
            </div>
          </div>
          {/* This value can be empty if there are no invoices yet. The reason is that historically,
          the token required for the debtor to access the payment portal was stored on the invoice
          Now that multiple invoices can exisst for one client, the link is generated with the token of that last open invoice, if any */}
          {debtor.debtor_reaction_link && (
            <div className={styles('bottom')}>
              <CopyToClipboard
                textToCopy={debtor.debtor_reaction_link!}
                text={t(i18nKeys.CLIENT.PORTAIL_LINK)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
