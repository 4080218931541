import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { isPublicEnvironment, useIsFeatureFlagEnabled } from 'shared/utils';

import {
  IconCalendarCheck,
  IconCalendarClock,
  IconCalendarDot,
  IconCalendarExclamation,
} from '@tabler/icons-react';

import { Menu, MenuItem } from '../types';

export function useBuildRemindersMenu(): Menu {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  return useMemo(() => {
    const items: Array<MenuItem | Menu> = [
      {
        path: '/invoices/actions',
        label: t(i18nKeys.NAV.IMINENT_REMINDERS),
        Icon: IconCalendarClock,
        unreadCount: company?.reminders_to_send_count?.next_five_days,
      },
      {
        path: '/reminders',
        label: t(i18nKeys.REMINDER_LIST.TITLE),
        Icon: IconCalendarCheck,
        tagLabel: 'beta',
      },
      {
        path: '/reminders/failed',
        label: t(i18nKeys.FAILED_REMINDERS.MENU_LABEL),
        Icon: IconCalendarExclamation,
        tagLabel: 'beta',
      },
    ];

    return {
      parent: null,
      label: t(i18nKeys.REMINDERS_MENU),
      Icon: IconCalendarDot,
      items,
    };
  }, [company, t]);
}
