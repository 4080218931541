import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { isNullish } from 'remeda';
import { Button, CustomSelect, DateSelector, Textarea } from 'shared/io';
import { getCurrencySymbolFromCurrencyCode } from 'shared/utils';
import { CurrencyCode } from 'types/currency';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import { Group, NumberInput, Radio, Stack, Text } from '@mantine/core';

import styleIdentifiers from './encodePaymentForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type EncodePaymentFormProps = {
  onSubmit: onSubmitFunction;
  currency: CurrencyCode;
  totalAmount: number;
  amountWithoutFees?: number;
};

type RadioGroupValue = 'percentage' | 'fullAmountWithFees' | 'amountWithoutFees' | 'customAmount';

export default function EncodePaymentForm({
  onSubmit,
  currency,
  totalAmount,
  amountWithoutFees,
}: EncodePaymentFormProps) {
  const { t } = useTranslation();
  const [radioGroupValue, setRadioGroupValue] = useState<RadioGroupValue>('fullAmountWithFees');
  const [percentageValue, setPercentageValue] = useState('10');

  const constants = useSelector((state: StoreState) => state.app.constants);
  const form = useForm();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    unregister,
    watch,
  } = form;

  useEffect(() => {
    register('received_at', { required: true });
    return () => {
      unregister('received_at');
    };
  }, []);

  useEffect(() => {
    setValue('amount', 0);
  }, []);

  const handlePercentageChange = (value: string | number) => {
    setPercentageValue(String(value));
    changeRangeAmount(value);
  };

  const changeRangeAmount = (value) => {
    setValue('amount', (Math.round(((totalAmount * value) / 100) * 100) / 100).toFixed(2));
  };

  const changeAmount = (value) => {
    setValue('amount', Number(value).toFixed(2));
  };

  const formatAmount = (value: number) => (Math.round(value * 100) / 100).toFixed(2);

  const amount = watch('amount');

  const shouldDisplayAmountWithoutFees = () => {
    if (isNullish(amountWithoutFees)) return false;
    if (amountWithoutFees <= 0) return false;
    if (amountWithoutFees === totalAmount) return false;

    return true;
  };

  return (
    <FormProvider {...form}>
      <form className={styles('EncodePaymentForm')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('head')}>{t(i18nKeys.SHARED.ENCODE_PAYMENT.TITLE)}</div>
        <div className={styles('content')}>
          <div className={styles('grid-row')}>
            <div className={styles('col-12')}>
              <div className={styles('label-input')}>
                {t(i18nKeys.AMOUNT_CURRENCY, { text: getCurrencySymbolFromCurrencyCode(currency) })}
              </div>
              <Stack mt="sm">
                <Radio.Group
                  value={radioGroupValue}
                  onChange={(value: string) => setRadioGroupValue(value as RadioGroupValue)}
                >
                  <Stack gap="md">
                    <Radio
                      value="fullAmountWithFees"
                      styles={{ body: { alignItems: 'center' } }}
                      onClick={() => changeAmount(totalAmount)}
                      label={
                        <Group>
                          <Text size="sm">{t(i18nKeys.SHARED.ENCODE_PAYMENT.FULL_AMOUNT)}</Text>
                          {radioGroupValue === 'fullAmountWithFees' && (
                            <Text size="sm">
                              ({totalAmount + getCurrencySymbolFromCurrencyCode(currency)})
                            </Text>
                          )}
                        </Group>
                      }
                    />
                    {shouldDisplayAmountWithoutFees() && (
                      <Radio
                        value="amountWithoutFees"
                        styles={{ body: { alignItems: 'center' } }}
                        onClick={() => changeAmount(amountWithoutFees)}
                        label={
                          <Group>
                            <Text size="sm">
                              {t(i18nKeys.SHARED.ENCODE_PAYMENT.AMOUNT_WITHOUT_FEES)}
                            </Text>
                            {radioGroupValue === 'amountWithoutFees' && (
                              <Text size="sm">
                                (
                                {amountWithoutFees &&
                                  formatAmount(amountWithoutFees) +
                                    getCurrencySymbolFromCurrencyCode(currency)}
                                )
                              </Text>
                            )}
                          </Group>
                        }
                      />
                    )}
                    <Radio
                      onFocus={() => changeAmount(0)}
                      value="percentage"
                      styles={{ body: { alignItems: 'center' } }}
                      label={
                        <Group>
                          <Text size="sm">{t(i18nKeys.SHARED.ENCODE_PAYMENT.PERCENTAGE)}</Text>
                          <NumberInput
                            value={percentageValue}
                            size="xs"
                            disabled={radioGroupValue !== 'percentage'}
                            onChange={handlePercentageChange}
                            w={80}
                            allowNegative={false}
                            suffix="%"
                            max={100}
                          />
                          {radioGroupValue === 'percentage' && (
                            <Text size="sm">
                              (
                              {formatAmount((Number(percentageValue) / 100) * totalAmount) +
                                getCurrencySymbolFromCurrencyCode(currency)}
                              )
                            </Text>
                          )}
                        </Group>
                      }
                    />
                    <Radio
                      value="customAmount"
                      styles={{ body: { alignItems: 'center' } }}
                      label={
                        <Group>
                          <Text size="sm">{t(i18nKeys.SHARED.ENCODE_PAYMENT.CUSTOM_AMOUNT)}</Text>
                          <NumberInput
                            size="xs"
                            disabled={radioGroupValue !== 'customAmount'}
                            w={130}
                            value={amount}
                            allowNegative={false}
                            onChange={changeAmount}
                            suffix={getCurrencySymbolFromCurrencyCode(currency)}
                            max={totalAmount}
                          />
                        </Group>
                      }
                    />
                  </Stack>
                </Radio.Group>
              </Stack>
              {errors.amount && 'ERROR.FIELD_REQUIRED'}
            </div>
            <div className={styles('col-6')}>
              <Controller
                defaultValue=""
                rules={{ required: true }}
                name="received_at"
                render={() => (
                  <DateSelector
                    name="received_at"
                    handleChange={(value) =>
                      setValue('received_at', value, { shouldValidate: true })
                    }
                    label={t(i18nKeys.FORM.PAYMENT_DATE)}
                    noMinDate
                    withBorder
                  />
                )}
              />
            </div>
            <div className={styles('col-6')}>
              <Controller
                rules={{ required: true }}
                name="modality"
                defaultValue=""
                render={() => (
                  <CustomSelect
                    name="modality"
                    keyText="description"
                    keyValue="value"
                    label={t(i18nKeys.MODALITY)}
                    withBorder
                    items={constants.payment_modalities}
                  />
                )}
              />
            </div>
            <div className={styles('col-12')}>
              <Textarea register={register('notes')} withBorder label={t(i18nKeys.NOTES)} />
            </div>
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button noShadow noMargin label={t(i18nKeys.SAVE)} type="submit" />
        </div>
      </form>
    </FormProvider>
  );
}
