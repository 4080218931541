import { AxiosInstance } from 'axios';
import {
  addActionNameToMutationResult,
  ApiError,
  ApiResponse,
  useAxiosInstance,
} from 'shared/hooks/utils';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type ResendReminderVariables = {
  id: string;
  emails: Array<string>;
};

export async function resendReminderFn(
  axiosInstance: Promise<AxiosInstance>,
  { id, emails }: ResendReminderVariables,
): Promise<ApiResponse> {
  const instance = await axiosInstance;

  return instance.post(`/payment_requests/${id}/resend`, { emails });
}

export function useResendReminder() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, ResendReminderVariables>({
    mutationFn: (variables) => resendReminderFn(axiosInstance, variables),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['reminders'],
      });
    },
    onError: (error: ApiError) => {
      notifications.show({
        color: 'red',
        message:
          error.response?.data.errorMessage ??
          error.response?.data.error_message ??
          error.response?.data.errors[0] ??
          error.message,
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    ResendReminderVariables,
    'resendReminder'
  >('resendReminder', mutation);
}
