import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

export const ChiftConfigurationVariablesSchema = z.object({
  tp_accounting_automatic_sync: z.boolean().optional(),
  tp_accounting_issue_date_limit: z.date().optional().nullable(),
  use_chift_import_integration: z.boolean().optional(),
  fetch_pdf: z.boolean().optional(),
});

export type ChiftConfigurationVariables = z.infer<typeof ChiftConfigurationVariablesSchema>;

export async function updateChiftConfiguration(
  axiosInstance: Promise<AxiosInstance>,
  data: ChiftConfigurationVariables,
) {
  const instance = await axiosInstance;

  return instance.put('/chift_configurations', data);
}

export function useUpdateChiftConfiguration() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, ChiftConfigurationVariables>({
    mutationFn: (data) => updateChiftConfiguration(axiosInstance, data),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['update-chift-configuration'],
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    ChiftConfigurationVariables,
    'updateChiftConfiguration'
  >('updateChiftConfiguration', mutation);
}
