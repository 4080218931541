import classNames from 'classnames/bind';
import Amount from 'shared/components/Amount';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Saldo } from 'shared/hooks/use-load-dashboard';
import { CurrencyCode } from 'types/currency';

import styleIdentifiers from './MultiCurrencyData.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  data: Saldo;
  withTotal?: boolean;
  prefix?: string;
};

export default function MultiCurrencyData({ data, withTotal = false, prefix }: Props) {
  const { localizedTotal, ...otherCurrency } = data;
  const { company } = useLoadCompanyConfiguration();

  const hasMultipleCurrency = Object.keys(otherCurrency).length > 1;
  return (
    <div className={styles('MultiCurrencyData')}>
      {Object.keys(otherCurrency).map((currency) => (
        <div key={currency} className={styles('deviseLine')}>
          <Amount
            key={currency}
            value={otherCurrency[currency]}
            suffix={currency as CurrencyCode}
            prefix={prefix}
          />
        </div>
      ))}
      {withTotal && (hasMultipleCurrency || Object.keys(otherCurrency)[0] !== company.currency) && (
        <div className={styles('deviseLineTotal')}>
          <Amount value={localizedTotal} prefix={prefix} />
        </div>
      )}
    </div>
  );
}
