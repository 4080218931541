import { AxiosInstance } from 'axios';
import { CurrencyCodeSchema } from 'types/currency';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ThirdPartyCaseConversationSchema } from './use-load-third-party-cases';
import { addResourceNameToQueryResult, ApiError, useLegacyAxiosInstance } from './utils';

const InvoiceFeedbackSchema = z
  .object({
    id: z.coerce.number(),
    attributes: z.object({
      conversation: ThirdPartyCaseConversationSchema,
      closed: z.boolean(),
      has_tp_case_pdf: z.boolean().nullable(),
    }),
  })
  .transform(({ id, attributes }) => ({
    id,
    ...attributes,
  }))
  .nullable();

const AttachedInvoiceSchema = z
  .object({
    id: z.string(),
    attributes: z.object({
      reference: z.string(),
      due_date: z.string(),
      total_tvac: z.coerce.number(),
      late_fees: z.coerce.number(),
      remaining_balance_with_fees: z.string(),
      amount_already_paid: z.string(),
      currency: CurrencyCodeSchema.optional(),
      localized_money_object: z.object({
        currency: CurrencyCodeSchema.optional(),
        total_htva: z.string(),
        total_tvac: z.string(),
        remaining_balance: z.string(),
        remaining_balance_with_fees: z.string(),
        total_discount_amount: z.string(),
        late_fees: z.string(),
        remaining_late_fees: z.string(),
        interest_amount: z.string(),
        fix_fee_amount: z.string(),
        penalty_clause_amount: z.string(),
        amount_already_paid: z.string(),
        terms_and_conditions: z.object({
          fix_fee: z.object({
            value: z.string(),
          }),
          penalty_clause: z.object({
            value: z.boolean(),
            minimum: z.string(),
          }),
        }),
      }),
      remaining_balance: z.string(),
      has_pdf: z.boolean(),
      additional_files: z.array(z.any()), // @thibault: todo
    }),
  })
  .transform(({ id, attributes }) => ({
    id,
    ...attributes,
  }));

const AttachedInvoicesSchema = z.object({
  details: z.array(AttachedInvoiceSchema),
  total_tvac: z.coerce.number(),
  total_late_fees: z.coerce.number(),
  paid_amount: z.coerce.number(),
  total_due: z.coerce.number(),
});

const ThirdPartyCaseSchema = z
  .object({
    id: z.coerce.number(),
    attributes: z.object({
      case_type: z.string(),
      started: z.boolean(),
      closed: z.boolean(),
      invoice_reference: z.string(),
      invoice_debtor_name: z.string(),
      created_at: z.string(),
      invoice_id: z.number(),
      last_update: z.string(),
      total_tvac: z.string(),
      total_remaining: z.string(),
      step_collection: z.number(),
      conversation: ThirdPartyCaseConversationSchema.nullable(),
      case_id: z.string(),
      status_description: z.object({
        description: z.string(),
        date: z.string(),
      }),
      tp_owner: z.string().optional(),
      invoice_feedback: InvoiceFeedbackSchema,
      attached_invoices: AttachedInvoicesSchema,
    }),
  })
  .transform(({ id, attributes }) => ({
    id,
    ...attributes,
  }));

export type ThirdPartyCase = z.infer<typeof ThirdPartyCaseSchema>;
export type InvoiceFeedback = z.infer<typeof InvoiceFeedbackSchema>;
export type AttachedInvoices = z.infer<typeof AttachedInvoicesSchema>;
export type AttachedInvoice = z.infer<typeof AttachedInvoiceSchema>;

export async function loadThirdPartyCaseFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get(`/thirdparty_cases/${queryKey[1]}`);

  return ThirdPartyCaseSchema.parse(data);
}

export function useLoadThirdPartyCase(externalMailId: number) {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<ThirdPartyCase, ApiError>({
    queryKey: ['third-party-case', externalMailId],
    queryFn: (context) => loadThirdPartyCaseFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<ThirdPartyCase, ApiError, 'thirdPartyCase'>(
    'thirdPartyCase',
    queryResult,
  );
}
