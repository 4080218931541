import { Reducer, useEffect, useMemo, useReducer, useState } from 'react';
import AnimateNumber from 'animated-number-react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { first, head } from 'lodash-es';
import qs from 'query-string';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import BankAccount from 'shared/components/BankAccount';
import { Icon, IconName } from 'shared/components/Icon';
import { InfiniteScroll } from 'shared/components/InfiniteScroll';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, CustomSelect } from 'shared/io';
import { currencySymbol, formatAmount } from 'shared/utils';
import { reducerState, updateQueryFilters } from 'shared/utils/view';
import { transactionActions } from 'store/transaction/transaction.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { ReconciliationInvoice, ReconciliationPayment } from 'types/store/transaction-state';
import { StoreState } from 'types/storeTypes';

import { ManualReconciliationModal } from './ManualReconciliationModal';
import ReconciliationInvoiceFilter from './ReconciliationInvoiceFilter';
import ReconciliationItem from './ReconciliationItem';
import ReconciliationMobileModal from './ReconciliationMobileModal';
import ReconciliationPaymentFilter from './ReconciliationPaymentFilter';

import styleIdentifiers from './reconciliation.module.scss';

const styles = classNames.bind(styleIdentifiers);

function hasLateFees(invoice?: ReconciliationInvoice): boolean {
  if (invoice == null) return false;

  // ! Caution: remaining_late_fees is incorrectly typed as `number`, it is actually a `string`
  return Number(invoice.remaining_late_fees) !== 0;
}

function shouldTriggerModal(selectedInvoices: Array<ReconciliationInvoice>): boolean {
  if (selectedInvoices.length > 1) return true;
  if (selectedInvoices.length === 1 && hasLateFees(first(selectedInvoices))) return true;
  return false;
}

type ReconciliationState = Reducer<
  {
    filters: any;
    paymentFilterOpen: boolean;
    invoiceFilterOpen: boolean;
    asc?: boolean;
    order?: string;
    selectAll: boolean;
  },
  any
>;

export default function Reconciliation() {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const {
    invoices,
    selectedInvoices,
    selectedPayments,
    payments,
    loading: isLoading,
  } = useSelector((state: StoreState) => state.transaction.reconciliation);

  const location = useLocation();
  const [state, setState] = useReducer<ReconciliationState>(reducerState, {
    filters: { invoice: {}, payment: {} },
    paymentFilterOpen: false,
    invoiceFilterOpen: false,
    selectAll: false,
  });

  const [isInInvoiceTable, setIsInInvoiceTable] = useState(false);

  const paymentForm = useForm({
    shouldUnregister: true,
    defaultValues: state.filters.payment,
  });

  const invoiceForm = useForm({
    shouldUnregister: true,
    defaultValues: state.filters.payment,
  });

  const invoiceData = paymentForm.watch();
  const { bank_account_ids } = invoiceData;

  const selectedPaymentCurrency = head(selectedPayments)?.currency;
  const selectedInvoiceCurrency = head(selectedInvoices)?.currency;
  const selectedCurrency = selectedPaymentCurrency || selectedInvoiceCurrency;

  const loadInvoices = (reset: boolean = false) => {
    transactionActions.getReconciliation({
      noLoading: true,
      reset,
      data: {
        ...state.filters.invoice,
        payment_id:
          reset && selectedInvoices.length === 0 && selectedPayments.length === 1
            ? selectedPayments[0].id
            : undefined,
        page: reset ? 1 : invoices ? invoices.metadata.pagination.current_page + 1 : 1,
        ignore_invoice_ids: reset
          ? undefined
          : invoices?.data
              ?.filter((invoice) => invoice.suggested_invoice)
              .map((invoice) => invoice.id),
      },
    });
  };

  const loadPayments = (reset: boolean = false, newBankAccountIds?) => {
    transactionActions.getReconciliationPayments({
      noLoading: true,
      reset,
      data: {
        reconciliation: true,
        ...state.filters.payment,
        bank_account_ids: newBankAccountIds || bank_account_ids,
        page: reset ? 1 : payments ? payments.metadata.pagination.current_page + 1 : 1,
      },
    });
  };

  const updateFilters = (type: 'invoice' | 'payment') => (editFilters) => {
    const newFilters = { ...state.filters };
    if (type === 'payment') {
      newFilters[type] = { ...editFilters };
    } else {
      newFilters[type] = editFilters;
    }

    setState({ filters: newFilters });

    const queryFilters = {};

    for (const key in newFilters.invoice) {
      queryFilters[`invoice_${key}`] = newFilters.invoice[key] || undefined;
    }
    for (const key in newFilters.payment) {
      queryFilters[`payment_${key}`] = newFilters.payment[key] || undefined;
    }
    updateQueryFilters(queryFilters);
  };

  useEffect(() => {
    loadInvoices(true);
  }, [state.filters.invoice]);

  useEffect(() => {
    loadPayments(true);
  }, [state.filters.payment]);

  useEffect(() => {
    paymentForm.register('start_date');
    paymentForm.register('end_date');

    const filters = qs.parse(location.search);

    const newFilters = {
      invoice: {},
      payment: {},
    };

    for (const key in filters) {
      if (key.indexOf('invoice_') !== -1) {
        newFilters.invoice[key.substring(8)] = filters[key];
      } else if (key.indexOf('payment_') !== -1) {
        newFilters.payment[key.substring(8)] = filters[key];
      }
    }
    for (const key in newFilters.payment) {
      paymentForm.setValue(key, newFilters.payment[key]);
    }

    setState({
      filters: newFilters,
    });

    return () => {
      paymentForm.unregister('start_date');
      paymentForm.unregister('end_date');
    };
  }, []);

  useEffect(() => {
    if (selectedPayments.length <= 2 && selectedInvoices.length === 0) {
      loadInvoices(true);
    }
  }, [selectedInvoices.length, selectedPayments.length]);

  const selectItem = (item: ReconciliationPayment | ReconciliationInvoice) => {
    transactionActions.selectReconciliation(item);
  };

  const changeOrder = (field: string, type: 'payment' | 'invoice') => {
    updateFilters(type)({
      ...state.filters[type],
      sort_by: field,
      sort_order:
        state.filters[type] &&
        state.filters[type].sort_by === field &&
        state.filters[type].sort_order === 'asc'
          ? 'desc'
          : 'asc',
    });
  };

  const ignorePayments = () => {
    const query = state.selectAll ? { ...state.filters.payment } : {};
    transactionActions.ignorePayments({
      data: {
        payment_ids: selectedPayments.map((payment) => payment.id),
        reconciliation: true,
        ...query,
      },
      callback: () => loadPayments(true),
    });
  };

  const handleReconciliation = () => {
    if (shouldTriggerModal(selectedInvoices)) {
      showDialog({
        id: DialogShowId.CUSTOM,
        title: t(i18nKeys.FORM.PREFERENCES.RECONCILIATION),
        children: (
          <ManualReconciliationModal
            invoices={selectedInvoices}
            payments={selectedPayments}
            onSuccess={() => {
              transactionActions.resetReconciliationSelection();
              dialogHide(DialogShowId.CUSTOM);
              loadInvoices(true);
              loadPayments(true);
            }}
          />
        ),
      });
    } else {
      transactionActions.manualReconciliation({
        data: {
          invoices: selectedInvoices.map((arg) => ({
            id: arg.id,
            amount: Number(arg.remaining_balance),
          })),
          payment_ids: selectedPayments.map((payment) => payment.id),
        },
        callback: () => {
          dialogHide(DialogShowId.CONFIRM);
          loadInvoices(true);
          loadPayments(true);
        },
      });
    }
  };

  const resetPaymentForm = () => {
    paymentForm.reset({
      start_date: '',
      end_date: '',
      reconciliation_detail: '',
      operator: '',
      amount: '',
    });
  };

  const resetInvoicesForm = () => {
    invoiceForm.setValue('issue_date_before', '');
    invoiceForm.setValue('issue_date_after', '');
    invoiceForm.setValue('reconciliation_detail', '');
    invoiceForm.setValue('remaining_balance', '');
    invoiceForm.setValue('remaining_balance_operator', '');
  };

  const resetFilters = (type: 'payment' | 'invoice') => () => {
    if (type === 'invoice') resetInvoicesForm();
    else resetPaymentForm();

    updateFilters(type)({});
  };

  const selectAll = () => {
    setState({ ...state, selectAll: true });
    transactionActions.resetReconciliationSelection();
  };

  const unselectAll = () => {
    setState({ ...state, selectAll: false });
  };

  const openMobileReconciliation = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.FULL,
      title: t(i18nKeys.NAV.RECONCILIATION),
      children: (
        <ReconciliationMobileModal
          selectedInvoices={selectedInvoices}
          selectedPayments={selectedPayments}
          submit={handleReconciliation}
        />
      ),
    });
  };

  const renderHeadItem = (
    label: string,
    className: string,
    field: string,
    type: 'payment' | 'invoice',
  ) => (
    <th className={styles('sortable-head', className)} onClick={() => changeOrder(field, type)}>
      <span>{label}</span>
      {state.filters[type] && state.filters[type].sort_by === field && (
        <Icon
          name={IconName.TAILDOWN}
          className={styles('arrow', state.filters[type].sort_order === 'desc' && 'inverted')}
          size="15px"
        />
      )}
    </th>
  );

  const bankAccount = ({ item }) => (
    <div className={styles('bank-account', bank_account_ids?.indexOf(item.id) > -1 && 'selected')}>
      <BankAccount value={item.iban} small image={item.bic} />
      {bank_account_ids?.indexOf(item.id) > -1 && (
        <Icon name={IconName.CHECK} className={styles('check-icon')} size="20px" />
      )}
    </div>
  );

  let invoiceFilterActiveNumber = 0;
  let paymentFilterActiveNumber = 0;
  const invoiceValues = state.filters.invoice;
  if (invoiceValues.issue_date_after || invoiceValues.issue_date_before) {
    invoiceFilterActiveNumber++;
  }
  if (invoiceValues.reconciliation_detail) {
    invoiceFilterActiveNumber++;
  }
  if (invoiceValues.remaining_balance) {
    invoiceFilterActiveNumber++;
  }

  const paymentValues = paymentForm.getValues();

  if (paymentValues.start_date || paymentValues.end_date) {
    paymentFilterActiveNumber++;
  }
  if (paymentValues.reconciliation_detail) {
    paymentFilterActiveNumber++;
  }
  if (paymentValues.amount) {
    paymentFilterActiveNumber++;
  }

  const paymentsNumber = payments?.data?.reduce(
    (acc, _payments) => (_payments.inactive ? acc : acc + 1),
    0,
  );

  const invoiceNumber = invoices?.data?.reduce(
    (acc, invoice) => (invoice.inactive ? acc : acc + 1),
    0,
  );

  const totalSelectedInvoices = useMemo(
    () =>
      selectedInvoices.reduce(
        (acc, invoice) => acc + +invoice.localized_money_object.remaining_balance_with_fees,
        0,
      ),
    [selectedInvoices.length],
  );

  const totalSelectedPayments = useMemo(
    () =>
      selectedPayments.reduce(
        (acc, payment) => acc + +payment.localized_money_object.remaining_balance,
        0,
      ),
    [selectedPayments.length],
  );

  return (
    <div className={styles('Reconciliation', isInInvoiceTable ? 'invoices' : 'payments')}>
      <div className={styles('head')}>
        <div className={styles('title')}>
          <h1>{t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.MANUAL_RECONCILIATION)}</h1>
        </div>
        <div>
          <Button
            className={styles('variable')}
            label={t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.IGNORE_PAYMENTS)}
            noMargin
            color={ButtonColor.GREY}
            iconLeft={IconName.TRASH_SIMPLE}
            disabled={
              !state.selectAll && (selectedPayments.length === 0 || selectedInvoices.length > 0)
            }
            onClick={ignorePayments}
          />
          <Button
            className={styles('variable', 'reconciliation-button')}
            label={t(i18nKeys.RECONCILE)}
            noMargin
            color={ButtonColor.BLUE}
            iconRight={IconName.MINIMAL_RIGHT}
            isLoading={isLoading}
            disabled={!(selectedInvoices.length > 0 && selectedPayments.length > 0)}
            onClick={handleReconciliation}
          />
          <Button
            className={styles('variable', 'choosed-payments-button')}
            label={t(i18nKeys.INVOICING.SEE_INVOICES)}
            noMargin
            color={ButtonColor.BLUE}
            iconRight={IconName.MINIMAL_RIGHT}
            onClick={() => setIsInInvoiceTable(true)}
          />
          <Button
            className={styles('variable', 'back-button')}
            label={t(i18nKeys.BACK)}
            noMargin
            color={ButtonColor.GREY}
            iconLeft={IconName.MINIMAL_LEFT}
            onClick={() => setIsInInvoiceTable(false)}
          />
          <Button
            className={styles('variable', 'mobile-reconciliation-button')}
            label="Voir réconciliation"
            noMargin
            color={ButtonColor.GREEN}
            disabled={!(selectedInvoices.length > 0 && selectedPayments.length > 0)}
            onClick={openMobileReconciliation}
          />
        </div>
      </div>
      <div className={styles('tables-wrapper')}>
        <div className={styles('table-content', 'card')}>
          <div className={styles('header')}>
            <FormProvider {...paymentForm}>
              <Controller
                defaultValue=""
                name="bank_account_ids"
                render={() => (
                  <CustomSelect
                    selectClassName={styles('bank-account-select')}
                    valueClassName={styles('bank-account-select-value')}
                    itemsClassName={styles('list-bank-account')}
                    removeAll={t('BANK_ACCOUNT.RECONCILIATION.ALL_ENTRY_PAYMENT')}
                    onValueChanged={(value) => {
                      loadPayments(true, value);
                      return value;
                    }}
                    noMargin
                    noBorder
                    multiple
                    noTags
                    valueRendering={(item) =>
                      item &&
                      (item.value.length === 0
                        ? t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.ALL_ENTRY_PAYMENT)
                        : item.value.length > 1
                          ? t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.SEVERAL_ACCOUNT_SELECTED)
                          : bankAccount(item.value[0]))
                    }
                    itemRendering={bankAccount}
                    name="bank_account_ids"
                    keyText="iban"
                    keyValue="id"
                    items={company.bank_accounts_attributes || []}
                  />
                )}
              />
            </FormProvider>
            <div className={styles('filter-container')}>
              {paymentFilterActiveNumber > 0 && (
                <Button
                  onClick={resetFilters('payment')}
                  color={ButtonColor.WHITE}
                  small
                  noMargin
                  className={styles('filter-button')}
                >
                  <Icon name={IconName.FILTER} />
                  <Icon name={IconName.SMALL_REMOVE} />
                </Button>
              )}

              <Button
                onClick={() => {
                  setState({ paymentFilterOpen: !state.paymentFilterOpen });
                }}
                color={ButtonColor.WHITE}
                iconLeft={IconName.FILTER}
                className={styles('filter-button')}
                small
                noMargin
              >
                {paymentFilterActiveNumber > 0 && (
                  <span className={styles('text-circle', 'absolute')}>
                    {paymentFilterActiveNumber}
                  </span>
                )}

                {t(i18nKeys.FILTER)}
              </Button>
            </div>
          </div>
          <div className={styles('table-filter')}>
            <FormProvider {...paymentForm}>
              {state.paymentFilterOpen ? (
                <ReconciliationPaymentFilter onSubmit={updateFilters('payment')} />
              ) : null}
            </FormProvider>
          </div>

          {payments?.data?.length > 0 ? (
            <InfiniteScroll
              className={styles('table-wrapper')}
              load={loadPayments}
              hasMore={
                payments.metadata.pagination.last_page !== payments.metadata.pagination.current_page
              }
              items={payments.data}
              loading={payments.loading}
            >
              <table>
                <thead>
                  <tr>
                    {renderHeadItem(t(i18nKeys.DATE), 'date', 'value_date', 'payment')}
                    {renderHeadItem(t(i18nKeys.DETAILS), 'details', 'counterparty_name', 'payment')}
                    {renderHeadItem(t(i18nKeys.AMOUNT), 'amount', 'remaining_balance', 'payment')}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {(selectedPayments.length > 0 || state.selectAll) && (
                      <td colSpan={3} className={styles('select-all')}>
                        {state.selectAll &&
                          selectedPayments.length <= 0 &&
                          t(
                            payments.metadata.pagination.total_objects === 1
                              ? i18nKeys.SELECTED_ELEMENT
                              : i18nKeys.SELECTED_ELEMENTS,
                            {
                              count: payments.metadata.pagination.total_objects,
                            },
                          )}

                        <span onClick={state.selectAll ? unselectAll : selectAll}>
                          {t(
                            state.selectAll ? i18nKeys.FORM.DESELECT_ALL : i18nKeys.FORM.SELECT_ALL,
                          )}
                        </span>
                      </td>
                    )}
                  </tr>
                  {payments.data.map((payment) => (
                    <ReconciliationItem
                      overrideSelection={state.selectAll}
                      disabled={selectedCurrency != null && selectedCurrency !== payment.currency}
                      item={payment}
                      key={payment.id}
                      action={selectItem}
                    />
                  ))}
                </tbody>
              </table>
            </InfiniteScroll>
          ) : (
            <div className={styles('no-result')}>{t(i18nKeys.NO_RESULT)}</div>
          )}
          <div className={styles('footer')}>
            {payments?.loaded && payments?.data?.length > 0 && (
              <div>
                1 -{' '}
                {paymentsNumber >= payments.metadata.pagination.total_objects
                  ? paymentsNumber
                  : payments.metadata.pagination.total_objects}{' '}
                {t(i18nKeys.OF)} {payments.metadata.pagination.total_objects}
              </div>
            )}
            {totalSelectedPayments > 0 && (
              <span>
                <AnimateNumber
                  duration={400}
                  value={totalSelectedPayments}
                  formatValue={(value) =>
                    `${formatAmount(value, ',', '.', 2, currencySymbol(undefined, company))}`
                  }
                />
              </span>
            )}
          </div>
        </div>
        <div className={styles('table-content', 'card')}>
          <div className={styles('header')}>
            <h3>{t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.UNPAID_INVOICES)}</h3>
            <div className={styles('filter-container')}>
              {invoiceFilterActiveNumber > 0 && (
                <Button
                  onClick={resetFilters('invoice')}
                  color={ButtonColor.WHITE}
                  small
                  noMargin
                  className={styles('filter-button')}
                >
                  <Icon name={IconName.FILTER} />
                  <Icon name={IconName.SMALL_REMOVE} />
                </Button>
              )}
              <Button
                onClick={() => {
                  setState({ invoiceFilterOpen: !state.invoiceFilterOpen });
                }}
                color={ButtonColor.WHITE}
                iconLeft={IconName.FILTER}
                className={styles('filter-button')}
                small
                noMargin
              >
                {invoiceFilterActiveNumber > 0 && (
                  <span className={styles('text-circle', 'absolute')}>
                    {invoiceFilterActiveNumber}
                  </span>
                )}
                {t(i18nKeys.FILTER)}
              </Button>
            </div>
          </div>
          <div className={styles('table-filter')}>
            <FormProvider {...invoiceForm}>
              {state.invoiceFilterOpen ? (
                <ReconciliationInvoiceFilter onSubmit={updateFilters('invoice')} />
              ) : null}
            </FormProvider>
          </div>

          {invoices?.data?.length > 0 ? (
            <InfiniteScroll
              className={styles('table-wrapper')}
              load={loadInvoices}
              hasMore={
                invoices.metadata.pagination.last_page !== invoices.metadata.pagination.current_page
              }
              items={invoices.data}
              loading={invoices.loading}
            >
              <table>
                <thead>
                  <tr>
                    {renderHeadItem(t(i18nKeys.DATE), 'date', 'issue_date', 'invoice')}
                    {renderHeadItem(t(i18nKeys.DETAILS), 'details', 'debtor_name', 'invoice')}
                    {renderHeadItem(t(i18nKeys.BALANCE), 'amount', 'remaining_balance', 'invoice')}
                  </tr>
                </thead>
                <tbody>
                  {invoices.data.map((invoice) => (
                    <ReconciliationItem
                      item={invoice}
                      disabled={selectedCurrency != null && selectedCurrency !== invoice.currency}
                      key={invoice.id}
                      action={selectItem}
                    />
                  ))}
                </tbody>
              </table>
            </InfiniteScroll>
          ) : (
            <div className={styles('no-result')}>{t(i18nKeys.NO_RESULT)}</div>
          )}
          <div className={styles('footer')}>
            {totalSelectedInvoices > 0 && (
              <span>
                {
                  <AnimateNumber
                    duration={400}
                    value={totalSelectedInvoices}
                    formatValue={(value) =>
                      `${formatAmount(value, ',', '.', 2, currencySymbol(undefined, company))}`
                    }
                  />
                }{' '}
                <span className={styles('balance')}>
                  {totalSelectedInvoices - totalSelectedPayments > 0 && '+'}
                  {formatAmount(
                    totalSelectedInvoices - totalSelectedPayments,
                    ',',
                    '.',
                    2,
                    currencySymbol(undefined, company),
                  )}
                </span>
              </span>
            )}
            {invoices?.loaded && invoices?.data?.length > 0 && (
              <div>
                1 - {invoiceNumber} {t(i18nKeys.OF)}{' '}
                {invoiceNumber > invoices.metadata.pagination.total_objects
                  ? invoiceNumber
                  : invoices.metadata.pagination.total_objects}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
