import { useEffect } from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import { DebtorBalanceInvoice } from 'shared/hooks';
import { formattedDate } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { ActionIcon, Divider, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconExternalLink, IconMinus, IconPlus } from '@tabler/icons-react';

import { InvoiceLabel } from './Labels';

interface InvoiceRowProps {
  invoice: DebtorBalanceInvoice;
  isForceUnfoldActive: boolean;
}

export const InvoiceRow = ({ invoice, isForceUnfoldActive }: InvoiceRowProps) => {
  const { t, currentLang } = useTranslation();
  const [isUnfolded, fold] = useDisclosure(false);

  useEffect(() => {
    if (isForceUnfoldActive) fold.open();
    else fold.close();
  }, [isForceUnfoldActive]);

  const {
    id,
    reference,
    status,
    issueDate,
    dueDate,
    totalTvac,
    lateFees,
    remainingBalance,
    remainingLateFees,
    creditNotes,
    matchedPayments,
  } = invoice;

  const Icon = isUnfolded ? IconMinus : IconPlus;

  const openInvoice = () => {
    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: <ToHandleDetail id={id} modal />,
    });
  };

  const totalWithFees = totalTvac + lateFees;

  const remainingBalanceWithFees = remainingBalance + remainingLateFees;

  return (
    <>
      <tr>
        <td>
          <InvoiceLabel />
        </td>
        <td onClick={fold.toggle} style={{ cursor: 'pointer' }}>
          <Group gap={0}>
            <ActionIcon variant="transparent" size={20}>
              <Icon color="black" stroke={1.5} />
            </ActionIcon>
            <Text ml="xs" fz="0.9rem" ta="left" flex={1}>
              {reference}
            </Text>
            <ActionIcon
              variant="transparent"
              size={20}
              onClick={(e) => {
                e.stopPropagation();
                openInvoice();
              }}
              color="blue"
            >
              <IconExternalLink stroke={1.5} />
            </ActionIcon>
          </Group>
        </td>
        <td>
          <Text fz="0.9rem">{status || '-'}</Text>
        </td>
        <td style={{ minWidth: '100px' }}>
          <Text fz="0.9rem"> {issueDate ? issueDate.format('DD/MM/YY') : '-'}</Text>
        </td>
        <td style={{ minWidth: '100px' }}>
          <Text fz="0.9rem">{dueDate ? formattedDate(dueDate, currentLang, 'DD/MM/YY') : '-'}</Text>
        </td>
        <td>
          <Text fz="0.9rem" c="blue">
            <Amount value={totalTvac + lateFees} />
          </Text>
        </td>
        <td>
          <Text fz="0.9rem" c="red">
            {/* Alternatively, maybe  it should actually be the sum of all the payments */}
            {totalWithFees - remainingBalanceWithFees > 0 && (
              <Amount value={totalWithFees - remainingBalanceWithFees} />
            )}
          </Text>
        </td>
        <td>
          <Text fz="0.9rem">
            <Amount value={remainingBalanceWithFees} />
          </Text>
        </td>
      </tr>
      {isUnfolded && (
        <>
          <tr style={{ border: 'none' }}>
            <td />
            <td>
              <Text ml={20} fz="0.9rem">
                {t(i18nKeys.AMOUNT_BILLED)}
              </Text>
            </td>
            <td colSpan={3} />
            <td>
              <Text fz="0.9rem" c="blue">
                <Amount value={totalTvac} />
              </Text>
            </td>
          </tr>
          {lateFees !== 0 && (
            <tr style={{ border: 'none' }}>
              <td />
              <td>
                <Text ml={20} fz="0.9rem">
                  {t(i18nKeys.LATE_FEES)}
                </Text>
              </td>
              <td colSpan={3} />
              <td>
                <Text fz="0.9rem" c="blue">
                  <Amount value={lateFees} />
                </Text>
              </td>
            </tr>
          )}
          {creditNotes.map((creditNote) => (
            <tr key={creditNote.reference} style={{ border: 'none' }}>
              <td />
              <td>
                <Text ml={20} fz="0.9rem">
                  {t(i18nKeys.CREDIT_NOTE_LINKED)} - {creditNote.reference}
                </Text>
              </td>
              <td colSpan={1} />
              <td>
                <Text fz="0.9rem">
                  {creditNote.issueDate?.format('DD/MM/YY') ?? t(i18nKeys.MISSING_DATA)}
                </Text>
              </td>
              <td colSpan={2} />
              <td>
                <Text fz="0.9rem" c="red">
                  <Amount value={creditNote.amount} />
                </Text>
              </td>
            </tr>
          ))}
          {matchedPayments.map((payment) => (
            <tr key={payment.amount} style={{ border: 'none' }}>
              <td />
              <td>
                <Text ml={20} fz="0.9rem">
                  {t(i18nKeys.LINKED_PAYMENT)} - {payment.communication}
                </Text>
              </td>
              <td colSpan={1} />
              <td>
                <Text fz="0.9rem">
                  {payment.issueDate?.format('DD/MM/YY') ?? t(i18nKeys.MISSING_DATA)}
                </Text>
              </td>
              <td colSpan={2} />
              <td>
                <Text fz="0.9rem" c="red">
                  <Amount value={payment.amount} />
                </Text>
              </td>
            </tr>
          ))}
        </>
      )}
      <tr>
        <td colSpan={8} style={{ padding: '0' }}>
          <Divider p={0} />
        </td>
      </tr>
    </>
  );
};
