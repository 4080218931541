import { useMemo, useState } from 'react';
import { TasksListItemStatus } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { NumericFormat } from 'react-number-format';
import { Document, Page } from 'react-pdf';
import Amount from 'shared/components/Amount';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import StatusItem from 'shared/components/StatusItem';
import Tooltip from 'shared/components/Tooltip';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { formatCommunication } from 'shared/utils';
import { formattedDate, reloadCustomView } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';
import { TableColumn } from 'types/views';

import ReminderPreview from './ReminderPreview';

import styleIdentifiers from './TableItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  columns: TableColumn[];
  item: any;
  noCheckbox?: boolean;
  onClickCheckbox?: Function;
  onClickRow: Function;
  actionsCol?: Function;
  groupedInvoices?: string[];
  isRemindersView?: boolean;
  isSelected: boolean;
};

export default function TableItem({
  columns,
  item,
  noCheckbox,
  onClickCheckbox,
  onClickRow,
  actionsCol,
  groupedInvoices,
  isRemindersView,
  isSelected,
}: Props) {
  const { t, currentLang } = useTranslation();
  const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false);
  item = item.attributes
    ? {
        id: item.id,
        type: item.type,
        ...item.attributes,
      }
    : item;

  const columnParser = useMemo(
    () => ({
      date: (value) => formattedDate(value, currentLang),
      boolean: (value) => (value ? t(i18nKeys.YES) : t(i18nKeys.NO)),
      money: (value) => (
        <NumericFormat
          value={value}
          displayType="text"
          suffix="€"
          decimalSeparator="."
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator=" "
        />
      ),
      percentage: (value) => (
        <NumericFormat value={value} suffix="%" decimalScale={2} displayType="text" />
      ),
      days: (value) => t(i18nKeys.DAY_S, { count: value }),
      structured_communication: (value) => formatCommunication(value),
    }),
    [],
  );

  const editClient = (item) => (e) => {
    e.stopPropagation();

    editClient({
      client: {
        id: item.debtor_id || item.id,
      },
      callback: () => {
        reloadCustomView(clientActions.listPageRes);
      },
    });
  };

  const parser = (item, column: TableColumn) => {
    const value =
      item.debtor_custom_variables?.[column.name.replace('debtor__', '')] ||
      item.custom_variables?.[column.name] ||
      item[column.name];

    if (columnParser[column.format] && column.name !== 'next_step_date' && value !== null) {
      return columnParser[column.format](value);
    }
    return value;
  };

  const renderColumn = (column, item) => {
    if (column.name === 'status') {
      return (
        <td key={column.name}>
          {column.cleaned_view === 'tasks' ? (
            <TasksListItemStatus status={item.status} statusKey={item.status_key} />
          ) : (
            <StatusItem status={item.status} step={item.step_collection} />
          )}
        </td>
      );
    }

    if (
      [
        'total_ca',
        'annual_ca',
        'total_tvac',
        'total_htva',
        'late_fees',
        'remaining_late_fees',
        'remaining_balance',
        'remaining_balance_with_fees',
        'remaining_balance_without_late_fees',
        'total_discount_amount',
        'overdue_amount',
        'interest_amount',
        'credit_notes_total',
        'payments_sum',
        'penalty_clause_amount',
      ].includes(column.name)
    ) {
      const value = item?.localized_money_object?.[column.name] || item?.[column.name];
      if (value == null) return <td key={column.name}>-</td>;

      return (
        <td key={column.name}>
          <Amount localizedValue={item?.[column.name]} value={value} suffix={item.currency} />
        </td>
      );
    }

    return (
      <td
        className={styles(
          column.column_type === 'actions' && 'actions',
          column.column_type !== 'actions' &&
            parser(item, column) === t(i18nKeys.INVOICING.IMPENDING_REMINDERS.WAITING_VALIDATION) &&
            'red',
        )}
        key={column.name}
      >
        {column.column_type === 'actions' ? actionsCol!(item) : parser(item, column) || '-'}
      </td>
    );
  };

  const handlePdfView = () => {
    invoiceActions.document({
      id: item.id,
      type: 'invoice_pdf',
      callback: (data) => {
        const url = URL.createObjectURL(data);
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.LARGE,
          title: item.reference,
          children: (
            <Document file={url}>
              <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                className={styles('pdf-pages')}
                width={1100}
                pageNumber={1}
              />
            </Document>
          ),
        });
      },
    });
  };

  const handleReminderPreview = () => {
    setIsPreviewLoading(true);
    invoiceActions.previewTemplate({
      id: item.id,
      noLoading: true,
      data: {
        document_type: item.preview_template.step_type,
        template_id: item.preview_template.id,
        manual_reminder: false,
      },
      callback: (data) => {
        setIsPreviewLoading(false);
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.LARGE,
          title: item.recovery_step_id,
          children: <ReminderPreview email={data.email} pdf={data.pdf} />,
        });
      },
    });
  };

  return (
    <tr className={styles('table-item')} onClick={onClickRow as OnClickFunction}>
      <td
        className={styles(
          noCheckbox && 'small',
          'status',
          item.step_collection ? `status-${item.step_collection + 1}` : 'status-0',
        )}
      >
        <div className={styles('checkbox-wrapper')}>
          {!noCheckbox && (
            <div
              className={styles('checkbox', isSelected && 'checked')}
              onClick={onClickCheckbox as OnClickFunction}
            />
          )}
          {isRemindersView && (
            <Icon
              name={IconName[isPreviewLoading ? 'SYNC' : 'EYE']}
              rotate={isPreviewLoading}
              color={IconColor[isPreviewLoading ? 'BLUE' : 'DARK']}
              size="18px"
              title={t(i18nKeys.SHOW_TEMPLATE)}
              onClick={(e) => {
                e.stopPropagation();
                if (!isPreviewLoading) {
                  handleReminderPreview();
                }
              }}
            />
          )}
          {(item.address_missing || item.email_missing) && (
            <Tooltip
              className={styles('action-drop')}
              contentClass={styles('warning')}
              item={
                <div className={styles('circle')} onClick={editClient(item)}>
                  !
                </div>
              }
            >
              <ul>
                {item.address_missing && <li>{t(i18nKeys.INCOMPLETE_ADRESS)}</li>}
                {item.email_missing && <li> {t(i18nKeys.MISSING_EMAIL)}</li>}
              </ul>
            </Tooltip>
          )}
          {item.has_pdf && (
            <Icon
              name={IconName.PDF}
              color={IconColor.DARK}
              size="18px"
              title={t(i18nKeys.INVOICE.VIEW_PDF)}
              onClick={(e) => {
                e.stopPropagation();
                handlePdfView();
              }}
            />
          )}
          {groupedInvoices?.length! > 1 && (
            <Tooltip
              item={
                <div className={styles('grouped-invoices-count')}>{groupedInvoices?.length}</div>
              }
              size="14px"
              text={t(i18nKeys.TOOLTIP.IMMINENT_REMINDER_INVOICES, {
                references: groupedInvoices!.join(' '),
              })}
              direction={DropdownDirection.TOP}
              oneLine
            />
          )}
          {(item.off_balance_since ?? item.debtor_off_balance_since) && (
            <Tooltip
              className={styles('action-drop')}
              contentClass={styles('warning')}
              item={
                <div className={styles('circle')} onClick={editClient(item)}>
                  !
                </div>
              }
            >
              {t(i18nKeys.OFF_BALANCE, {
                since: formattedDate(
                  item.off_balance_since ?? item.debtor_off_balance_since,
                  currentLang,
                ),
              })}
            </Tooltip>
          )}
          {item.paused && (
            <Icon
              name={IconName.PAUSE}
              color={IconColor.DARK}
              size="18px"
              title={t(i18nKeys.CLIENT.FILTER.PAUSED)}
            />
          )}
          {item.has_payment_plan && (
            <Icon
              name={IconName.PAYMENT_BOOK}
              color={IconColor.DARK}
              size="18px"
              title={t(i18nKeys.ONGOING_PAYMENT_PLAN)}
            />
          )}
          {item.disputed && (
            <Icon
              name={IconName.ALERT_CIRCLE}
              color={IconColor.RED}
              size="18px"
              title={t(i18nKeys.FOLLOW_UP.SUMMARY.CONFLICT)}
            />
          )}
        </div>
      </td>
      {columns.map((column) => renderColumn(column, item))}
      {/* Extra td to fill the last column used by the settings icon in the header */}
      <td />
    </tr>
  );
}
