import Payment from 'payment';

import { extractNumber } from '.';

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = extractNumber(value);

  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        15,
      )}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        14,
      )}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12,
      )} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatBankAccount(value) {
  if (!value) return '';

  const clearValue = value.replace(/\s+/g, '').toUpperCase();

  let output = `${clearValue.slice(0, 4)}`;

  if (clearValue.length > 4) {
    output += ` ${clearValue.slice(4, 8)}`;
  }
  if (clearValue.length > 8) {
    output += ` ${clearValue.slice(8, 12)}`;
  }
  if (clearValue.length > 12) {
    output += ` ${clearValue.slice(12, 16)}`;
  }
  if (clearValue.length > 16) {
    output += ` ${clearValue.slice(16)}`;
  }

  return output;
}
