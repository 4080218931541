import { LightUser } from 'shared/hooks';
import { Id } from 'types';
import { DescVal } from 'types/storeTypes';

export * from './charts';
export * from './currency';
export * from './date';
export * from './environment';
export * from './feature-flags';
export * from './form';
export * from './identities';
export * from './mask.utils';
export * from './payment';
export * from './query';
export * from './unimplemented';

export function usersToDescVal(users: Array<LightUser>): Array<DescVal<Id>> {
  return users.map(({ id, name }) => ({ description: name.full, value: id }));
}

export function descValsToSelectOptions(descVals: Array<DescVal>) {
  return descVals.map(({ description, value }) => ({ label: description, value }));
}

export function extractNumber(value = '') {
  return value.replace(/\D+/g, '');
}
