import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import { CustomPreferencesFieldsName, PreferencesForm } from 'shared/forms/PreferencesForm';
import {
  CGV,
  CompanyEdit,
  CompanyTermsAndConditionsEdit,
  SalePreferencesFieldsName,
} from 'shared/forms/PreferencesForm/PreferencesForm.constants';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';

import { Stack } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';

import { SendingSettings } from './SendingSettings';
import { TaskSettings } from './TaskSettings';

export const PreferenceSettings = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { company } = useLoadCompanyConfiguration();

  const defaultValues = { ...company };

  const handleFilesUpdate = (files: CompanyTermsAndConditionsEdit[], res: any) => {
    const termsAndConditionsAttributes = res.data.attributes.terms_and_conditions.map((item) => ({
      ...item,
      ...item.attributes,
    }));

    files.forEach((file) => {
      accountActions.uploadTerms({
        ...file,
        ...termsAndConditionsAttributes.find((e) => e.id === file.id || e.name === file.name),
      });
    });
  };

  const handleUpdate = (values: CompanyEdit) => {
    const updatedCompany = {
      ...defaultValues,
      ...omit(values, [CustomPreferencesFieldsName.SEND_COPY_EMAILS_INPUT]),
      [CGV]: values[CGV].map((e) => omit(e, SalePreferencesFieldsName.PDF_BLOB)),
    };

    accountActions.updateCompany({
      ...updatedCompany,
      callback: (res) => {
        handleFilesUpdate(
          values[CGV].filter((e) => e[SalePreferencesFieldsName.PDF_BLOB]),
          res,
        );
        queryClient.invalidateQueries({ queryKey: ['company-configuration'] });
      },
    });
  };

  return (
    <>
      <PageTitle>{t(i18nKeys.SETTINGS.PREFERENCES.TITLE)}</PageTitle>
      <Stack>
        <PreferencesForm onSubmit={handleUpdate} />
        <SendingSettings onSubmit={handleUpdate} />
        {company.package.can_use_tasks && <TaskSettings />}
      </Stack>
    </>
  );
};
