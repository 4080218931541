import { AxiosInstance } from 'axios';
import { CurrencyCodeSchema } from 'types/currency';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useLegacyAxiosInstance } from './utils';

export const ExternalMailAttributesSchema = z.object({
  date: z.coerce.date(),
  subject: z
    .string()
    .nullable()
    .transform((value) => value ?? ''),
  from_mail: z
    .string()
    .nullable()
    .transform((value) => value ?? ''),
  to_mail: z.array(z.string().email()),
  cc: z.array(z.string().email()).nullable(),
  bcc: z.array(z.string().email()).nullable(),
  read: z.boolean(),
  status: z.enum(['processed', 'unprocessed']),
  body: z.string().nullable(),
  from_name: z.string().nullable(),
  has_attachments: z.boolean(),
  reply_mails: z.any(),
  attachments: z.array(
    z.object({
      filename: z.string(),
      url: z.string().url(),
    }),
  ),
  debtor: z
    .object({
      id: z.string(),
      attributes: z.object({
        currency: CurrencyCodeSchema,
        emails: z.array(z.string().email()),
        full_name: z.string(),
      }),
    })
    .nullable(),
  reply_count: z.number(),
  account_manager: z
    .object({
      id: z.string(),
      attributes: z.object({
        first_name: z.string(),
        last_name: z.string(),
        email: z.string().email(),
      }),
    })
    .nullable(),
});

const ExternalMailBase = z.object({
  data: z.object({
    id: z.string(),
    attributes: ExternalMailAttributesSchema,
  }),
});

export const ExternalMailSchema = ExternalMailBase.transform(
  (data: z.infer<typeof ExternalMailBase>) => data.data,
);

export type ExternalMail = z.infer<typeof ExternalMailSchema>;

export async function loadExternalMailFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get(`/external_mails/${queryKey[1]}`);

  return ExternalMailSchema.parse(data);
}

export function useLoadExternalMail(externalMailId: string) {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<ExternalMail, ApiError>({
    queryKey: ['externalMail', externalMailId],
    queryFn: (context) => loadExternalMailFn(axiosInstance, context),
    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<ExternalMail, ApiError, 'externalMail'>(
    'externalMail',
    queryResult,
  );
}
