import {
  useCreateTaskComment,
  useDeclinePaymentPlanRequest,
  useGetActivities,
  useGetTask,
  useUpdateTask,
  useUpdateTasksStatus,
} from 'api';
import {
  CreateTaskBody,
  CreateTaskCommentBody,
  TaskActionType,
  UpdateTasksStatusBody,
} from 'api/models';
import { Comments } from 'app/Private/Comments';
import InvoiceTimeline from 'app/Private/Invoices/ToHandleDetail/InvoiceTimeline/InvoiceTimeline';
import AttachedInvoicesList from 'app/Private/ThirdpartyCases/AttachedInvoices';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import Tooltip from 'shared/components/Tooltip';
import { AttachedInvoice, AttachedInvoices } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { formatActivities } from 'shared/utils/activities.utils';
import { dialogHide, DialogShowId, sideMenuHide, sideMenuShow } from 'store/view/view.actions';
import { CurrencyLocalizedMoneyObject } from 'types/currency';
import { StoreState } from 'types/storeTypes';

import { getAssigneeFullName, getTaskTypeDescription } from '../Task.utils';
import { TaskActions } from '../TaskActions/TaskActions';
import { TaskForm } from '../TaskForm/TaskForm';
import { TasksListItemStatus } from '../TasksList/TasksListItemStatus';

import styleIdentifiers from './TaskDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const TaskDetail = () => {
  const { t, currentLang } = useTranslation();
  const history = useHistory();

  const { task_types } = useSelector((state: StoreState) => state.app.constants);

  const { id: taskId } = useParams<{ id: string }>();

  const { data: taskResponse, isLoading, isError, refetch } = useGetTask(taskId);

  const { mutate: updateTaskMutation } = useUpdateTask();
  const { mutate: updateTasksStatus } = useUpdateTasksStatus();

  const { mutate: createTaskComment } = useCreateTaskComment();
  const { mutate: declinePaymentPlanRequest } = useDeclinePaymentPlanRequest();

  const {
    data: activitiesListResponse,
    refetch: refetchActivities,
    isLoading: isActivitiesLoading,
    isError: isActivitiesError,
  } = useGetActivities({
    task_id: taskId,
  });

  if (isLoading && !taskResponse && isActivitiesLoading) {
    return <RecovrLogoLoader />;
  }

  if (isError || !taskResponse || isActivitiesError || !activitiesListResponse) {
    return <span>Error</span>;
  }

  const goToList = () => {
    history.goBack();
  };

  const { data: task } = taskResponse;

  const {
    id,
    attributes: {
      title,
      created_at,
      status_key,
      status,
      debtor,
      description,
      invoices,
      due_date,
      days_postponed,
      due_date_adjusted,
      comments,
      completed: isCompleted,
      skipped: isSkipped,
      task_type: {
        attributes: { uid },
      },
    },
  } = task;

  const handleUpdateTask = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <TaskForm
          task={task}
          onSubmit={(data: CreateTaskBody) =>
            updateTaskMutation(
              { id: taskId, data },
              {
                onSuccess: () => {
                  refetch();
                  refetchActivities();
                  sideMenuHide();
                },
              },
            )
          }
        />
      ),
    });
  };

  const handleUpdateStatus = (data: UpdateTasksStatusBody) =>
    updateTasksStatus(
      {
        data,
      },
      {
        onSuccess: () => {
          if (data.action_type === TaskActionType.delete) {
            goToList();
          } else {
            refetch();
            refetchActivities();
          }
          dialogHide(DialogShowId.CUSTOM);
        },
      },
    );

  // TODO REWORK AttachedInvoicesList Component
  const invoicesFormated = {
    type: 'attached_invoice',
    ...omit(invoices, ['details']),
    details: invoices.details.map(
      ({
        id: invoiceId,
        attributes: { due_date: due_date_invoice, localized_money_object, ...rest },
      }) =>
        ({
          id: Number(invoiceId),
          due_date: moment.utc(due_date_invoice) as unknown as Date,
          localized_money_object: localized_money_object as unknown as CurrencyLocalizedMoneyObject,
          ...rest,
        }) as unknown as AttachedInvoice,
    ),
  } as AttachedInvoices;

  const activitiesListFormated = formatActivities(activitiesListResponse);

  const contactInfo = () => {
    switch (task.attributes.task_type_id) {
      case 1:
        return (
          <div className={styles('item')}>
            <span className={styles('subtitle')}>{t(i18nKeys.PHONES)}</span>
            {debtor!.attributes.phones.map(({ attributes: { number } }) => (
              <a key={number} href={`tel:${number}`} className={styles('value')}>
                <div className={styles('value')}>{number}</div>
              </a>
            ))}
          </div>
        );
      case 2:
        return (
          <div className={styles('item')}>
            <span className={styles('subtitle')}>{t(i18nKeys.EMAIL)}</span>
            {debtor!.attributes.emails.map((email) => (
              <a key={email} href={`mailto:${email}`} className={styles('value', 'block')}>
                <span>{email}</span>
              </a>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  const submitComment = (data: CreateTaskCommentBody) =>
    createTaskComment(
      {
        id: taskId,
        data,
      },
      {
        onSuccess: () => {
          refetch();
          refetchActivities();
          dialogHide(DialogShowId.CUSTOM);
        },
      },
    );

  const goToPaymentPlanCreation = () => {
    history.push(
      `/payment-plans/create?debtor_id=${debtor?.id}&invoice_ids=${invoices.details.map(
        ({ id: invoiceId }) => invoiceId,
      )}`,
    );
  };

  const refusePlan = () =>
    declinePaymentPlanRequest(
      {
        data: { task_id: taskId },
      },
      {
        onSuccess: () => {
          refetch();
          refetchActivities();
          dialogHide(DialogShowId.CUSTOM);
        },
      },
    );

  const isSkippedOrCompleted = isSkipped || isCompleted;

  return (
    <div className={styles('task-detail')}>
      <div className={styles('task-wrapper')}>
        <div className={styles('title-line')}>
          <Icon name={IconName.MINIMAL_LEFT} onClick={goToList} />
          <h1>{title || id}</h1>
          <div className={styles('buttons-wrapper')}>
            {uid === 'payment_plan' && (
              <>
                <Button
                  noMargin
                  label={t(i18nKeys.TASK.PAYMENT_PLAN.ACCEPT)}
                  color={ButtonColor.GREEN}
                  onClick={goToPaymentPlanCreation}
                  disabled={isSkippedOrCompleted}
                  noShadow
                />
                <Button
                  noMargin
                  label={t(i18nKeys.TASK.PAYMENT_PLAN.DECLINE)}
                  color={ButtonColor.RED}
                  onClick={refusePlan}
                  disabled={isSkippedOrCompleted}
                  noShadow
                />
              </>
            )}
            <TaskActions taskIds={[id]} onClick={handleUpdateStatus}>
              <div
                className={styles('dropdown-item')}
                onClick={() => {
                  handleUpdateTask();
                }}
              >
                {t(i18nKeys.EDIT)}
              </div>
            </TaskActions>
          </div>
        </div>
        <div className={styles('line-wrapper')}>
          <Card title={t(i18nKeys.CLIENT.GENERAL_INFO)} className={styles('task-info', 'box')}>
            <div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>{t(i18nKeys.TASK.ATTRIBUTES.TITLE)}</span>
                <span className={styles('value', 'blue', 'bigger')}>{title}</span>
              </div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>{t(i18nKeys.CREATION_DATE)}</span>
                <DateItem
                  date={new Date(created_at)}
                  lg={currentLang}
                  format="DD/MM/YY"
                  className={styles('value', 'blue', 'bigger')}
                />
              </div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>{t(i18nKeys.DUE_DATE)}</span>
                {due_date_adjusted ? (
                  <DateItem
                    date={new Date(due_date_adjusted)}
                    lg={currentLang}
                    format="DD/MM/YY"
                    className={styles('value', 'blue', 'bigger')}
                  >
                    {!!days_postponed && (
                      <Tooltip icon={IconName.INFO} size="14px" color={IconColor.RED}>
                        <div>
                          <DateItem
                            date={new Date(due_date)}
                            lg={currentLang}
                            format="DD/MM/YY"
                            className={styles('value', 'white', 'bigger')}
                          />
                          + {t(i18nKeys.DAY_S, { count: days_postponed })}
                        </div>
                      </Tooltip>
                    )}
                  </DateItem>
                ) : (
                  <span className={styles('value', 'blue', 'bigger')}>-</span>
                )}
              </div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>{t(i18nKeys.TASK.ATTRIBUTES.TASK_TYPE)}</span>
                <span className={styles('value')}>{getTaskTypeDescription(task_types, task)}</span>
              </div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>{t(i18nKeys.TASK.ATTRIBUTES.ASSIGNEE)}</span>
                <div className={styles('value')}>{getAssigneeFullName(task)}</div>
              </div>
            </div>
          </Card>
          <Card title={t(i18nKeys.DETAILS)} className={styles('task-info', 'box')}>
            <div>
              <div className={styles('item')}>
                <span className={styles('subtitle')}>{t(i18nKeys.TASK.ATTRIBUTES.STATUS)}</span>
                <span>
                  <TasksListItemStatus statusKey={status_key} status={status} />
                </span>
              </div>
              {debtor && (
                <>
                  <div className={styles('item')}>
                    <span className={styles('subtitle')}>{t(i18nKeys.TASK.ATTRIBUTES.DEBTOR)}</span>
                    <span className={styles('value')}>
                      <NavLink to={`/clients/${debtor.id}`}>{debtor.attributes.full_name}</NavLink>
                    </span>
                  </div>
                  {contactInfo()}
                </>
              )}
              <div className={styles('item')}>
                <span className={styles('subtitle')}>{t(i18nKeys.DESCRIPTION)}</span>
                <div className={styles('value')}>{description}</div>
              </div>
            </div>
          </Card>
        </div>
        {!!invoices.details.length && (
          <AttachedInvoicesList
            title={t(i18nKeys.TASK.ATTRIBUTES.INVOICE)}
            invoices={invoicesFormated}
          />
        )}
        {!!activitiesListFormated.activities.length && (
          <InvoiceTimeline
            metadata={activitiesListFormated.metadata}
            activities={activitiesListFormated.activities}
          />
        )}
        <Comments comments={comments} submitComment={submitComment} />
      </div>
    </div>
  );
};
