import { AxiosInstance } from 'axios';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Id } from '../../api/models';

import { addActionNameToMutationResult, ApiError, ApiResponse, useAxiosInstance } from './utils';

export type UseMarkConversationAsReadVariables = {
  id: Id;
};

async function markConversationAsReadMutationFn(
  axiosInstance: Promise<AxiosInstance>,
  vars: UseMarkConversationAsReadVariables,
) {
  const instance = await axiosInstance;

  const data = instance.put(`conversations/${vars.id}/mark_as_read`);
  return data;
}

export function useMarkConversationAsRead() {
  const axiosInstance = useAxiosInstance();
  const client = useQueryClient();

  const mutation = useMutation({
    mutationFn: (vars: UseMarkConversationAsReadVariables) =>
      markConversationAsReadMutationFn(axiosInstance, vars),
    onError: (error: ApiError) => {
      console.error(error);
    },
    onSuccess: () => client.invalidateQueries({ queryKey: ['third-party-cases'] }),
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    UseMarkConversationAsReadVariables,
    'markConversationAsRead'
  >('markConversationAsRead', mutation);
}
