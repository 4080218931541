import axios, { AxiosError } from 'axios';
import { v4 } from 'uuid';

import { useMutation } from '@tanstack/react-query';

import { addActionNameToMutationResult, ApiResponse } from './utils';

export async function uploadFileFn(file: File) {
  const id = v4();
  return axios.put(`https://recovr-front-upload.s3.eu-west-3.amazonaws.com/${id}`, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
}

export function useUploadImageToS3() {
  const mutation = useMutation<
    ApiResponse,
    AxiosError<{ error_message: Array<string> }>,
    { file: File }
  >({
    mutationFn: ({ file }) => uploadFileFn(file),
  });

  return addActionNameToMutationResult<
    ApiResponse,
    AxiosError<{ error_message: Array<string> }>,
    { file: File },
    'uploadImageToS3'
  >('uploadImageToS3', mutation);
}
