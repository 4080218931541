import { ExternalMail } from 'api/models';
import { ExternalMailDetail } from 'app/Private/ExternalMailbox';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import styleIdentifiers from './ClientDetailTabsTimeline.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientDetailTabsTimelineItemEmailProps = {
  email: ExternalMail;
};

export const ClientDetailTabsTimelineItemEmail = ({
  email,
}: ClientDetailTabsTimelineItemEmailProps) => {
  const { t } = useTranslation();
  const {
    attributes: { subject, date, from_name, from_mail },
  } = email;

  const onClick = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(i18nKeys.BODY),
      children: <ExternalMailDetail emailId={email.id} />,
    });
  };

  return (
    <div onClick={onClick}>
      <span className={styles('date')}>{dayjs(date).fromNow()}</span>
      <div className={styles('from')}>
        {from_name} &#x3c;<a href={`mailto:${from_mail}`}>{from_mail}</a>&#x3e;
      </div>
      <p className={styles('sub-detail')}>{t(i18nKeys.EMAIL)}</p>
      <div className={styles('message')}>{subject}</div>
    </div>
  );
};
