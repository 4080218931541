import { Id, PaymentPlanComplete } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import InvoiceReferences from 'shared/components/InvoiceReferences/InvoiceReferences';
import { formattedDate } from 'shared/utils/view';
import { CurrencyCode } from 'types/currency';

import { StepsCalculationWorkflow } from '../PaymentPlanForm/StepsCalculation/StepsCalculationWorkflow';

import styleIdentifiers from '../PaymentPlanForm/PaymentPlanForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type PaymentPlanPropertiesProps = {
  paymentPlan: PaymentPlanComplete;
  addedInvoices?: Id[];
  newBalance?: number;
};

export const PaymentPlanProperties = ({
  paymentPlan,
  addedInvoices,
  newBalance,
}: PaymentPlanPropertiesProps) => {
  const { t, currentLang } = useTranslation();

  const {
    attributes: {
      debtor,
      invoices,
      workflow: {
        attributes: { name: workflowName },
      },
      remaining_balance,
      created_at,
      with_fees,
      currency,
      total_amount,
    },
  } = paymentPlan;

  const invoiceIds = invoices!.details?.map(({ id }) => id);

  const {
    attributes: { full_name },
  } = debtor;

  const canEditWorkflow = paymentPlan.attributes.payment_steps_attributes.every(
    (entry) => entry.attributes.can_edit,
  );

  return (
    <Card title={t(i18nKeys.DETAILS)}>
      <div className={styles('details', addedInvoices && 'additional-info')}>
        <div className="left">
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.COMMON.CLIENT)}</span>
            <span className={styles('value')}>{full_name}</span>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.INVOICES)}</span>
            <span className={styles('value')}>
              <InvoiceReferences ids={invoiceIds} />
            </span>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.WITH_FEES)}</span>
            <span className={styles('value')}>{t(i18nKeys[with_fees ? 'YES' : 'NO'])}</span>
          </div>
        </div>
        <div className="right">
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>{t(i18nKeys.CREATION_DATE)}</span>
            <span className={styles('value')}>{formattedDate(created_at, currentLang)}</span>
          </div>
          <div className={styles('subtitle-value')}>
            <span className={styles('subtitle')}>
              {t(i18nKeys.INVOICE.UNMATCH_PAYMENT.RECONCILIATION_AMOUNT)}
              {' / '}
              {addedInvoices
                ? t(i18nKeys.FORM.PAYMENT_PLAN.PROPERTIES.OLD_BALANCE)
                : t(i18nKeys.TOTAL)}
            </span>
            <span className={styles('value')}>
              <Amount value={total_amount - remaining_balance} suffix={currency} />
              <span style={{ margin: '0 5px' }}>/</span>
              <Amount value={total_amount} suffix={currency} />
            </span>
          </div>
          {canEditWorkflow ? (
            <div style={{ margin: '4px 0px 8px 0px' }}>
              <StepsCalculationWorkflow canUnselect={false} setCurrentWorkflow={() => {}} />
            </div>
          ) : (
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>{t(i18nKeys.PLAN.NAME)}</span>
              <span className={styles('value')}>{workflowName}</span>
            </div>
          )}
        </div>
      </div>
      {addedInvoices && (
        <div className={styles('details')}>
          <div className="left">
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>
                {t(i18nKeys.FORM.PAYMENT_PLAN.PROPERTIES.ADDED_INVOICES)}
              </span>
              <span className={styles('value')}>
                <InvoiceReferences ids={addedInvoices} />
              </span>
            </div>
          </div>
          <div className="right">
            <div className={styles('subtitle-value')}>
              <span className={styles('subtitle')}>
                {t(i18nKeys.FORM.PAYMENT_PLAN.PROPERTIES.NEW_BALANCE)}
              </span>
              <span className={styles('value')}>
                <Amount value={newBalance} suffix={currency} />
              </span>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};
