import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import UserInfo from 'shared/components/UserInfo/UserInfo';
import { useLoadLightUsers } from 'shared/hooks';
import { Checkbox, CustomSelect } from 'shared/io';
import { email } from 'shared/utils/validation';

import { Group, Space, Stack } from '@mantine/core';

import { CustomPreferencesFields, CustomPreferencesFieldsName } from '../PreferencesForm.constants';

import { CustomDomainPartialForm } from './CustomDomainPartialForm';

import styleIdentifiers from '../PreferencesForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const CustomPreferencesPartialForm = () => {
  const { t } = useTranslation();
  const { register, watch } = useFormContext<CustomPreferencesFields>();
  const { users = [] } = useLoadLightUsers();

  const willSendCopy = watch(CustomPreferencesFieldsName.SEND_COPY);

  return (
    <Stack>
      <Checkbox
        noMargin
        register={register(CustomPreferencesFieldsName.SEND_COPY)}
        watch={watch}
        label={
          <span>
            {t(i18nKeys.FORM.PREFERENCES.RECEIVE_COPY)}{' '}
            <Tooltip
              icon={IconName.INFO}
              size="14px"
              text={t(i18nKeys.FORM.PREFERENCES.TOOLTIP.RECEIVE_COPY)}
            />
          </span>
        }
      />
      {willSendCopy && (
        <Controller
          name={CustomPreferencesFieldsName.SEND_COPY_EMAILS}
          render={() => (
            <CustomSelect
              name={CustomPreferencesFieldsName.SEND_COPY_EMAILS}
              className={styles('send-copy-email')}
              multiple
              noArrow
              noMargin
              canAdd
              itemRendering={(props) => <UserInfo {...props} />}
              keyText="email"
              keyValue="email"
              items={users}
              label={t(i18nKeys.FORM.PREFERENCES.RECEIVE_ON)}
              inputValidation={email}
              registerMultipleInput={register(CustomPreferencesFieldsName.SEND_COPY_EMAILS_INPUT, {
                validate: {
                  emailInputNotEmpty: (value) => !value,
                },
              })}
            />
          )}
        />
      )}
      <Checkbox
        noMargin
        register={register(CustomPreferencesFieldsName.SEND_TO_ALL_EMAILS)}
        watch={watch}
        label={
          <span>
            {t(i18nKeys.FORM.PREFERENCES.SEND_TO_ALL_EMAILS)}{' '}
            <Tooltip
              icon={IconName.INFO}
              size="14px"
              text={t(i18nKeys.FORM.PREFERENCES.TOOLTIP.SEND_TO_ALL_EMAILS)}
            />
          </span>
        }
      />
      <CustomDomainPartialForm />
    </Stack>
  );
};
