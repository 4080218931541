import { useFormContext } from 'react-hook-form';
import { Input } from 'shared/io';

type VatInputProps = {
  name: string;
  label?: string;
  className?: string;
  vatRequired?: boolean;
  withBorder?: boolean;
  tabIndex?: number;
  defaultValue?: any;
  error?: any;
  noDropdownMargin?: boolean;
};

export const VatInput = ({ error, className, ...rest }: VatInputProps) => {
  const { register } = useFormContext();

  return (
    <Input register={register(rest.name)} {...rest} className={className} errorMessage={error} />
  );
};
