import { useEffect, useLayoutEffect, useState } from 'react';
import { ExternalMailboxSettingsForm } from 'app/Private/Settings';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { isEmpty } from 'remeda';
import CustomTable from 'shared/components/CustomTable';
import ListResModal from 'shared/components/DeleteModal';
import {
  BatchUpdateExternalMail,
  ExternalMail,
  ExternalMailsSearchParams,
  useBatchUpdateExternalMails,
  useCreateExternalMailbox,
  useLoadCompanyConfiguration,
  useLoadExternalMails,
  useManuallySyncExternalMails,
  useProfile,
} from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import {
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';

import { IconRefresh } from '@tabler/icons-react';

import {
  EXTERNAL_MAIL_STATUSES,
  ExternalMailListFiltersFields,
  TABLE_HEADERS,
} from '../ExternalMail.constants';
import { ExternalMailBatchActions } from '../ExternalMailBatchActions/ExternalMailBatchActions';

import { ExternalMailListFilters } from './ExternalMailListFilters';
import { ExternalMailListItem } from './ExternalMailListItem';

import styleIdentifiers from './ExternalMailList.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EnhancedTable = CustomTable(ExternalMailListItem, ExternalMailListFilters);

export const ExternalMailList = () => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const profile = useProfile();

  const [params, setParams] = useState<ExternalMailsSearchParams>({
    page_limit: profile.preferences.itemsPerPage,
    page: 1,
  });

  const [{ isSelectAllActive, selectedItems }, setSelection] = useState({
    isSelectAllActive: false,
    selectedItems: [] as ExternalMail[],
  });

  const { externalMails, isExternalMailsLoading, isExternalMailsFetching } =
    useLoadExternalMails(params);

  useEffect(() => {
    if (selectedItems.length !== externalMails?.data.length) {
      setSelection((currentState) => ({
        ...currentState,
        isSelectAllActive: false,
      }));
    }
  }, [selectedItems.length, externalMails?.data.length]);

  const emailIds = isSelectAllActive ? [] : selectedItems.map(({ id }) => id);

  useLayoutEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<ExternalMailsSearchParams>(Object.fromEntries(new URLSearchParams(location.search)), [
        'page',
        'page_limit',
        'sort_by',
        'sort_order',
        'date_before',
        'date_after',
        'from_mail',
        'to_mail',
        'subject',
        'status',
        'debtor_id',
        'external_mailbox_id',
        'account_manager_id',
        'body',
      ]),
    }));
  }, []);

  const { manuallySyncExternalEmails, isManuallySyncExternalEmailsLoading } =
    useManuallySyncExternalMails();

  const { createExternalMailbox } = useCreateExternalMailbox();

  const createExternalMailboxConfiguration = () =>
    sideMenuShow({
      unmount: true,
      content: (
        <ExternalMailboxSettingsForm
          onSubmit={(data) => {
            createExternalMailbox(data, {
              onSuccess: () => {
                sideMenuHide();
              },
            });
          }}
        />
      ),
    });

  const onCheckBoxChange = (newSelectedItems: ExternalMail[]) => {
    setSelection((currentState) => ({
      ...currentState,
      selectedItems: newSelectedItems,
    }));
  };

  const onSelectAll = (isSelected: boolean) => {
    setSelection((state) => ({
      ...state,
      isSelectAllActive: isSelected,
    }));
  };

  const onSortChange = (newParams: ExternalMailsSearchParams) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']),
    }));
  };

  const { batchUpdateExternalMails } = useBatchUpdateExternalMails();

  const batchActions = (data: BatchUpdateExternalMail) => {
    batchUpdateExternalMails(data, {
      onSuccess: (response) => {
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.MEDIUM,
          title: t(i18nKeys.RESULT),
          children: <ListResModal data={response.data.logs} />,
        });
        setSelection((currentState) => ({
          ...currentState,
          selectedItems: [],
          isSelectAllActive: false,
        }));
      },
    });
  };

  const handleFilters = (data: ExternalMailListFiltersFields) => {
    const filters = {
      ...pick(params, ['page_limit', 'page']),
      ...data,
    };
    setParams(filters);
    return filters;
  };

  const handleManuallySyncExtEmails = () => {
    manuallySyncExternalEmails();
  };

  const externalMailStatuses = EXTERNAL_MAIL_STATUSES.map(({ description, ...rest }) => ({
    description: t(description),
    ...rest,
  }));

  const filtersNames = {
    date_before: {
      description: t(i18nKeys.DATE),
    },
    date_after: {
      description: t(i18nKeys.DATE),
    },
    status: {
      description: t(i18nKeys.TASK.ATTRIBUTES.STATUS),
      value: externalMailStatuses,
    },
    from_mail: {
      description: t(i18nKeys.FROM),
    },
    to_mail: {
      description: t(i18nKeys.TASK.ATTRIBUTES.DEBTOR),
    },
    subject: {
      description: t(i18nKeys.SUBJECT),
    },
    body: {
      description: t(i18nKeys.BODY),
    },
    debtor_id: {
      description: t(i18nKeys.TASK.ATTRIBUTES.DEBTOR),
    },
    external_mailbox_id: {
      description: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE),
    },
    account_manager_id: {
      description: t(i18nKeys.ACCOUNT_MANAGER),
    },
  };

  const headers = TABLE_HEADERS.map(({ title, ...rest }) => ({
    title: t(title),
    ...rest,
  }));

  const { can_add_mailbox: canAddMailbox, can_use_external_mails: canUseExternalMails } =
    company.package;
  const tooltipMessage = canUseExternalMails
    ? i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LIMIT_EXCEEDED
    : i18nKeys.NOT_INCLUDED;

  return (
    <EnhancedTable
      title={t(i18nKeys.MAILS.NAV)}
      className={styles('mails-list')}
      headers={headers}
      items={externalMails?.data || []}
      pagination={externalMails?.metadata.pagination}
      itemProps={{ externalMails }}
      onSortChange={onSortChange}
      onCheckBoxChange={onCheckBoxChange}
      setSelectAll={onSelectAll}
      loading={isExternalMailsLoading || isManuallySyncExternalEmailsLoading}
      loaded={
        !(isExternalMailsLoading || isManuallySyncExternalEmailsLoading) && !isExternalMailsFetching
      }
      showShadow
      handleFilters={handleFilters}
      filtersNames={filtersNames}
      actions={
        isSelectAllActive || !isEmpty(selectedItems) ? (
          <div className={styles('actions')}>
            <ExternalMailBatchActions emailIds={emailIds} onClick={batchActions} />
          </div>
        ) : (
          <>
            <Button
              label={t(i18nKeys.REFETCH)}
              color={ButtonColor.BLUE}
              isLoading={isManuallySyncExternalEmailsLoading || isExternalMailsLoading}
              onClick={handleManuallySyncExtEmails}
              noMargin
              disabled={!canUseExternalMails}
              title={canUseExternalMails ? '' : t(i18nKeys.NOT_INCLUDED)}
              noShadow
              childrenBefore
            >
              <IconRefresh size="17px" style={{ marginRight: '10px' }} />
            </Button>

            <Button
              label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ADD)}
              onClick={createExternalMailboxConfiguration}
              noMargin
              title={canAddMailbox ? '' : t(tooltipMessage)}
              disabled={!canAddMailbox}
              noShadow
            />
          </>
        )
      }
    />
  );
};
