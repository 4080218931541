import { useLayoutEffect, useState } from 'react';
import { useGetTasks } from 'api/index';
import { DebtorComplete, GetTasksParams } from 'api/models';
import { useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useLocation } from 'react-router';
import CustomTable from 'shared/components/CustomTable';
import { useProfile } from 'shared/hooks';

import { TABLE_HEADERS } from '../Task.constants';

import { TaskListItem } from './TasksListItem';

const EnhancedTable = CustomTable(TaskListItem);

type TasksListProps = {
  debtor?: DebtorComplete;
};

export const TasksList = ({ debtor }: TasksListProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const profile = useProfile();

  const [params, setParams] = useState<GetTasksParams>({
    page_limit: profile.preferences.itemsPerPage,
    page: 1,
    debtor_id: debtor?.id,
  });

  useLayoutEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<GetTasksParams>(Object.fromEntries(new URLSearchParams(location.search)), [
        'page',
        'page_limit',
        'sort_by',
        'sort_order',
        'title',
        'description',
        'status',
        'user_id',
        'debtor_id',
        'invoice_ids',
        'mandatory',
        'task_type_id',
        'created_at_before',
        'created_at_after',
        'due_date_before',
        'due_date_after',
      ]),
    }));
  }, []);

  const { data: tasksListResponse, isLoading, isFetched, refetch } = useGetTasks(params);

  const onSortChange = (newParams: GetTasksParams) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['sort_by', 'sort_order', 'page_limit', 'page']),
    }));
  };

  const headers = TABLE_HEADERS.map(({ title, ...rest }) => ({
    title: t(title),
    ...rest,
  }));

  return (
    <div>
      <EnhancedTable
        headers={headers}
        items={tasksListResponse?.data || []}
        pagination={tasksListResponse?.metadata.pagination}
        loading={isLoading}
        loaded={isFetched}
        onSortChange={onSortChange}
        itemProps={{ refetch }}
        noShadow
        noCheckbox
      />
    </div>
  );
};
