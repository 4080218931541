import { z } from 'zod';

export const AVAILABLE_CURRENCIES = [
  { description: 'EUR (€)', code: 'EUR', symbol: '€' },
  { description: 'USD ($)', code: 'USD', symbol: '$' },
  { description: 'GBP (£)', code: 'GBP', symbol: '£' },
  { description: 'CHF (CHF)', code: 'CHF', symbol: 'CHF' },
  { description: 'SEK (kr)', code: 'SEK', symbol: 'kr' },
  { description: 'JPY (¥)', code: 'JPY', symbol: '¥' },
  { description: 'NOK (kr)', code: 'NOK', symbol: 'kr' },
] as const;

export const CURRENCY_CODES = ['EUR', 'USD', 'GBP', 'CHF', 'SEK', 'JPY', 'NOK'] as const;
export const CURRENCY_SYMBOLS = ['€', '$', '£', 'CHF', 'kr', '¥', 'kr'] as const;

export type CurrencyCode = (typeof CURRENCY_CODES)[number];
export type CurrencySymbol = (typeof CURRENCY_SYMBOLS)[number];

export const CurrencyCodeSchema = z.enum([...CURRENCY_CODES]);
export const CurrencySymbolSchema = z.enum([...CURRENCY_SYMBOLS]);
export const CurrencyEnumKeysSchema = z.record(z.enum(CURRENCY_CODES), z.coerce.number());

export type CurrencyLocalizedMoneyObject = {
  [key: string]: number;
  localized_total: number;
};
