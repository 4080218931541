import { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { ModalFooter } from 'shared/components';
import { RichTextArea } from 'shared/components/Inputs';

import { Modal } from '@mantine/core';

type SignatureEditorModalProps = {
  onSave: (htmlString: string | null) => void;
  onClose: VoidFunction;
  isOpen: boolean;
  signature: string | null;
};

export default function SignatureEditorModal({
  isOpen,
  onClose,
  onSave,
  signature,
}: SignatureEditorModalProps) {
  const { t } = useTranslation();
  const [htmlContent, setHtmlContent] = useState(signature);

  useEffect(() => {
    setHtmlContent(signature);
  }, [signature]);

  const handleSave = () => {
    onSave(htmlContent ?? null);
    onClose();
  };

  return (
    <Modal
      opened={isOpen}
      centered
      onClose={onClose}
      title={t(i18nKeys.EDIT_SIGNATURE)}
      size="auto"
    >
      <RichTextArea
        content={htmlContent ?? undefined}
        onChange={(content) => setHtmlContent(content)}
        style={{ height: '200px' }}
      />
      <ModalFooter onCancel={onClose} onSave={handleSave} />
    </Modal>
  );
}
