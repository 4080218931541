import { useEffect, useState } from 'react';
import { useGetSimilarDebtors } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router-dom';
import { openEditClient } from 'shared/action-component/EditClientForm';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import { Icon, IconName } from 'shared/components/Icon';
import { useGetClientState } from 'shared/utils/selectors';
import { reloadCustomView } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { DialogShowId, DialogShowSize, showDialog, sideMenuHide } from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import { NoCheckBox } from './BatchActions/NoCheckBox';
import { ShowCheckBox } from './BatchActions/ShowCheckBox';
import { ExportDebtorCsv } from './ExportDebtorCsv';

import styleIdentifiers from './ClientsList.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const ClientsList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const clients = useGetClientState().list;
  const [selectedClients, setSelectedClients] = useState<Debtor[]>([]);
  const [areAllItemsAcrossPagesSelected, setAreAllItemsAcrossPagesSelected] = useState(false);

  const { data: similarDebtorsListResponse, refetch: refetchSimilarDebtors } = useGetSimilarDebtors(
    {
      query: {
        enabled: false,
      },
    },
  );

  const shouldShowBatchActions = selectedClients.length > 0;

  useEffect(() => {
    if (clients?.loaded) {
      refetchSimilarDebtors();
    }
  }, [clients, refetchSimilarDebtors]);

  const handleFilters = (filters) => {
    const newFilters = {
      ...filters,
    };

    const amountKeys = ['payment_delay', 'remaining_balance', 'annual_ca', 'overdue_amount'];

    const hasInvalidFilter = amountKeys.some(
      (key) =>
        (newFilters[key] && !newFilters[`${key}_operator`]) ||
        (!newFilters[key] && newFilters[`${key}_operator`]),
    );

    if (hasInvalidFilter) return false;

    return newFilters;
  };

  const exportCsv = () => {
    sideMenuHide();
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.EXPORT_CSV),
      children: <ExportDebtorCsv />,
    });
  };

  const showDebtor = (item) => () => {
    history.push(`/clients/${item.id}`);
  };

  const handleClick = (item) => (e) => {
    e.stopPropagation();
    openEditClient({
      client: item,
      callback: () => {
        reloadCustomView(clientActions.listPageRes);
        refetchSimilarDebtors();
      },
    });
  };

  const actionsCol = (item) => (
    <div onClick={handleClick(item)}>
      <Icon name={IconName.PENCIL} size="18px" />
    </div>
  );

  const getActions = () => {
    if (shouldShowBatchActions) {
      return (
        <ShowCheckBox
          listUserChecked={selectedClients}
          selectAll={areAllItemsAcrossPagesSelected}
        />
      );
    }

    return (
      <NoCheckBox
        exportCSV={exportCsv}
        duplicatesCount={similarDebtorsListResponse?.metadata?.pagination.total_objects ?? 0}
      />
    );
  };

  return (
    <div className={styles('client-list')}>
      <CustomTableViews
        title={t(i18nKeys.CLIENT.YOUR_CLIENTS)}
        tableName="debtors"
        onClickRow={showDebtor}
        actionsCol={actionsCol}
        actions={getActions()}
        callbackAction={clientActions.listPageRes}
        isLoading={!clients.loaded}
        handleFilters={handleFilters}
        pagination={clients.metadata?.pagination}
        items={clients.pages!}
        onChangeSelectionCallback={(values) => setSelectedClients(values as Array<Debtor>)}
        onChangeSelectItemsAcrossAllPagesCallback={setAreAllItemsAcrossPagesSelected}
        currentPage={clients.currentPage}
      />
    </div>
  );
};
