import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'shared/io';
import { formatBankAccount } from 'shared/utils';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './BankAccountForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
};

export default function BankAccountForm({ onSubmit }: Props) {
  const { t } = useTranslation();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ shouldUnregister: true });

  const onSetBankAccount = (value) => {
    setValue('iban', formatBankAccount(value.target.value));
  };

  return (
    <form className={styles('bank-account-form')} onSubmit={handleSubmit(onSubmit)}>
      <Input
        errorMessage={errors.iban}
        onChange={onSetBankAccount}
        register={register('iban', { required: true })}
        type="text"
        label="IBAN"
      />
      <Input register={register('bic')} type="text" label={`BIC (${t(i18nKeys.OPTIONAL)})`} />
      <div className={styles('button-wrapper')}>
        <Button label={t(i18nKeys.FORM.VALIDATE)} type="submit" />
      </div>
    </form>
  );
}
