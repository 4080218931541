import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

dayjs.updateLocale('fr', {
  calendar: {
    sameDay: '[Aujourd’hui à] HH:mm',
    nextDay: '[Demain à] HH:mm',
    nextWeek: 'dddd [à] HH:mm',
    lastDay: '[Hier à] HH:mm',
    lastWeek: 'dddd [dernier à] HH:mm',
    sameElse: 'DD/MM/YYYY',
  },
});

dayjs.updateLocale('nl', {
  calendar: {
    sameDay: '[Vandaag om] HH:mm',
    nextDay: '[Morgen om] HH:mm',
    nextWeek: 'dddd [to] HH:mm',
    lastDay: '[Gisteren om] HH:mm',
    lastWeek: '[Afgelopen] dddd [om] HH:mm',
    sameElse: 'DD/MM/YYYY',
  },
});
