import { z } from 'zod';

export const REMINDER_STATUS = ['successful', 'failed', 'resent', 'ignored'] as const;

export type ReminderStatus = (typeof REMINDER_STATUS)[number];

export const ReminderStatusSchema = z.enum([...REMINDER_STATUS]);

export const IGNORED_REASONS = ['paid', 'manually_ignored', 'automatically_ignored'] as const;

export type IgnoredReason = (typeof IGNORED_REASONS)[number];

export const IgnoredReasonSchema = z.enum([...IGNORED_REASONS]);
