import { AxiosInstance } from 'axios';
import { isEmpty } from 'lodash-es';
import { isDefined, isNonNullish, omit, pick, pickBy } from 'remeda';
import { addResourceNameToQueryResult, ApiError, useAxiosInstance } from 'shared/hooks/utils';
import { numberOrNull } from 'shared/utils';
import { CurrencyCodeSchema } from 'types/currency';
import { z } from 'zod';

import { captureException } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

export const KANBAN_INVOICE_COLUMNS = [
  'late',
  'first_reminder',
  'last_reminder',
  'formal_notice',
  'third_party_case',
  'disputed',
] as const;

export type KanbanInvoiceColumn = (typeof KANBAN_INVOICE_COLUMNS)[number];

// #region Schemas
const KanbanInvoiceSchema = z.object({
  id: z.number(),
  reference: z.string(),
  remainingBalance: z.coerce.number(),
  daysLate: z.number(),
  debtor: z.object({
    id: z.number(),
    fullName: z.string(),
  }),
  thirdPartyCase: z
    .object({
      type: z.enum(['callcenter', 'lawyer', 'bailiff', 'debt_collector']),
      date: z.string(),
      description: z.string(),
    })
    .optional(),
});

const InvoiceColumnSchema = z.object({
  invoices: z.array(KanbanInvoiceSchema),
  total: z.object({
    totalInEur: z.coerce.number(),
    detail: z.array(
      z.object({
        amount: z.coerce.number(),
        currency: CurrencyCodeSchema,
      }),
    ),
  }),
});

export const LoadInvoiceColumnVariablesSchema = z.object({
  accountManagerId: z.coerce.number().optional().nullable(), // See (1)
  column: z.enum([...KANBAN_INVOICE_COLUMNS]),
  debtorName: z.string().optional(),
  startDate: z
    .date()
    .transform((date) => date?.toISOString())
    .nullable() // See (1)
    .optional(),
  endDate: z
    .date()
    .transform((date) => date?.toISOString())
    .nullable() // See (1)
    .optional(),
  reference: z.string().optional(),
  minDaysLate: z.union([z.number(), z.string()]).optional(),
  maxDaysLate: z.union([z.number(), z.string()]).optional(),
  remainingBalance: z.object({
    amount: z.union([z.number(), z.string()]).optional(),
    operator: z.enum(['>=', '<=']),
  }),
});

export const LoadInvoiceColumnVariablesTransformerSchema =
  LoadInvoiceColumnVariablesSchema.transform((data) => ({
    ...omit(data, ['remainingBalance']),
    ...(isDefined(data.remainingBalance.amount)
      ? {
          remainingBalance: numberOrNull(data.remainingBalance.amount),
          remainingBalanceOperator: data.remainingBalance.operator,
        }
      : {}),
  }));

// #endregion

// #region Type exports
export type KanbanInvoice = z.infer<typeof KanbanInvoiceSchema>;
export type InvoiceColumn = z.infer<typeof InvoiceColumnSchema>;
export type LoadInvoiceColumnVariablesInput = z.infer<typeof LoadInvoiceColumnVariablesSchema>;
export type LoadInvoiceColumnVariablesOutput = z.infer<
  typeof LoadInvoiceColumnVariablesTransformerSchema
>;
// #endregion

// #region Hook
export async function loadInvoiceColumnQueryFn(
  axiosInstance: Promise<AxiosInstance>,
  { queryKey },
) {
  const instance = await axiosInstance;
  const { data } = await instance.get('kanban/invoices', { params: queryKey[1] });
  return InvoiceColumnSchema.parse(data);
}

export const useLoadInvoiceColumn = (_variables: LoadInvoiceColumnVariablesInput) => {
  const axiosInstance = useAxiosInstance();
  const variablesCompacted = pickBy(_variables, (val) => isNonNullish(val) && !isEmpty(val));
  let variables: unknown;

  try {
    variables = LoadInvoiceColumnVariablesTransformerSchema.parse(variablesCompacted);
  } catch (error) {
    variables = pick(_variables, ['column']) as LoadInvoiceColumnVariablesInput;
    console.error(error?.toString() ?? error);
    captureException(error);
  }

  const queryResult = useQuery<InvoiceColumn, ApiError>({
    queryKey: ['invoice-kanban', variables],
    queryFn: (context) => loadInvoiceColumnQueryFn(axiosInstance, context),
    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<InvoiceColumn, ApiError, 'invoiceColumn'>(
    'invoiceColumn',
    queryResult,
  );
};
// #endregion

// MARK: Comments
// (1): Nullability is required t oproperly reset the date pickers when the filters are cleared
// If only marked optional, the component becomes uncontrolled and the old date stays visible.
