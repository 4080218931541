import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { AmountFields, DateSelector, Input } from 'shared/io';
import { currencySymbol } from 'shared/utils';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './ReconciliationInvoiceFilter.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  className?: string;
};

export default function ReconciliationInvoiceFilter({ onSubmit, className }: Props) {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const { register, getValues, handleSubmit, setValue } = useFormContext();
  const [timeout, setTimeoutDebounce] = useState();

  const submit = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    const newTimeout = setTimeout((): void => {
      const values = getValues();
      onSubmit(values);
    }, 500);

    setTimeoutDebounce(newTimeout as any);
  };

  return (
    <form
      className={styles('reconciliation-invoice-filter', className)}
      onSubmit={handleSubmit(submit)}
    >
      <div className={styles('fields')}>
        <DateSelector
          className={styles('input')}
          shadow
          withBorder
          noMinDate
          label={t(i18nKeys.ISSUE_DATE)}
          placeholder={t(i18nKeys.OF)}
          endPlaceholder={t(i18nKeys.FORM.TO)}
          endName="issue_date_before"
          name="issue_date_after"
          handleChange={(date) => {
            setValue('issue_date_after', date);
            submit();
          }}
          handleEndChange={(date) => {
            setValue('issue_date_before', date);
            submit();
          }}
        />
        <Input
          onValueChanged={submit}
          register={register('reconciliation_detail', {})}
          noMargin
          withBorder
          shadow
          type="text"
          label={t(i18nKeys.DETAILS)}
        />
        <AmountFields
          onChange={submit}
          className={styles('spacing')}
          name="remaining_balance"
          label={t(i18nKeys.BALANCE_CURRENCY, {
            text: currencySymbol(undefined, company),
          })}
        />
      </div>
    </form>
  );
}
