import { useEffect } from 'react';
import posthog from 'posthog-js';
import { isDefined } from 'remeda';
import { useEnforceProfile } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import { isProduction } from 'shared/utils';

export function useInitialisePosthog() {
  const { profile } = useEnforceProfile();

  useEffect(() => {
    if (isProduction() && isDefined(profile)) {
      posthog.init('phc_f3vuQblHJAnPKJA0qKZan0PmalMguSQJdPFSEw0Upsc', {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'identified_only',
      });

      posthog.identify(profile.id.toString(), {
        tenant: apiService.tenant,
        accessibleTenants: profile.accessibleTenants,
        locale: profile.locale,
        isAdmin: profile.isAdmin,
        roles: profile.roles,
      });
    }
  }, [profile]);
}
