import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import BankAccount from 'shared/components/BankAccount';
import { Checkbox } from 'shared/io';
import { currencySymbol, formatAmount } from 'shared/utils';
import { Debtor } from 'types/store/client-state';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './DebtorReactionCardHeader.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  data: any;
  selectAll: boolean;
  hasPaymentOptions: boolean;
  onChangeSelectAll: Function;
};

export default function DebtorReactionCardHeader({
  data,
  hasPaymentOptions,
  selectAll,
  onChangeSelectAll,
}: Props) {
  const constants = useSelector((state: StoreState) => state.app.constants);
  const { t } = useTranslation();
  return (
    <div className={styles('debtor-reaction-card-header')}>
      <div>
        <span>
          IBAN : <BankAccount value={data?.company_data.iban || ''} />
        </span>
        <span>
          {t(i18nKeys.INVOICE.DUE_AMOUNT)} :{' '}
          {formatAmount(
            data?.totalRemainingBalance,
            ',',
            '.',
            2,
            currencySymbol(undefined, data.debtor_data as Debtor),
          )}
        </span>
      </div>
      {hasPaymentOptions && (
        <div>
          <Checkbox
            noMargin
            label={t(selectAll ? 'FORM.DESELECT_ALL' : 'FORM.SELECT_ALL')}
            name=""
            onChange={onChangeSelectAll}
            checked={selectAll}
          />
        </div>
      )}
    </div>
  );
}
