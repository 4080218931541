import { PropsWithChildren, useEffect, useState } from 'react';
import { DueDateDelay } from 'api/models';
import { TaskFormColleague, TaskFormType } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { set } from 'date-fns';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import { isNonNullish } from 'remeda';
import { CustomSelect, Input, Textarea } from 'shared/io';
import { RecoveryPlanStep } from 'types/recovery-plan';

import { Checkbox, Group, NumberInput, Stack, Text, Tooltip } from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import { IconHelpCircle } from '@tabler/icons-react';

import styleIdentifiers from './RecoveryStepForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const RecoveryStepFormCreateTask = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation();

  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<RecoveryPlanStep>();

  const [isAddStartDateChecked, setIsAddStartDateChecked] = useState(
    isNonNullish(getValues('task_attributes.start_offset_days')),
  );
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<
    HTMLDivElement,
    HTMLDivElement
  >();

  register('step_type', { required: true, value: 'task_generation' });

  useEffect(() => {
    if (isAddStartDateChecked && targetRef.current) {
      scrollIntoView();
    }
  }, [isAddStartDateChecked, targetRef]);

  const dueDateDescription = (days) => {
    switch (days) {
      case 0:
        return i18nKeys.CREATION_DATE;
      case 999:
        return i18nKeys.NEXT_STEP_DATE;
      default:
        return i18nKeys.N_DAYS_AFTER_CREATION;
    }
  };

  const dueDateDelays = Object.values(DueDateDelay).map((e) => ({
    description: t(dueDateDescription(e), { days: e }),
    value: e.toString(),
  }));

  return (
    <>
      {children}
      <Input
        register={register('task_attributes.title', {
          required: true,
        })}
        errorMessage={get(errors, 'task_attributes.title')}
        label={t(i18nKeys.TASK.ATTRIBUTES.TITLE)}
        withBorder
      />
      <Textarea
        register={register('task_attributes.description')}
        className={styles('input')}
        withBorder
        label={t(i18nKeys.DESCRIPTION)}
      />
      <TaskFormType name="task_attributes.task_type_id" />
      <TaskFormColleague name="task_attributes.user_id" />
      <Controller
        name="task_attributes.due_date_delay"
        rules={{ required: true }}
        render={() => (
          <CustomSelect
            label={t(i18nKeys.DUE_DATE)}
            keyText="description"
            keyValue="value"
            name="task_attributes.due_date_delay"
            withBorder
            items={dueDateDelays}
          />
        )}
      />
      <Controller
        name="task_attributes.mandatory"
        rules={{ required: true }}
        render={() => (
          <CustomSelect
            label={t(i18nKeys.TASK.ATTRIBUTES.MANDATORY)}
            keyText="description"
            keyValue="value"
            name="task_attributes.mandatory"
            withBorder
            items={[
              { description: t(i18nKeys.YES), value: 'true' },
              { description: t(i18nKeys.NO), value: 'false' },
            ]}
          />
        )}
      />
      <Stack ref={scrollableRef}>
        <Group mt={16} justify="space-between" align="center">
          <Checkbox
            checked={isAddStartDateChecked}
            onChange={(event) => setIsAddStartDateChecked(event.currentTarget.checked)}
            label={t(i18nKeys.TASK.ATTRIBUTES.ADD_START_DATE)}
          />
          <Tooltip
            multiline
            w={250}
            withArrow
            transitionProps={{ duration: 200 }}
            label={t(i18nKeys.TASK.ATTRIBUTES.START_DATE_TOOLTIP)}
            position="top"
          >
            <IconHelpCircle color="grey" />
          </Tooltip>
        </Group>
        {isAddStartDateChecked && (
          <Group ref={targetRef}>
            <Controller
              name="task_attributes.start_offset_days"
              render={({ field }) => (
                <NumberInput
                  w={70}
                  placeholder="1"
                  min={1}
                  allowNegative={false}
                  allowDecimal={false}
                  required={isAddStartDateChecked}
                  value={field.value ?? ''}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
            <Text>{t(i18nKeys.TASK.ATTRIBUTES.DAYS_BEFORE_DUE_DATE)}</Text>
          </Group>
        )}
      </Stack>
    </>
  );
};
