import { i18nKeys, useTranslation } from 'locales';
import { mergeWith, subtract } from 'lodash-es';
import { clone } from 'remeda';
import { AgedBalanceCompact, useLoadAgedBalanceCompact, useLoadPausedInvoices } from 'shared/hooks';
import { useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils';

import { BaseTicker } from '../BaseTicker';
import { Format } from '../TickersBanner';

function getAmountPaused(pausedAmount: number, formatter: Intl.NumberFormat) {
  return formatter.format(pausedAmount);
}

function getPercentagePaused(agedBalanceCompact: AgedBalanceCompact, pausedAmount: number) {
  const netAgedBalance = mergeWith(
    clone(agedBalanceCompact.debits),
    agedBalanceCompact.credits,
    subtract,
  );

  const totalReceivable =
    netAgedBalance.notDue +
    netAgedBalance['0'] +
    netAgedBalance['30'] +
    netAgedBalance['60'] +
    netAgedBalance['90'];

  const percentage = (pausedAmount / totalReceivable) * 100;
  return percentage.toFixed(2);
}

interface PausedTickerProps {
  format: Format;
}

export const PausedTicker = ({ format }: PausedTickerProps) => {
  const { t } = useTranslation();
  const { agedBalanceCompact, isAgedBalanceCompactLoading } = useLoadAgedBalanceCompact();
  const { pausedInvoices, isPausedInvoicesLoading } = useLoadPausedInvoices();
  const formatter = useSafeLocalizedCompactCurrencyFormatter(true);

  if (
    isAgedBalanceCompactLoading ||
    agedBalanceCompact == null ||
    isPausedInvoicesLoading ||
    pausedInvoices == null
  ) {
    return <BaseTicker label={t(i18nKeys.ANALYTICS.TICKERS.PAUSED.TITLE)} />;
  }

  const value =
    format === 'percentage'
      ? getPercentagePaused(agedBalanceCompact, pausedInvoices)
      : getAmountPaused(pausedInvoices, formatter);

  return (
    <BaseTicker
      label={t(i18nKeys.ANALYTICS.TICKERS.PAUSED.TITLE)}
      value={value}
      suffix={format === 'percentage' ? '%' : '€'}
      url="/invoices/listing?&status=paused"
    />
  );
};
