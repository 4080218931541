import { CompanyConfiguration, useProfile } from 'shared/hooks';
import { Locale } from 'types';
import { AVAILABLE_CURRENCIES, CurrencyCode, CurrencySymbol } from 'types/currency';
import { Debtor } from 'types/store/client-state';

import { captureMessage } from '@sentry/react';

/**
 * @deprecated The currency should only:
 * - be part of a localizedAmount object
 * - be assumed to be EUR in other cases
 */
export function currencySymbol(
  currencySuffix?: CurrencyCode | undefined,
  fromCurrencyItem: CompanyConfiguration | Debtor | null = null,
) {
  const currency = currencySuffix ?? fromCurrencyItem?.currency ?? 'EUR';

  return AVAILABLE_CURRENCIES.find((c) => c.code === currency)?.symbol ?? '€';
}

/**
 * @deprecated Use `useSafeLocalizedCurrencyFormatter` instead
 */
export const formatAmount = (
  value,
  _decimal = ',',
  _thousands = '.',
  _decimalCount = 2,
  currencySymbolStr: CurrencySymbol = '€',
  locale: Locale = 'fr',
) => {
  const options: Intl.NumberFormatOptions = {};

  const currencyFromSymbol =
    AVAILABLE_CURRENCIES.find((c) => c.symbol === currencySymbolStr)?.code ?? 'EUR';

  options.style = 'currency';
  options.currency = currencyFromSymbol;

  const formatter = Intl.NumberFormat(locale, options);

  return formatter.format(value);
};

// See https://recovr.sentry.io/issues/4697076958/
// The problem is most likely a compatibility issue with the more recent `notation: 'compact'` option
function supportsCompactNotation() {
  try {
    new Intl.NumberFormat('en', { notation: 'compact' }).format(1000);
    return true;
  } catch (e) {
    captureMessage(
      'Intl.NumberFormat does not support compact notation, falling back to long notation',
    );
    return false;
  }
}

// Formatter that uses the compact notation if available without breaking on older browsers.
// If compact is not available, the long format will be used. I consider this acceptable given the
// small amount of people likely to be affected.
export function useSafeLocalizedCompactCurrencyFormatter(stripCurrency: boolean = false) {
  const { locale } = useProfile();

  const options: Intl.NumberFormatOptions = {};

  if (!stripCurrency) {
    options.style = 'currency';
    options.currency = 'EUR';
  }

  if (supportsCompactNotation()) {
    options.notation = 'compact';
  }

  return new Intl.NumberFormat(locale, options);
}

/**
 * Invoke the hook to get the formatter, then call the .format method on the formatter
 * @param stripCurrency boolean
 * @returns Intl.NumberFormat
 */
export function useLocalizedCurrencyFormatter(
  stripCurrency: boolean = false,
  currency?: CurrencyCode,
) {
  const profile = useProfile();

  const locale: Locale = profile?.locale ?? 'fr';

  const options: Intl.NumberFormatOptions = {};

  if (!stripCurrency) {
    options.style = 'currency';
    options.currency = currency ?? 'EUR';
  }

  return new Intl.NumberFormat(locale, options);
}

export function getCurrencySymbolFromCurrencyCode(currencyCode: CurrencyCode) {
  return AVAILABLE_CURRENCIES.find((c) => c.code === currencyCode)?.symbol ?? '€';
}
