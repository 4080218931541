import { Get, Paths } from 'type-fest';

function isExcluded(path: string, excludedPaths: string[]): boolean {
  return excludedPaths.some((excluded) => path.startsWith(excluded));
}

function isEmpty(value: any): boolean {
  return (
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    value === undefined
  );
}

export function countActiveFilters<Filters>(
  filters: Filters,
  excludedPaths: Array<Paths<Filters>> = [],
): number {
  let count = 0;

  function recurse(current: Filters | Get<Filters, string>, path: string) {
    if (typeof current !== 'object' || current === null || current instanceof Date) {
      if (!isExcluded(path, excludedPaths as Array<string>) && !isEmpty(current)) {
        count++;
      }
      return;
    }

    Object.keys(current).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(current, key)) {
        const newPath = path ? `${path}.${key}` : key;
        recurse(current[key], newPath);
      }
    });
  }

  recurse(filters, '');
  return count;
}
