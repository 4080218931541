import { useEffect, useState } from 'react';
import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { prop, uniqueBy } from 'remeda';
import { useLoadLightDebtors } from 'shared/hooks';
import { REMINDER_TYPES, SENDING_MEDIA } from 'types';

import { Checkbox, Drawer, Grid, MultiSelect, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { UseFormReturnType } from '@mantine/form';
import { useDebouncedState } from '@mantine/hooks';

import { RemindersFilter } from '../pages';

interface FilterDrawerProps {
  isOpen: boolean;
  close: () => void;
  form: UseFormReturnType<RemindersFilter>;
}

export const FilterDrawer = ({ isOpen, close, form }: FilterDrawerProps) => {
  const { t } = useTranslation();
  const [debtorSearchText, setDebtorSearchText] = useDebouncedState('', 200);
  const { lightDebtors } = useLoadLightDebtors({ query: debtorSearchText });
  const [persistedDebtors, setPersistedDebtors] = useState<
    Array<{
      value: string;
      label: string;
    }>
  >([]);

  useEffect(() => {
    const formattedForSelect = (lightDebtors ?? []).map((debtor) => ({
      value: String(debtor.id),
      label: debtor.fullName,
    }));
    setPersistedDebtors(uniqueBy([...persistedDebtors, ...formattedForSelect!], prop('value')));
  }, [lightDebtors?.map((debtor) => debtor.id).join()]);

  return (
    <Drawer
      position="right"
      opened={isOpen}
      onClose={close}
      title={t(i18nKeys.REMINDER_LIST.FILTERS.TITLE)}
    >
      <Grid>
        <Grid.Col span={12}>
          <TextInput
            label={t(i18nKeys.REMINDER_LIST.FILTERS.LABELS.INVOICE_REFERENCE)}
            placeholder={t(i18nKeys.REMINDER_LIST.FILTERS.PLACEHOLDERS.INVOICE_REFERENCE)}
            {...form.getInputProps('reference')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <MultiSelect
            label={t(i18nKeys.REMINDER_LIST.FILTERS.LABELS.DEBTOR_REFERENCE)}
            placeholder={t(i18nKeys.REMINDER_LIST.FILTERS.PLACEHOLDERS.DEBTOR_REFERENCE)}
            data={persistedDebtors}
            searchable
            clearable
            onSearchChange={setDebtorSearchText}
            {...form.getInputProps('debtorIds')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <MultiSelect
            label={t(i18nKeys.REMINDER_LIST.FILTERS.LABELS.REMINDER_TYPE)}
            placeholder={t(i18nKeys.REMINDER_LIST.FILTERS.PLACEHOLDERS.REMINDER_TYPE)}
            searchable
            clearable
            data={REMINDER_TYPES.map((type) => ({
              value: type,
              label: t(i18nKeys.REMINDER_LIST.FILTERS.REMINDER_TYPES[type.toUpperCase()]),
            }))}
            {...form.getInputProps('reminderTypes')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <MultiSelect
            label={t(i18nKeys.REMINDER_LIST.FILTERS.LABELS.SENT_MEDIA)}
            placeholder={t(i18nKeys.REMINDER_LIST.FILTERS.PLACEHOLDERS.SENT_MEDIA)}
            searchable
            clearable
            data={SENDING_MEDIA.filter((medium) => medium !== 'missing_information').map(
              (medium) => ({
                value: medium,
                label: t(i18nKeys.REMINDER_LIST.BADGE[medium.toUpperCase()]),
              }),
            )}
            {...form.getInputProps('sentMedia')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DatePickerInput
            label={t(i18nKeys.REMINDER_LIST.FILTERS.LABELS.SENT_AFTER)}
            placeholder={t(i18nKeys.REMINDER_LIST.FILTERS.PLACEHOLDERS.SENT_AFTER)}
            flex={1}
            allowDeselect
            clearable
            {...form.getInputProps('startDate')}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DatePickerInput
            label={t(i18nKeys.REMINDER_LIST.FILTERS.LABELS.SENT_BEFORE)}
            placeholder={t(i18nKeys.REMINDER_LIST.FILTERS.PLACEHOLDERS.SENT_BEFORE)}
            flex={1}
            allowDeselect
            clearable
            {...form.getInputProps('endDate')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Checkbox
            {...form.getInputProps('showUndelivered', { type: 'checkbox' })}
            onChange={(event) =>
              form.setFieldValue('showUndelivered', event.currentTarget.checked ? true : null)
            }
            label={t(i18nKeys.REMINDER_LIST.FILTERS.LABELS.SHOW_UNDELIVERED)}
          />
        </Grid.Col>
      </Grid>
    </Drawer>
  );
};
