import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { useHistory } from 'react-router';
import { IgnoredReason } from 'types/reminder-status';

import { Box, Button, Group, List, Stack, Text, useMantineTheme } from '@mantine/core';
import { IconExclamationCircleFilled } from '@tabler/icons-react';

import { Reminder } from '../api';

interface StatusCardProps {
  reminder: Reminder;
}

export const StatusCard = ({ reminder }: StatusCardProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const history = useHistory();

  const hasFailedReminder = reminder.status === 'failed';
  const isOriginalReminder = reminder.resentVia;
  const hasBeenIgnored = reminder.status === 'ignored';
  const isResentReminder = reminder.original;

  const getIgnoredReason = (reason: IgnoredReason) => {
    if (reason === 'paid') return t(i18nKeys.FAILED_REMINDERS.STATUS.IGNORE_PAID);
    if (reason === 'manually_ignored') return t(i18nKeys.FAILED_REMINDERS.STATUS.MANUALLY_IGNORE);
    if (reason === 'automatically_ignored')
      return t(i18nKeys.FAILED_REMINDERS.STATUS.AUTOMATICALLY_IGNORE);
    return t(i18nKeys.FAILED_REMINDERS.STATUS.IGNORED);
  };

  if (isResentReminder) {
    return (
      <Box bg="blue.0" p="lg" radius="md" style={{ borderRadius: '8px' }}>
        <Group maw={600} align="flex-start">
          <IconExclamationCircleFilled size={24} color={theme.colors.blue[5]} />
          <Stack maw={400}>
            <Text>{t(i18nKeys.FAILED_REMINDERS.STATUS.RESENT)}</Text>
            <Button onClick={() => history.push(`/reminders/${reminder.original}`)}>
              <Text>{t(i18nKeys.FAILED_REMINDERS.STATUS.BUTTON_SEE_ORIGINAL)}</Text>
            </Button>
          </Stack>
        </Group>
      </Box>
    );
  }

  if (hasBeenIgnored) {
    return (
      <Box bg="orange.0" p="lg" radius="md" style={{ borderRadius: '8px' }}>
        <Group maw={600} align="flex-start">
          <IconExclamationCircleFilled size={24} color={theme.colors.orange[5]} />
          <Stack maw={400} gap={0}>
            <Text>
              {reminder.ignoredReason
                ? getIgnoredReason(reminder.ignoredReason)
                : t(i18nKeys.FAILED_REMINDERS.STATUS.IGNORED)}
            </Text>
          </Stack>
        </Group>
      </Box>
    );
  }

  if (isOriginalReminder) {
    return (
      <Box bg="blue.0" p="lg" radius="md" style={{ borderRadius: '8px' }}>
        <Group miw={300} align="flex-start">
          <IconExclamationCircleFilled size={24} color={theme.colors.blue[5]} />
          <Stack maw={400}>
            <Text>{t(i18nKeys.FAILED_REMINDERS.STATUS.ORIGINAL_REMINDER)}</Text>

            {reminder.resentVia && (
              <Button onClick={() => history.push(`/reminders/${reminder.resentVia}`)}>
                <Text>{t(i18nKeys.FAILED_REMINDERS.STATUS.BUTTON_SEE_REMINDER)}</Text>
              </Button>
            )}
          </Stack>
        </Group>
      </Box>
    );
  }

  if (hasFailedReminder) {
    return (
      <Box bg="orange.0" p="lg" radius="md" style={{ borderRadius: '8px' }}>
        <Group maw={600} align="flex-start">
          <IconExclamationCircleFilled size={24} color={theme.colors.orange[5]} />
          <Stack maw={400} gap={0}>
            <Text>{t(i18nKeys.FAILED_REMINDERS.STATUS.FAILED)}</Text>
            <List>
              {reminder?.emails
                .filter((email) => email.failed)
                .map((email) => (
                  <List.Item key={email.email}>
                    <Text>{email.email}</Text>
                  </List.Item>
                ))}
            </List>
          </Stack>
        </Group>
      </Box>
    );
  }

  return null;
};
