import { useState } from 'react';
import CustomVariablesBatchForm from 'app/Private/CustomVariables/CustomVariablesBatchForm/CustomVariablesBatchForm';
import { SetReasonForm } from 'app/Private/Reasons/SetReasonForm/SetReasonForm';
import { TaskForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { isNil, omit, reject } from 'lodash-es';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import ChooseCollaboratorModal from 'shared/components/ChooseCollaboratorModal';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import ListResModal from 'shared/components/DeleteModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ChooseThirdPartyTypeForm } from 'shared/components/InvoiceActionsDropdown/ChooseThirdPartyTypeForm';
import FormalNoticeForm from 'shared/components/InvoiceActionsDropdown/FormalNotice/FormalNoticeForm';
import { GcollectModal } from 'shared/components/InvoiceActionsDropdown/GcollectModal';
import SelectThirdPartyForm from 'shared/components/InvoiceActionsDropdown/SelectThirdPartyForm';
import MoveRecoveryPlanInvoiceModal from 'shared/components/MoveRecoveryPlanInvoiceModal';
import { ThirdParties, ThirdPartyType, useLoadCompanyConfiguration } from 'shared/hooks';
import { ButtonColor } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { isConnectorActive } from 'shared/utils/connector';
import { useRefresh } from 'shared/utils/hooks';
import { queryParamParser, reloadCustomView } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { settingsActions } from 'store/settings/settings.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  error,
  showDialog,
  sideMenuHide,
} from 'store/view/view.actions';
import { Invoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import { modals } from '@mantine/modals';

import SendingOptionsForm from '../ToHandleDetail/SendingOptionsForm';

import styleIdentifiers from './BatchActions.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum Categories {
  RECOVERY = 'RECOVERY',
  STATUS = 'STATUS',
  FOLLOW_UP = 'FOLLOW_UP',
  ASSIGNMENT = 'ASSIGNMENT',
  OTHER = 'OTHER',
}

export type BatchActionsProps = {
  selectedInvoices: Invoice[];
  isSelectedAll: boolean;
  reload?: VoidFunction;
};

export default function BatchActions({
  selectedInvoices,
  isSelectedAll: _isSelectedAll,
  reload,
}: BatchActionsProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const { company } = useLoadCompanyConfiguration();
  const invoicesData = useSelector((state: StoreState) => state.invoice.list);
  const hasActiveConnector = isConnectorActive();

  const [isSelectedAll, setSelectedAll] = useState<boolean>(_isSelectedAll);

  const { doRefresh } = useRefresh();

  if (company == null) return null;

  const aysModal = (action_type) => (onSubmit) => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(
            selectedInvoices.length === 1 && !isSelectedAll
              ? i18nKeys.AYS[`INVOICE_ACTION_${action_type.toUpperCase()}`]
              : i18nKeys.AYS[`INVOICES_ACTION_${action_type.toUpperCase()}`],
            {
              count: isSelectedAll
                ? invoicesData.metadata.pagination.total_objects
                : selectedInvoices.length,
            },
          )}
          onConfirm={() => onSubmit({})}
        />
      ),
    });
  };

  const batchAction = (action_type, action) => () => {
    sideMenuHide();
    const data = {} as any;
    if (isSelectedAll) {
      data.filter_params = queryParamParser(qs.parse(location.search));
      data.view_id = location.hash.slice(1);
    } else {
      data.invoice_ids = selectedInvoices.map((invoice) => invoice.id);
    }

    action((values) => {
      invoiceActions.actionsBatchAction({
        data: {
          ...data,
          ...values,
          action_type,
        },
        callback: (res) => {
          dialogHide(DialogShowId.CONFIRM);
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.RESULT),
            children: <ListResModal data={res} />,
          });
          reloadCustomView(invoiceActions.listPageRes);
          setSelectedAll(false);
          doRefresh();
          reload?.();
        },
      });
    });
  };

  const paymentPlan = () => {
    const data = {} as any;
    if (isSelectedAll) {
      data.filter_params = queryParamParser(qs.parse(location.search));
      data.view_id = location.hash.slice(1);
    } else {
      data.invoice_ids = selectedInvoices.map((invoice) => invoice.id);
    }

    invoiceActions.actionsBatchAction({
      data: {
        ...data,
        action_type: 'payment_plan',
      },
      callback: (response) => {
        const { debtor_id, invoice_ids } = response[0];
        history.push(`/payment-plans/create?debtor_id=${debtor_id}&invoice_ids=${invoice_ids}`);
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  const pause = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CLIENT.PAUSE),
      children: (
        <SetReasonForm
          onSubmit={(values) =>
            batchAction('paused', (submit) => {
              submit(values);
            })()
          }
          reasonType="pause"
        />
      ),
    });
  };

  const lostOrDisputed = (actionType) => () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(`INVOICE.${actionType === 'lose' ? 'INVOICE_LOST' : 'DISPUTED'}`),
      children: (
        <SetReasonForm
          onSubmit={(values) =>
            batchAction(actionType, (submit) => {
              submit(values);
            })()
          }
          reasonType={actionType}
        />
      ),
    });
  };

  const selectStepPlan = (
    newPlan,
    plans,
    counter,
    maxItems,
    callback,
    step_switchs: any[] = [],
  ) => {
    MoveRecoveryPlanInvoices({
      newPlan,
      oldPlan: plans[counter - 1],
      title: t(i18nKeys.PLAN.MOVE_INVOICES),
      onSubmit: (steps_switch) => {
        step_switchs.push({
          to: newPlan.id,
          from: plans[counter - 1].id,
          steps_switch,
        });
        if (counter === maxItems) {
          callback(step_switchs);
        } else {
          selectStepPlan(newPlan, plans, counter + 1, maxItems, callback, step_switchs);
        }
      },
    });
  };

  const movePlan = async () => {
    sideMenuHide();
    let scope = {
      scope: 'invoice',
    } as any;
    if (isSelectedAll) {
      scope = { scope: 'invoice', ...qs.parse(location.search) };
      scope.view_id = location.hash.slice(1);
    } else {
      scope.invoice_ids = selectedInvoices.map((invoice) => invoice.id);
    }

    settingsActions.recoveryPlanList({
      callback: async ({ data }) => {
        const recoveryPlans = data.map((item) => treatRecoveryPlan(item));

        const planId: string | null = await new Promise((resolve) => {
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.PLAN.WHERE_MOVE_INVOICES),
            children: (
              <ChooseRecoveryPlanModal
                recoverPlans={recoveryPlans}
                onClick={(selectedPlan) => resolve(selectedPlan ?? null)}
              />
            ),
          });
        });

        const response: any = await new Promise((resolve) => {
          settingsActions.getRecoveryPlan({
            id: Number(planId),
            callback: resolve,
          });
        });

        const { newPlan, out_of_plan, ...res } = await new Promise<any>((resolve) => {
          settingsActions.getPlansInvoices({
            data: scope,
            callback: (props) =>
              resolve({
                ...props,
                newPlan: treatRecoveryPlan(response.data),
              }),
          });
        });

        const plans = res.data.map((plan) => treatRecoveryPlan(plan));

        if (plans.length === 0 && !out_of_plan) {
          error({
            text: i18nKeys.ERROR.NO_INVOICE_IN_PLAN,
          });
        } else {
          const stepIndex = await new Promise<number | null>((resolve) => {
            if (out_of_plan) {
              showDialog({
                id: DialogShowId.CUSTOM,
                size: DialogShowSize.LARGE,
                title: t(i18nKeys.PLAN.MOVE_INVOICES),
                children: <MoveRecoveryPlanInvoiceModal onSubmit={resolve} plan={newPlan} />,
              });
            } else {
              resolve(null);
            }
          });

          const plansSwitch = await new Promise<Array<any>>((resolve) => {
            if (plans.length > 0) {
              selectStepPlan(newPlan, plans, 1, plans.length, resolve);
            } else {
              resolve([]);
            }
          });

          if (stepIndex !== null) {
            plansSwitch.push({
              to: planId,
              from: null,
              steps_switch: [{ from: null, to: newPlan.recovery_steps[stepIndex!].id }],
            });
          }

          settingsActions.switchPlansInvoices({
            id: Number(planId),
            data: {
              plans_switch: plansSwitch,
              ...scope,
            },
            callback: ({ data: _data }) => {
              showDialog({
                id: DialogShowId.CUSTOM,
                size: DialogShowSize.MEDIUM,
                title: t(i18nKeys.RESULT),
                children: <ListResModal data={_data} />,
              });
            },
          });
        }
      },
    });
  };

  const assignToAccManager = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CHOOSE_ACCOUNT_MANAGER),
      children: <ChooseCollaboratorModal onSubmit={onSubmit} />,
    });
  };

  const taskDetails = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.FORM.TASK.NEW),
      children: <TaskForm batch onSubmit={onSubmit} />,
    });
  };

  const customVariablesDetails = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE),
      children: <CustomVariablesBatchForm onSubmit={onSubmit} resourceType="invoice" />,
    });
  };

  const { default_templates } = company;

  const additionalReminderDetails = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.INVOICE.SENDING_OPTIONS),
      children: (
        <SendingOptionsForm
          customText={t(i18nKeys.INVOICE.SENDING_OPTIONS_BATCH_SUMMARY)}
          onSubmit={onSubmit}
          initialValues={{
            template_id: default_templates.additional_reminder.id,
            send_email: false,
            send_post: false,
          }}
        />
      ),
    });
  };

  const formalNoticeDetails = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.INVOICE.SENDING_OPTIONS),
      children: (
        <FormalNoticeForm
          batch
          customText={t(i18nKeys.INVOICE.SENDING_OPTIONS_BATCH_SUMMARY)}
          onSubmit={onSubmit}
          onChangeTemplate={() => {}}
          initialValues={{
            template_id: default_templates.formal_notice.id,
            send_email: true,
          }}
        />
      ),
    });
  };

  const openGcollectFlow = (data: { third_party_company_id: number }) => {
    dialogHide(DialogShowId.CUSTOM);
    modals.open({
      title: 'Gcollect',
      centered: true,
      children: (
        <GcollectModal
          gcollectId={data.third_party_company_id}
          invoiceIds={selectedInvoices.map(({ id }) => id)}
        />
      ),
    });
  };

  const openThirdParty = (
    thirdParties: ThirdParties,
    selectedThirdPartyType: ThirdPartyType,
    onSubmitFunction: Function,
  ) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.FORM.CHOOSE_THIRD_PARTY_TYPE.TITLE),
      children: (
        <SelectThirdPartyForm
          onSubmit={onSubmitFunction}
          onSubmitGcollect={(data) => openGcollectFlow(data)}
          thirdParties={thirdParties}
          selectedThirdPartyType={selectedThirdPartyType}
        />
      ),
    });
  };

  const thirdPartyDetails = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(i18nKeys.FORM.CHOOSE_THIRD_PARTY_TYPE.TITLE),
      children: (
        <ChooseThirdPartyTypeForm
          invoices={selectedInvoices.map((invoice) => ({
            ...omit(invoice, 'attributes'),
            // @ts-ignore incorrectly typed
            ...invoice.attributes,
          }))}
          onSubmit={(selectedThirdPartyType, thirdParties) =>
            openThirdParty(thirdParties, selectedThirdPartyType, onSubmit)
          }
        />
      ),
    });
  };

  const allActions = [
    {
      name: i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_REMINDER,
      function: batchAction('additional_reminder', additionalReminderDetails),
      icon: IconName.SEND,
      category: Categories.RECOVERY,
    },
    {
      name: i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_FORMAL_NOTICE,
      function: batchAction('formal_notice', formalNoticeDetails),
      icon: IconName.FORMAL_NOTICE,
      category: Categories.RECOVERY,
    },
    {
      name: i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_THIRD_PARTY,
      function: batchAction('third_party', thirdPartyDetails),
      icon: IconName.LAW,
      category: Categories.RECOVERY,
    },
    {
      name: i18nKeys.PAYMENT_PLAN.TITLE.NEW,
      function: paymentPlan,
      icon: IconName.PAYMENT_BOOK,
      category: Categories.RECOVERY,
    },
    !hasActiveConnector
      ? {
          name: i18nKeys.MARK_AS_PAID,
          function: batchAction('paid', aysModal('paid')),
          icon: IconName.CHECK,
          category: Categories.STATUS,
        }
      : null,
    {
      name: i18nKeys.MARK_AS_DISPUTED,
      function: lostOrDisputed('dispute'),
      icon: IconName.ALERT_CIRCLE,
      category: Categories.STATUS,
    },
    {
      name: i18nKeys.REVERT_DISPUTED,
      function: batchAction('revert_dispute', aysModal('undisputed')),
      icon: IconName.BOX_RIBBON,
      category: Categories.STATUS,
    },
    {
      name: i18nKeys.MARK_AS_LOST,
      function: lostOrDisputed('lose'),
      icon: IconName.QUESTION,
      category: Categories.STATUS,
    },
    {
      name: i18nKeys.INVOICE.MOVE_PLAN,
      function: movePlan,
      icon: IconName.REPLACE,
      category: Categories.ASSIGNMENT,
    },
    {
      name: i18nKeys.ASSIGN_ACCOUNT_MANAGER_SHORT,
      function: batchAction('assign', assignToAccManager),
      icon: IconName.USER,
      category: Categories.ASSIGNMENT,
    },
    {
      name: i18nKeys.INVOICE.PAUSE_THE_FOLLOW_UP,
      function: pause,
      icon: IconName.PAUSE,
      category: Categories.FOLLOW_UP,
    },
    {
      name: i18nKeys.INVOICE.RESUME_FOLLUW_UP,
      function: batchAction('unpaused', aysModal('unpaused')),
      icon: IconName.PLAY,
      category: Categories.FOLLOW_UP,
    },
    {
      name: i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.TAKE_OUT_OF_PROCESS,
      function: batchAction('to_be_processed', aysModal('to_be_processed')),
      icon: IconName.ROBOT,
      category: Categories.FOLLOW_UP,
    },
    {
      name: i18nKeys.FORM.TASK.NEW,
      function: batchAction('task', taskDetails),
      icon: IconName.COMMENT,
      category: Categories.ASSIGNMENT,
      disabled: !company.package.can_use_tasks,
    },
    {
      name: i18nKeys.BATCH.ACTIONS.CUSTOM_VARIABLES,
      function: batchAction('custom_variables', customVariablesDetails),
      icon: IconName.PENCIL,
      category: Categories.OTHER,
      disabled: !company.package.can_use_custom_variables,
    },
    {
      name: i18nKeys.IGNORE_LATE_FEES,
      function: batchAction('ignore_late_fees', aysModal('ignore_late_fees')),
      icon: IconName.SIMPLE_REMOVE,
      category: Categories.OTHER,
    },
    !hasActiveConnector
      ? {
          name: i18nKeys.DELETE,
          function: batchAction('delete', aysModal('delete')),
          icon: IconName.TRASH_SIMPLE,
          category: Categories.OTHER,
          color: ButtonColor.RED,
        }
      : null,
  ];

  const actions = reject(allActions, isNil) as Array<NonNullable<(typeof allActions)[number]>>;

  return (
    <div className={styles('batch-actions-wrapper')}>
      {Object.values(Categories).map((category) => (
        <div key={category}>
          <h5>{t(i18nKeys.BATCH.CATEGORIES[category])}</h5>
          <hr />
          <div className={styles('batch-actions')}>
            {actions
              .filter((action) => action.category === category)
              .map((action) => (
                <div
                  className={styles('batch-action', action.color, action.disabled && 'disabled')}
                  onClick={action.function}
                  key={action.name}
                >
                  <Icon name={action.icon} />
                  <p>{t(action.name)}</p>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}
