import React, { useState } from 'react';
import { useGetContactPersons, useGetDebtor } from 'api/index';
import { useAtom } from 'jotai';
import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { useResendReminder } from 'modules/Reminders/api';
import { useHistory, useParams } from 'react-router';
import { isEmpty, isNullish, unique } from 'remeda';

import {
  Box,
  Button,
  Center,
  Group,
  MultiSelect,
  Space,
  Stack,
  TagsInput,
  Text,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconSend } from '@tabler/icons-react';

import { resendReminderModalAtom } from './state';

type FormType = {
  selectedEmails: string[];
  extraEmails: string[];
  clientEmails: string[];
};

export const InputStep = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [state, dispatch] = useAtom(resendReminderModalAtom);

  const { resendReminder, isResendReminderLoading } = useResendReminder();
  const { data: contactPersons } = useGetContactPersons(state.reminder!.debtorId);
  const { data: debtorDetailsResponse } = useGetDebtor(state.reminder!.debtorId);
  const clientEmails = debtorDetailsResponse?.data.attributes?.emails;

  const form = useForm<FormType>({
    initialValues: {
      selectedEmails: [],
      extraEmails: [],
      clientEmails: [],
    },
  });

  const onClickCancel = () => dispatch({ type: 'close' });
  const onClickResend = () => {
    const emails = unique([
      ...form.values.extraEmails,
      ...form.values.selectedEmails,
      ...form.values.clientEmails,
    ]);
    resendReminder(
      {
        id: String(state.reminder!.id),
        emails,
      },
      {
        onSuccess: () => {
          dispatch({ type: 'close' });
          if (id) history.goBack();
        },
      },
    );
  };

  const shouldShowContactPersons = contactPersons?.data && contactPersons?.data?.length > 0;
  const shouldShowClientEmail = clientEmails && clientEmails.length > 0;

  const contactPersonsForSelect = contactPersons?.data.reduce(
    (memo, person) => {
      if (isNullish(person.attributes.emails) || isEmpty(person.attributes.emails)) return memo;
      const splitEmails = person.attributes.emails.map((email) => ({
        label: `${person.attributes.first_name} ${person.attributes.last_name} - ${email}`,
        value: email,
      }));
      return [...memo, ...splitEmails];
    },
    [] as Array<{ label: string; value: string }>,
  );

  return (
    <Box>
      <Space h={20} />
      <Center>
        <Stack w="50%" pb={20}>
          {shouldShowClientEmail && (
            <MultiSelect
              w="100%"
              searchable
              {...form.getInputProps('clientEmails')}
              styles={{
                label: { fontWeight: 400, marginBottom: '5px' },
              }}
              placeholder={
                isEmpty(form.values.clientEmails)
                  ? t(i18nKeys.FAILED_REMINDERS.MODALS.RESEND.DEBTOR_EMAIL_PLACEHOLDER)
                  : undefined
              }
              label={t(i18nKeys.FAILED_REMINDERS.MODALS.RESEND.DEBTOR_EMAIL_LABEL)}
              data={clientEmails}
            />
          )}
          {shouldShowContactPersons && (
            <MultiSelect
              w="100%"
              searchable
              {...form.getInputProps('selectedEmails')}
              styles={{
                label: { fontWeight: 400, marginBottom: '5px' },
              }}
              placeholder={
                isEmpty(form.values.selectedEmails)
                  ? t(i18nKeys.FAILED_REMINDERS.MODALS.RESEND.DEBTOR_CONTACT_PERSON_PLACEHOLDER)
                  : undefined
              }
              label={t(i18nKeys.FAILED_REMINDERS.MODALS.RESEND.DEBTOR_CONTACT_PERSON_LABEL)}
              data={contactPersonsForSelect}
            />
          )}
          <Box>
            <TagsInput
              {...form.getInputProps('extraEmails')}
              styles={{
                label: { fontWeight: 400, marginBottom: '5px' },
              }}
              placeholder={
                isEmpty(form.values.extraEmails)
                  ? t(i18nKeys.FAILED_REMINDERS.MODALS.RESEND.EXTERNAL_EMAIL_PLACEHOLDER)
                  : undefined
              }
              w="100%"
              label={t(i18nKeys.FAILED_REMINDERS.MODALS.RESEND.EXTERNAL_EMAIL_LABEL)}
            />
            <Text c="dimmed" size="xs" mt={6}>
              {t(i18nKeys.FAILED_REMINDERS.MODALS.RESEND.SUBMIT_HINT)}
            </Text>
          </Box>
        </Stack>
      </Center>
      <Group p="md" justify="end">
        <Button
          loading={isResendReminderLoading}
          color="orange"
          variant="light"
          onClick={onClickCancel}
        >
          {t(i18nKeys.FAILED_REMINDERS.MODALS.CANCEL)}
        </Button>
        <Button
          loading={isResendReminderLoading}
          onClick={onClickResend}
          leftSection={<IconSend stroke={1.5} size={18} />}
        >
          {t(i18nKeys.FAILED_REMINDERS.ACTION_RESEND)}
        </Button>
      </Group>
    </Box>
  );
};
