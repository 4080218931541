import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Button, ButtonColor } from 'shared/io';
import { formatAmount } from 'shared/utils';
import { formattedDate } from 'shared/utils/view';
import { OnClickFunction } from 'types/html-type';

import styleIdentifiers from './ReconciliationMobileModal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  selectedInvoices: any[];
  selectedPayments: any[];
  submit: OnClickFunction;
};

export default function ReconciliationMobileModal({
  selectedInvoices,
  selectedPayments,
  submit,
}: Props) {
  const { t, currentLang } = useTranslation();

  return (
    <div className={styles('reconciliation-modal')}>
      <h3>{t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)}</h3>
      {selectedPayments.map((payment) => (
        <div key={payment.id} className={styles('item')}>
          <div>
            <span className={styles('blue')}>{payment.counterparty_name} </span>{' '}
            <span className={styles('no-wrap')}>{formatAmount(payment.amount)}</span>{' '}
          </div>
          <div className={styles('bold')}>{t(i18nKeys.COMMUNICATION)} :</div>
          <div>{payment.communication}</div>
        </div>
      ))}
      <h3>{t(i18nKeys.INVOICES)}</h3>
      {selectedInvoices.map((invoice) => (
        <div key={invoice.id} className={styles('item')}>
          <div>
            <span className={styles('blue')}>{(invoice as any).debtor_name} </span>{' '}
            <span className={styles('no-wrap')}>{formatAmount(invoice.remaining_balance)}</span>
          </div>
          <div>
            <span className={styles('bold')}> {invoice.reference} </span>{' '}
            <span>{formattedDate(invoice.issue_date, currentLang)}</span>
          </div>
        </div>
      ))}
      <Button
        className={styles('variable', 'reconciliation-button')}
        label={t(i18nKeys.RECONCILE)}
        noMargin
        color={ButtonColor.GREEN}
        disabled={!(selectedInvoices.length > 0 && selectedPayments.length > 0)}
        onClick={submit}
      />
    </div>
  );
}
