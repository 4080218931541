import classNames from 'classnames/bind';
import Amount from 'shared/components/Amount';
import MultiCurrencyData from 'shared/components/MultiCurrencyData';
import { Saldo } from 'shared/hooks/use-load-dashboard';
import { CurrencyCode } from 'types/currency';

import styleIdentifiers from './bankStatus.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  data: Saldo;
  currency: CurrencyCode;
};

export default function BankStatusAmount({ data, currency }: Props) {
  const { localizedTotal, ...rest } = data;

  const currencies = Object.keys(rest).filter((e) => e !== currency);
  if (!currencies.length) {
    return (
      <Amount
        value={localizedTotal}
        prefix={localizedTotal >= 0 ? '+ ' : undefined}
        className={styles('amount-bank')}
      />
    );
  }

  return (
    <Amount
      value={localizedTotal}
      prefix={localizedTotal >= 0 ? '+ ' : undefined}
      className={styles('amount-bank')}
    >
      <MultiCurrencyData data={data} prefix="+ " />
    </Amount>
  );
}
