import { AxiosInstance } from 'axios';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { CreateExternalMailboxVariables } from './use-create-external-mailbox';
import { ApiError, ApiResponse, useLegacyAxiosInstance } from './utils';

type updateExternalMailboxVariables = Partial<CreateExternalMailboxVariables>;

export async function updateExternalMailboxFn(
  axiosInstance: Promise<AxiosInstance>,
  { id, data }: { id: string; data: updateExternalMailboxVariables },
) {
  const instance = await axiosInstance;
  return instance.put(`external_mailbox_configurations/${id}`, data);
}

export function useUpdateExternalMailbox() {
  const client = useQueryClient();
  const axiosInstance = useLegacyAxiosInstance();

  const mutation = useMutation<
    ApiResponse,
    ApiError,
    { id: string; data: updateExternalMailboxVariables }
  >({
    mutationFn: (variables) => updateExternalMailboxFn(axiosInstance, variables),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({ queryKey: ['externalMailboxes'] });
    },
    onError: (error) => {
      notifications.show({
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
        color: 'red',
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    { id: string; data: updateExternalMailboxVariables },
    'updateExternalMailbox'
  >('updateExternalMailbox', mutation);
}
