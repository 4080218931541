import { useEffect } from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import moment, { Moment } from 'moment';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { isNonNullish } from 'remeda';
import Amount from 'shared/components/Amount';
import Card from 'shared/components/Card';
import DateItem from 'shared/components/DateItem';
import HTML from 'shared/components/HTML/HTML';
import FormSection from 'shared/forms/FormSection';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { formattedDate } from 'shared/utils/view';
import { accountActions } from 'store/account/account.actions';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { Activity, EmailDeliveryStatus } from 'types/store/activity';
import { StoreState } from 'types/storeTypes';

import { styled } from '@stitches/react';
import { useQueryClient } from '@tanstack/react-query';

import { Icon, IconName } from '../Icon';

import Conversation from './Conversation';

import styleIdentifiers from './ActivityDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EmailStatusHistory = styled('div', {});

const EmailStatusHistoryItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  padding: '10px 0px 0px 5px',
});

type Props = {
  activity: Activity;
};

export default function ActivityDetail({ activity }: Props) {
  const isActivityLoaded = useSelector((state: StoreState) => state.account.activity).loaded;
  const invoice = useSelector((state: StoreState) => state.invoice.current?.data);
  const creditNote = useSelector((state: StoreState) => state.creditNote.current?.data);
  const {
    company: {
      package: { can_use_emails_activity: canUseEmailStatus },
    },
  } = useLoadCompanyConfiguration();

  const { t, currentLang } = useTranslation();
  const queryClient = useQueryClient();

  const location = useLocation();
  const history = useHistory();

  const flattenedStatuses = Object.keys(activity.email_statuses ?? {}).reduce(
    (memo, currentKey) => [
      ...memo,
      ...activity.email_statuses![currentKey].map((date) => ({
        status: currentKey,
        timestamp: moment(date),
      })),
    ],
    [] as Array<{ status: EmailDeliveryStatus; timestamp: Moment }>,
  );

  const sortedStatuses = flattenedStatuses.sort((a, b) => b.timestamp.diff(a.timestamp));

  const reloadInvoice = () => {
    invoiceActions.activity({
      id: invoice!.id,
      noLoading: true,
      noReset: true,
    });
  };

  const reloadCreditNote = () => {
    creditNoteActions.activity({
      id: creditNote!.id,
      noLoading: true,
      noReset: true,
    });
  };

  const reloadActivities = () => {
    accountActions.activityPage(qs.parse(location.search));
  };

  const reload = () => {
    if (invoice) reloadInvoice();
    if (creditNote) reloadCreditNote();
    if (isActivityLoaded) reloadActivities();
  };

  useEffect(() => {
    if (activity.new) {
      accountActions.activitySeen({
        id: activity.id,
        noLoading: true,
        noFeedback: true,
        callback: () => {
          queryClient.invalidateQueries({
            queryKey: ['notifications', 'count'],
          });
        },
      });
    }
  }, [activity]);

  const detailInvoice = () => {
    dialogHide(DialogShowId.CUSTOM);

    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children: (
        <ToHandleDetail
          isCreditNote={activity.tracked_type === 'CreditNote'}
          id={activity.tracked_id}
          modal
        />
      ),
    });
  };

  const detailDebtor = () => {
    dialogHide(DialogShowId.CUSTOM);

    history.push(`/clients/${activity.tracked_id}`);
  };

  const detailTask = () => {
    dialogHide(DialogShowId.CUSTOM);

    history.push(`/tasks/${activity.tracked_id}`);
  };

  const detailPaymentPlan = () => {
    dialogHide(DialogShowId.CUSTOM);

    history.push(`/payment-plan/${activity.payment_plan_id}`);
  };

  const getIcon = (): IconName => {
    switch (activity.tracked_type) {
      case 'CreditNote':
        return IconName.CREDIT_NOTE;
      case 'Invoice':
        return IconName.INVOICE;
      case 'Debtor':
        return IconName.CLIENT;
      case 'Question':
        return IconName.ALERT_CIRCLE;
      case 'CompanyDetail':
        return IconName.SETTINGS_GEAR;
      default:
        return IconName.ALERT_CIRCLE;
    }
  };

  const onSubmitMessage = () => {
    reload();
  };

  const downloadDocument = () => {
    invoiceActions.document({
      id: activity.tracked_id,
      type: activity.document_type,
      ...(isNonNullish(activity.document_id) ? { document_id: activity.document_id } : {}),
    });
  };

  const titleKey = (() => {
    if (activity.activity_type === 'lawyer') return i18nKeys.INVOICE.LAWYER_CASE;
    if (activity.activity_type === 'callcenter') return i18nKeys.INVOICE.CALLCENTER_CASE;
    if (activity.activity_type === 'bailiff') return i18nKeys.INVOICE.BAILIFF_CASE;
    if (activity.payment_plan_id) return i18nKeys.PAYMENT_PLAN.TITLE.ONE;
    if (activity.tracked_type === 'CreditNote')
      return i18nKeys.ACTIVITIES.UPDATE_STATUS_CREDIT_NOTE;
    if (activity.tracked_type === 'Invoice') return i18nKeys.ACTIVITIES.UPDATE_STATUS_INVOICE;
    if (activity.tracked_type === 'Debtor') return i18nKeys.ACTIVITIES.UPDATE_STATUS_DEBTOR;
    if (activity.tracked_type === 'CompanyDetail') return i18nKeys.ACTIVITIES.EDIT_COMPANY;
    if (activity.tracked_type === 'Task') return i18nKeys.ACTIVITIES.UPDATE_STATUS_TASK;
    return i18nKeys.DETAILS;
  })();

  const showPaymentPlan = activity.payment_plan_id;
  const shouldShowClient = activity.tracked_type === 'Debtor' && !showPaymentPlan;

  const shouldShowLinkToReminder = activity?.reminder?.wasSentByMail;

  return (
    <div className={styles('ActivityDetail')}>
      <Card title={t(titleKey)} classContent={styles('modal-body')}>
        <div>
          <div className={styles('content')}>
            <div className={styles('left')}>
              <Icon name={getIcon()} font-size="24px" className={styles('icon')} />
              <div className={styles('text')}>
                <HTML html={activity.description} />
                {activity.payment_information && (
                  <>
                    <div style={{ display: 'flex' }}>
                      <b style={{ marginRight: '5px' }}>{t(i18nKeys.AMOUNT)} : </b>
                      <Amount
                        value={activity.payment_information.localized_money_object.amount}
                        localizedValue={activity.payment_information.amount}
                        suffix={activity.payment_information.currency}
                      />
                    </div>
                    <div>
                      <b>{t(i18nKeys.DATE)} : </b>
                      <DateItem lg={currentLang} date={activity.payment_information.date} />
                    </div>
                    <div>
                      <b>{t(i18nKeys.MODALITY)} : </b>
                      <span>{activity.payment_information.modality}</span>
                    </div>
                    {activity.payment_information.notes && (
                      <div>
                        <b>{t(i18nKeys.NOTES)} : </b>
                        <span>{activity.payment_information.notes}</span>
                      </div>
                    )}
                    {activity.payment_information.counterparty_name && (
                      <div>
                        <b>{t(i18nKeys.ACTIVITIES.COUNTERPART)} : </b>
                        <span>{activity.payment_information.counterparty_name}</span>
                      </div>
                    )}
                    {activity.payment_information.external_id && (
                      <div>
                        <b>{t(i18nKeys.ACTIVITIES.EXTERNAL_ID)} : </b>
                        <span>{activity.payment_information.external_id}</span>
                      </div>
                    )}
                  </>
                )}
                {activity.comment && (
                  <div>
                    <b>{t(i18nKeys.COMMENT)} : </b>
                    <span>{activity.comment}</span>
                  </div>
                )}
                {activity.reason && (
                  <div>
                    <b>{t(i18nKeys.REASON)} : </b>
                    <span>{activity.reason}</span>
                  </div>
                )}
                {activity.end_pause_at && (
                  <div>
                    <b>{t(i18nKeys.END_OF_PAUSE)} : </b>
                    <DateItem lg={currentLang} date={activity.end_pause_at} />
                  </div>
                )}
              </div>
            </div>
            <div className={styles('right')}>
              {activity.attachment_url && (
                <div className={styles('img-wrapper')}>
                  <img src={activity.attachment_url} alt="avatar" />
                </div>
              )}
              <span className={styles('text')}>
                {dayjs(activity.date).fromNow()} {t(i18nKeys.FROM)} <span>{activity.author}</span>
              </span>
            </div>
          </div>
          {sortedStatuses.length !== 0 && canUseEmailStatus && (
            <EmailStatusHistory>
              <FormSection title={t(i18nKeys.MAILS.HISTORY)}>
                {sortedStatuses.map((_item, index) => (
                  <EmailStatusHistoryItem key={index}>
                    <span>{t(i18nKeys.MAILS.STATUS[_item.status.toUpperCase()])}</span>
                    <span style={{ fontStyle: 'italic', color: '#bbb' }}>
                      {formattedDate(_item.timestamp, currentLang, 'DD/MM/YYYY, LT')}
                    </span>
                  </EmailStatusHistoryItem>
                ))}
              </FormSection>
            </EmailStatusHistory>
          )}
        </div>
        {activity.invoice_feedback?.conversation && (
          <Conversation
            className={styles('conversation')}
            invoiceId={activity.tracked_id}
            invoiceFeedback={activity.invoice_feedback}
            onSubmit={onSubmitMessage}
            canNotComment={activity.invoice_feedback.closed}
          />
        )}
        <div className={styles('bottom')}>
          {activity.tracked_type === 'Invoice' && (
            <div className={styles('see-detail')} onClick={detailInvoice}>
              {t(i18nKeys.ACTIVITIES.SEE_INVOICE)}
              <Icon name={IconName.CIRCLE_RIGHT} size="13px" className={styles('bold')} />
            </div>
          )}
          {shouldShowLinkToReminder && (
            <div
              className={styles('see-detail')}
              onClick={() => window.open(`/reminders/${activity.reminder?.id}`, '_blank')}
            >
              {t(i18nKeys.REMINDER.SEE_REMINDER_DETAILS)}
              <Icon name={IconName.CIRCLE_RIGHT} size="13px" className={styles('bold')} />
            </div>
          )}
          {activity.tracked_type === 'CreditNote' && (
            <div className={styles('see-detail')} onClick={detailInvoice}>
              {t(i18nKeys.ACTIVITIES.SEE_CREDIT_NOTE)}
              <Icon name={IconName.CIRCLE_RIGHT} size="13px" className={styles('bold')} />
            </div>
          )}
          {activity.tracked_type === 'Task' && (
            <div className={styles('see-detail')} onClick={detailTask}>
              {t(i18nKeys.ACTIVITIES.SEE_TASK)}
              <Icon name={IconName.CIRCLE_RIGHT} size="13px" className={styles('bold')} />
            </div>
          )}
          {showPaymentPlan && (
            <div className={styles('see-detail')} onClick={detailPaymentPlan}>
              {t(i18nKeys.ACTIVITIES.SEE_PAYMENT_PLAN)}
              <Icon name={IconName.CIRCLE_RIGHT} size="13px" className={styles('bold')} />
            </div>
          )}
          {shouldShowClient && (
            <div className={styles('see-detail')} onClick={detailDebtor}>
              {t(i18nKeys.ACTIVITIES.SEE_CLIENT)}
              <Icon name={IconName.CIRCLE_RIGHT} size="13px" className={styles('bold')} />
            </div>
          )}
          {activity.has_document && (
            <div className={styles('see-detail')} onClick={downloadDocument}>
              {t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT)}
              <Icon name={IconName.PDF} size="13px" className={styles('bold')} />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
