import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import { Icon, IconName } from 'shared/components/Icon';
import Percentage from 'shared/components/Percentage';
import Tooltip from 'shared/components/Tooltip';
import { CustomSelect, Input } from 'shared/io';
import { currencySymbol, formatAmount } from 'shared/utils';
import { productActions } from 'store/product/product.actions';
import { Debtor } from 'types/store/client-state';
import { Product } from 'types/store/product-state';
import { StoreState } from 'types/storeTypes';

import { InvoiceFormSharedProps } from '../../InvoiceForm.types';

import styleIdentifiers from './InvoiceProductLine.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InvoiceProductLineProps = InvoiceFormSharedProps & {
  lineIndex: number;
  removeProductLine: Function;
  field: any;
  initialValues: any;
};

export default function InvoiceProductLine({
  lineIndex,
  removeProductLine,
  field,
  initialValues,
  isCreditNote,
  noTva,
  currency,
  company,
  constants,
}: InvoiceProductLineProps) {
  const { t } = useTranslation();

  const listProducts = useSelector((state: StoreState) => state.product.search?.data || []);

  const {
    watch,
    setValue,
    register,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const product_lines_attributes = watch('product_lines_attributes');
  const productChanged = watch('productChanged');
  const debtor = watch('debtor_attributes') as Debtor | null;

  const onSelectProduct = ({ item }: { item: Product }) => {
    if (isCreditNote) {
      if (item.price_htva > 0) item.price_htva *= -1;
      if (item.current_unity_price_htva > 0) item.current_unity_price_htva *= -1;
    }

    const formValues = getValues();

    formValues.product_lines_attributes[lineIndex] = {
      id: '',
      product_id: item.id,
      quantity: 1,
      product_attributes: {
        tax_category_code: noTva ? '00' : item.tax_category_code,
        current_unity_price_htva: item.price_htva,
        name: item.name,
      },
    };
    reset(formValues);
  };

  const onChangeInputProduct = () => {
    if (product_lines_attributes[lineIndex].product_id) {
      setValue(`product_lines_attributes.${lineIndex}.product_id`, undefined);
      setValue(`product_lines_attributes.${lineIndex}.id`, undefined);
    }
  };

  const productLine = (product_lines_attributes && product_lines_attributes[lineIndex]) || {};
  const product = productLine.product_attributes || {};
  const tvaCode = product.tax_category_code || field.tax_category_code;
  const tvaObj = tvaCode && constants.tax_categories.find((it) => it.value === tvaCode);
  const tvaRate = noTva ? 0 : (tvaCode && tvaObj?.vat_value) || 0;

  register(`product_lines_attributes.${lineIndex}.total_htva`);
  register(`product_lines_attributes.${lineIndex}.total_tvac`);
  register(`product_lines_attributes.${lineIndex}.id`);
  register(`product_lines_attributes.${lineIndex}.product_id`);

  useEffect(() => {
    setValue(
      `product_lines_attributes.${lineIndex}.total_htva`,
      productLine.quantity *
        product.current_unity_price_htva *
        (1 - (productLine.discount_rate || 0) / 100),
    );
    setValue(
      `product_lines_attributes.${lineIndex}.total_tvac`,
      productLine.quantity *
        product.current_unity_price_htva *
        (1 - (productLine.discount_rate || 0) / 100) *
        (1 + +tvaRate / 100),
    );
    setValue('productChanged', productChanged + 1);
  }, [productLine.quantity, productLine.discount_rate, product.current_unity_price_htva, tvaRate]);

  const removeProduct = () => {
    removeProductLine(lineIndex);
  };
  const productItem = ({ item }: { item: Product }) => (
    <div className={styles('product-item')}>
      <span>{item.name}</span>
      <span>
        {t(i18nKeys.EXCL_VAT_PRICE)}:{' '}
        {formatAmount(item.price_htva, ',', '.', 2, currencySymbol(currency, company))}
      </span>
    </div>
  );
  return (
    <tr className={styles('invoice-product-line')}>
      <td>
        <Controller
          control={control}
          rules={{ required: true }}
          name={`product_lines_attributes.${lineIndex}.product_attributes.name`}
          defaultValue=""
          render={() => (
            <CustomSelect
              CustomInput={Input}
              defaultValue={
                initialValues.product_lines_attributes?.[lineIndex]?.product_attributes.name
              }
              load={() => {
                productActions.search({
                  name: watch(`product_lines_attributes.${lineIndex}.product_attributes.name`),
                });
              }}
              size="small"
              className={styles('invoice-select-input-wrapper')}
              customInputClassname={styles('invoice-select-input')}
              selectClassName={styles('invoice-select')}
              valueClassName={styles('value')}
              errorClassName={styles('invoice-select-error')}
              itemRendering={productItem}
              items={listProducts}
              name={`product_lines_attributes.${lineIndex}.product_attributes.name`}
              placeholder={t(i18nKeys.INVOICING.CREATE.CHOOSE_PRODUCT)}
              onSelectItem={onSelectProduct}
              onInputChange={onChangeInputProduct}
              noMargin
              noArrow
              noBorder
            />
          )}
        />
      </td>
      <td>
        <Input
          defaultValue={field.quantity || 1}
          className={styles('invoice-input')}
          inputClassName={styles('value')}
          errorClassName={styles('error')}
          errorMessage={
            errors.product_lines_attributes && errors.product_lines_attributes[lineIndex]?.quantity
          }
          noMargin
          noBorder
          register={register(`product_lines_attributes.${lineIndex}.quantity`, {
            required: true,
            min: 0,
          })}
          type="number"
          placeholder="0"
          min="0"
          step="0.01"
        />
      </td>
      <td>
        <Input
          defaultValue={field.product_attributes?.current_unity_price_htva}
          className={styles('invoice-input')}
          inputClassName={styles('value')}
          errorClassName={styles('error')}
          errorMessage={
            errors.product_lines_attributes &&
            errors.product_lines_attributes[lineIndex]?.product_attributes?.current_unity_price_htva
          }
          noMargin
          noBorder
          register={register(
            `product_lines_attributes.${lineIndex}.product_attributes.current_unity_price_htva`,
            { required: true },
          )}
          placeholder="0.00"
          type="number"
          step="0.01"
        />
      </td>
      <td>
        <Input
          className={styles('invoice-input')}
          inputClassName={styles('value')}
          defaultValue={field.discount_rate}
          register={register(`product_lines_attributes.${lineIndex}.discount_rate`, {
            min: 0,
            max: 100,
          })}
          noMargin
          noBorder
          type="number"
          min="0"
          max="100"
          placeholder="0%"
          step="0.01"
        />
      </td>
      <td className={styles('amount')}>
        <div className={styles('htva-price')}>
          <Amount
            value={
              product &&
              productLine.quantity *
                product.current_unity_price_htva *
                (1 - (productLine.discount_rate || 0) / 100)
            }
            suffix={currency}
          />
        </div>
      </td>
      {/* here */}
      <td>
        {(productLine.product_id || noTva) && (
          <div className={styles('amount')}>
            <Percentage value={tvaRate} />
            {noTva && (
              <Tooltip icon={IconName.INFO}>
                {t(
                  debtor!.co_contractor
                    ? i18nKeys.FORM.INVOICING.VAT_COCONTRACTOR
                    : i18nKeys.FORM.INVOICING.VAT_INTRACOM,
                )}
              </Tooltip>
            )}
          </div>
        )}
        <div className={productLine.product_id || noTva ? 'invisible-input' : ''}>
          <Controller
            control={control}
            defaultValue=""
            rules={{ required: true }}
            name={`product_lines_attributes.${lineIndex}.product_attributes.tax_category_code`}
            render={() => (
              <CustomSelect
                size="small"
                valueClassName={styles('select-value')}
                filter
                className={styles('tva', 'invoice-select')}
                name={`product_lines_attributes.${lineIndex}.product_attributes.tax_category_code`}
                keyText="description"
                keyValue="value"
                noBorder
                noMargin
                placeholder={t(i18nKeys.SELECT)}
                items={constants.tax_categories}
                errorClassName={styles('invoice-select-error')}
              />
            )}
          />
          {noTva && (
            <div className={styles('special-tva-info')}>
              {t(i18nKeys.INVOICING.CREATE.VAT_APPLIED_0)}
            </div>
          )}
        </div>
      </td>
      <td className={styles('amount')}>
        <div className={styles('vat-total')}>
          <Amount
            value={
              product &&
              productLine.quantity *
                product.current_unity_price_htva *
                (1 - (productLine.discount_rate || 0) / 100) *
                (1 + +tvaRate / 100)
            }
            suffix={currency}
          />
          <div className={styles('remove')} onClick={removeProduct}>
            <Icon name={IconName.TRASH_SIMPLE} size="16px" />
          </div>
        </div>
      </td>
    </tr>
  );
}
