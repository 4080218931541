import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { SendingMedia } from 'types/sending-media';

import { Badge } from '@mantine/core';

const mediumToColor: Record<SendingMedia, string> = {
  email: 'grape',
  post: 'yellow',
  registered_post: 'orange',
  missing_information: 'gray',
};

interface SentMediumBadgeProps {
  sentMedium: SendingMedia;
}

export const SentMediumBadge = ({ sentMedium }: SentMediumBadgeProps) => {
  const { t } = useTranslation();

  return (
    <Badge size="xs" color={mediumToColor[sentMedium]} variant="light">
      {t(i18nKeys.REMINDER_LIST.BADGE[sentMedium.toUpperCase()])}
    </Badge>
  );
};
