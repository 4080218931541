import { i18nKeys, useTranslation } from 'locales';
import { mergeWith, subtract } from 'lodash-es';
import { clone } from 'remeda';
import {
  AgedBalanceCompact,
  useLoadActionableInvoices,
  useLoadAgedBalanceCompact,
} from 'shared/hooks';
import { useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils';

import { BaseTicker } from '../BaseTicker';
import { Format } from '../TickersBanner';

function getAmountActionable(actionableInvoices: number, formatter: Intl.NumberFormat) {
  return formatter.format(actionableInvoices);
}

function getPercentageActionable(
  agedBalanceCompact: AgedBalanceCompact,
  actionableInvoices: number,
) {
  const netAgedBalance = mergeWith(
    clone(agedBalanceCompact.debits),
    agedBalanceCompact.credits,
    subtract,
  );

  const totalReceivable =
    netAgedBalance.notDue +
    netAgedBalance['0'] +
    netAgedBalance['30'] +
    netAgedBalance['60'] +
    netAgedBalance['90'];

  const percentage = (actionableInvoices / totalReceivable) * 100;
  return percentage.toFixed(2);
}

interface ActionableTickerProps {
  format: Format;
}

export const ActionableTicker = ({ format }: ActionableTickerProps) => {
  const { t } = useTranslation();
  const { agedBalanceCompact, isAgedBalanceCompactLoading } = useLoadAgedBalanceCompact();
  const { actionableInvoices, isActionableInvoicesLoading } = useLoadActionableInvoices();
  const formatter = useSafeLocalizedCompactCurrencyFormatter(true);

  if (
    isAgedBalanceCompactLoading ||
    agedBalanceCompact == null ||
    isActionableInvoicesLoading ||
    actionableInvoices == null
  ) {
    return <BaseTicker label={t(i18nKeys.ANALYTICS.TICKERS.ACTIONABLE.TITLE)} />;
  }

  const value =
    format === 'percentage'
      ? getPercentageActionable(agedBalanceCompact, actionableInvoices)
      : getAmountActionable(actionableInvoices, formatter);

  return (
    <BaseTicker
      label={t(i18nKeys.ANALYTICS.TICKERS.ACTIONABLE.TITLE)}
      value={value}
      suffix={format === 'percentage' ? '%' : '€'}
      url="/invoices/to-handle"
    />
  );
};
