import { useEffect, useState } from 'react';
import AnimateNumber from 'animated-number-react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { isNullish } from 'remeda';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { Button } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { currencySymbol, formatAmount } from 'shared/utils';
import { useSearchInvoices } from 'shared/utils/hooks';
import { OnClickFunction } from 'types/html-type';
import { CreditNote } from 'types/store/credit-note-state';
import { LightInvoice } from 'types/store/invoice-state';

import { LinkInvoiceFormFields } from './LinkInvoiceForm.types';
import { getCurrentValues, getLinkInvoiceFormFieldLine } from './LinkInvoiceForm.utils';
import { LinkInvoiceFormTable } from './LinkInvoiceFormTable';

import styleIdentifiers from './linkInvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: SubmitHandler<LinkInvoiceFormFields>;
  creditNote: CreditNote;
  reload?: OnClickFunction;
};

export const LinkInvoiceForm = ({ onSubmit, creditNote, reload }: Props) => {
  const { t, currentLang } = useTranslation();
  const { getInvoices } = useSearchInvoices();

  const [invoices, setInvoices] = useState<Array<LightInvoice>>([]);

  useEffect(() => {
    if (isNullish(creditNote)) return;

    const {
      currency,
      debtor_attributes: { id: debtorId },
      matched_payments_attributes: matchedPayments,
    } = creditNote;

    setInvoices(matchedPayments.map((e) => e.invoice_attributes));

    getInvoices(
      {
        currency,
        debtor_id: debtorId,
        exclude: invoices.map((e) => e.id),
      },
      ({ data }) => {
        setInvoices((currentInvoices) => [
          ...currentInvoices,
          ...data
            .filter((e) => !matchedPayments.some((el) => el.invoice_attributes.id === e.id))
            .map((e) => treatInvoice(e)),
        ]);
      },
    );
  }, [creditNote]);

  const form = useForm<LinkInvoiceFormFields>({
    shouldUnregister: true,
    defaultValues: {
      invoices_to_link: (creditNote?.matched_payments_attributes ?? []).map((e) =>
        getLinkInvoiceFormFieldLine(e.invoice_attributes, creditNote?.matched_payments_attributes),
      ),
    },
  });

  const { handleSubmit, watch } = form;

  if (creditNote == null) return null;

  const {
    reference,
    issue_date,
    currency,
    total_tvac: totalTvac,
    localized_money_object,
    debtor_attributes: { full_name },
  } = creditNote;

  const { currentTotal, maxTotal, canSubmit } = getCurrentValues(
    watch('invoices_to_link'),
    localized_money_object,
  );

  return (
    <FormProvider {...form}>
      <article className={styles('link-invoices-form')}>
        <header className={styles('header')}>
          <div className={styles('title')}>{t(i18nKeys.CREDIT_NOTE)}</div>
          <div className={styles('info')}>
            <div>
              <span>{t(i18nKeys.FORM.REFERENCE)}:</span>
              <span className={styles('value')}>{reference}</span>
            </div>
            <div>
              <span>{t(i18nKeys.TOTAL_VAT_PRICE)}:</span>
              <Amount
                localizedValue={totalTvac}
                value={maxTotal}
                suffix={currency}
                className={styles('value', 'bigger')}
              />
            </div>
            <div>
              <span>{t(i18nKeys.DUE_DATE)}: </span>
              <DateItem
                date={issue_date}
                lg={currentLang}
                format="DD/MM/YY"
                className={styles('value', 'blue', 'bigger')}
              />
            </div>
            <div>
              <span>{t(i18nKeys.DEBTOR_NAME)}:</span>
              <span className={styles('value')}>{full_name}</span>
            </div>
          </div>
        </header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LinkInvoiceFormTable
            className={styles('invoice-table')}
            invoices={invoices}
            creditNote={creditNote}
            reload={reload}
          />
          <div className={styles('resume', !canSubmit && 'error')}>
            <span>{t(i18nKeys.INVOICE.DUE_AMOUNT)} : </span>
            <AnimateNumber
              duration={400}
              value={currentTotal}
              className={styles('total')}
              formatValue={(value) =>
                `${formatAmount(value, ',', '.', 2, currencySymbol(currency, undefined))}`
              }
            />
            {' / '}
            {formatAmount(maxTotal, ',', '.', 2, currencySymbol(currency, undefined))}
          </div>
          <div className={styles('button-wrapper')}>
            <Button noShadow label={t(i18nKeys.SUBMIT)} type="submit" disabled={!canSubmit} />
          </div>
        </form>
      </article>
    </FormProvider>
  );
};
