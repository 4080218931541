import { useReducer } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { Reducer } from 'redux';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { CustomSelect, Input } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { currencySymbol } from 'shared/utils';
import { reducerState, removeAttributes } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, showDialog } from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import styleIdentifiers from './ClientDetailForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientDetailFormProps = {
  data: Debtor;
  setData: Function;
};

type State = Reducer<
  {
    recoveryPlans: any[];
    invoiceTemplate: any[];
    cnTemplate: any[];
  },
  any
>;

export const ClientDetailForm = ({ data, setData }: ClientDetailFormProps) => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const [{ recoveryPlans, cnTemplate, invoiceTemplate }, _setState] = useReducer<State>(
    reducerState,
    {
      recoveryPlans: [],
      invoiceTemplate: [],
      cnTemplate: [],
    },
  );
  const form = useFormContext();

  const setState = (field: 'recoveryPlans' | 'cnTemplate' | 'invoiceTemplate') => (value) => {
    _setState({
      [field]: value,
    });
  };

  const getRecoveryPlans = () => {
    settingsActions.recoveryPlanList({
      callback: ({ data: callbackData }) => {
        setState('recoveryPlans')(
          callbackData.map((recoveryPlan) => treatRecoveryPlan(recoveryPlan)),
        );
      },
    });
  };

  const getTemplates = (step_type) => () => {
    settingsActions.templateList({
      data: {
        step_type,
      },
      callback: ({ data: callbackData }) => {
        setState(step_type === 'invoice' ? 'invoiceTemplate' : 'cnTemplate')(
          callbackData.map((item) => removeAttributes(item)),
        );
      },
    });
  };

  const updateDefaultRecoveryPlan = (plan_id, steps_switch?) => {
    dialogHide(DialogShowId.CUSTOM);

    setData({
      recovery_plan_id: plan_id === 'default' ? null : plan_id,
      steps_switch,
    });
  };

  const setDefaultRecoveryPlan = (plan_id, moveInvoices) => {
    if (moveInvoices) {
      MoveRecoveryPlanInvoices({
        title: t(i18nKeys.PLAN.INVOICES),
        newPlanId: plan_id,
        oldPlanId: data.recovery_plan ? data.recovery_plan.id : company.recovery_plan?.id,
        debtor_id: data.id,
        onSubmit: (steps_switch) => {
          updateDefaultRecoveryPlan(plan_id, steps_switch);
        },
      });
    } else {
      updateDefaultRecoveryPlan(plan_id);
    }
  };

  const askMoveInvoicesWithPlan = (plan_id) => {
    if (plan_id) {
      if (data.has_invoices_to_switch) {
        showDialog({
          id: DialogShowId.CONFIRM,
          title: t(i18nKeys.ATTENTION),
          children: (
            <AYSModal
              text={t(i18nKeys.CLIENT.MOVE_INVOICES_IN_PLAN)}
              cancelButtonText={t(i18nKeys.NO)}
              confirmButtonText={t(i18nKeys.YES)}
              onCancel={() => {
                setDefaultRecoveryPlan(plan_id, false);
              }}
              onConfirm={() => {
                setDefaultRecoveryPlan(plan_id, true);
              }}
            />
          ),
        });
      } else {
        setDefaultRecoveryPlan(plan_id, false);
      }
    }
  };

  return (
    <div className={styles('client-detail-form', 'wrap')}>
      <Controller
        name="send_to_all_emails"
        defaultValue=""
        render={() => (
          <CustomSelect
            selectClassName={styles('input')}
            keyText="description"
            keyValue="value"
            withBorder
            name="send_to_all_emails"
            items={[
              { description: t(i18nKeys.DEFAULT), value: 'default' },
              {
                description: t(i18nKeys.YES),
                value: 'true',
              },
              {
                description: t(i18nKeys.NO),
                value: 'false',
              },
            ]}
            label={t(i18nKeys.FORM.PREFERENCES.SEND_TO_ALL_EMAILS)}
          />
        )}
      />
      <Controller
        name="allow_payment_plans"
        defaultValue=""
        render={() => (
          <CustomSelect
            info={!company.package.can_use_advanced_payment_plans ? t(i18nKeys.NOT_INCLUDED) : ''}
            notAllowed={!company.package.can_use_advanced_payment_plans}
            noArrow={!company.package.can_use_advanced_payment_plans}
            selectClassName={styles('input')}
            keyText="description"
            keyValue="value"
            withBorder
            name="allow_payment_plans"
            items={[
              { description: t(i18nKeys.DEFAULT), value: 'default' },
              {
                description: t(i18nKeys.YES),
                value: 'true',
              },
              {
                description: t(i18nKeys.NO),
                value: 'false',
              },
            ]}
            label={t(i18nKeys.FORM.PREFERENCES.ALLOW_PAYMENT_PLANS_DEBTOR)}
          />
        )}
      />
      <Controller
        name="recovery_plan_id"
        defaultValue=""
        render={() => (
          <CustomSelect
            selectClassName={styles('input')}
            keyText="name"
            keyValue="id"
            onValueChanged={askMoveInvoicesWithPlan}
            withBorder
            name="recovery_plan_id"
            load={getRecoveryPlans}
            items={[{ name: t(i18nKeys.DEFAULT), id: 'default' }, ...recoveryPlans]}
            label={t(i18nKeys.RECOVERY_PLAN)}
          />
        )}
      />
      <Controller
        name="default_invoice_template_id"
        defaultValue=""
        render={() => (
          <CustomSelect
            selectClassName={styles('input')}
            keyText="name"
            keyValue="id"
            withBorder
            name="default_invoice_template_id"
            load={getTemplates('invoice')}
            items={[{ name: t(i18nKeys.DEFAULT), id: 'default' }, ...invoiceTemplate]}
            label={t(i18nKeys.DEFAULT_INVOICE_TEMPLATE)}
          />
        )}
      />
      <Controller
        name="default_credit_note_template_id"
        defaultValue=""
        render={() => (
          <CustomSelect
            selectClassName={styles('input')}
            keyText="name"
            keyValue="id"
            withBorder
            name="default_credit_note_template_id"
            load={getTemplates('credit_note')}
            items={[{ name: t(i18nKeys.DEFAULT), id: 'default' }, ...cnTemplate]}
            label={t(i18nKeys.DEFAULT_CN_TEMPLATE)}
          />
        )}
      />
      {/* </FormProvider> */}
      <Input
        register={form.register('days_between_reminder')}
        label={t(i18nKeys.CLIENT.DETAIL.MIN_DAYS_BETWEEN_REMINDER)}
        type="number"
        withBorder
        min="0"
      />

      <Input
        register={form.register('minimum_saldo_sending')}
        label={t(i18nKeys.FORM.PREFERENCES.DONT_SEND_REMINDER_TO_DEBTOR_UNDER, {
          text: currencySymbol(undefined, company),
        })}
        type="number"
        withBorder
      />
    </div>
  );
};
