import { useAtom } from 'jotai';
import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { useLoadFailedReminderPreview } from 'modules/Reminders/api/use-load-failed-reminder-preview';
import { isNonNullish } from 'remeda';
import HTML from 'shared/components/HTML/HTML';

import { Box, Button, Center, Group, Loader, Text } from '@mantine/core';

import { resendReminderModalAtom } from './state';

interface PreviewStepProps {}

export const PreviewStep = ({}: PreviewStepProps) => {
  const { t } = useTranslation();
  const [state, dispatch] = useAtom(resendReminderModalAtom);
  const { failedReminderPreview, isFailedReminderPreviewLoading } = useLoadFailedReminderPreview(
    {
      id: String(state.reminder?.id),
    },
    { enabled: isNonNullish(state.reminder) },
  );

  const onClickCancel = () => dispatch({ type: 'close' });
  const onClickIgnore = () => dispatch({ type: 'ignore' });
  const onClickNext = () => dispatch({ type: 'confirmPreview' });

  return (
    <Box>
      <Text mb="md">{t(i18nKeys.FAILED_REMINDERS.MODALS.PREVIEW.DESCRIPTION)}</Text>
      <Center>
        <Box
          w="80%"
          p="md"
          style={(theme) => ({
            border: `1px solid ${theme.colors.gray[2]}`,
            borderRadius: '8px',
            overflowY: 'auto',
          })}
        >
          {isFailedReminderPreviewLoading ? (
            <Center h="800px">
              <Loader />
            </Center>
          ) : (
            <HTML html={failedReminderPreview?.emailPreview} />
          )}
        </Box>
      </Center>

      <Group p="md" bg="white" justify="flex-end" gap="md" w="100%" pos="sticky" bottom={0}>
        <Button color="orange" variant="subtle" onClick={onClickCancel}>
          {t(i18nKeys.FAILED_REMINDERS.MODALS.CANCEL)}
        </Button>
        <Button color="gray" variant="light" onClick={onClickIgnore}>
          {t(i18nKeys.FAILED_REMINDERS.MODALS.IGNORE_REMINDER)}
        </Button>
        <Button onClick={onClickNext}>{t(i18nKeys.FAILED_REMINDERS.MODALS.CONTINUE)}</Button>
      </Group>
    </Box>
  );
};

PreviewStep.title = 'Preview'; // @translate
