import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useAxiosInstance } from './utils';

const InvoiceLifeCycleSchema = z.object({
  nodes: z.array(z.object({ id: z.string() })),
  links: z.array(z.object({ source: z.string(), target: z.string(), value: z.number() })),
});

export type InvoiceLifeCycle = z.infer<typeof InvoiceLifeCycleSchema>;

export async function loadInvoiceLifeCycleFn(axiosInstance: Promise<AxiosInstance>) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/analytics/invoice_lifecycles');
  return InvoiceLifeCycleSchema.parse(data);
}

export function useLoadInvoiceLifeCycle() {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<InvoiceLifeCycle, ApiError>({
    queryKey: ['invoiceLifeCycle'],
    queryFn: () => loadInvoiceLifeCycleFn(axiosInstance),
  });

  return addResourceNameToQueryResult<InvoiceLifeCycle, ApiError, 'invoiceLifeCycle'>(
    'invoiceLifeCycle',
    queryResult,
  );
}
