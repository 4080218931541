import { useAtom } from 'jotai';
import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { isNonNullish } from 'remeda';

import { Modal } from '@mantine/core';

import { IgnoreStep } from './IgnoreStep';
import { InputStep } from './InputStep';
import { PreviewStep } from './PreviewStep';
import { resendReminderModalAtom, ResendReminderModalState } from './state';

const stepToComponent: Record<
  ResendReminderModalState['modalStep'],
  { StepComponent: React.FunctionComponent; titleKey: string }
> = {
  preview: { StepComponent: PreviewStep, titleKey: 'preview' },
  input: { StepComponent: InputStep, titleKey: 'resend' },
  confirmIgnore: { StepComponent: IgnoreStep, titleKey: 'ignore' },
};

interface ResendReminderModalProps {}

export const ResendReminderModal = ({}: ResendReminderModalProps) => {
  const { t } = useTranslation();
  const [state, dispatch] = useAtom(resendReminderModalAtom);
  const { StepComponent, titleKey } = stepToComponent[state.modalStep];

  return (
    <Modal
      title={t(i18nKeys.FAILED_REMINDERS.MODALS[titleKey.toUpperCase()].TITLE)}
      opened={isNonNullish(state.reminder)}
      onClose={() => dispatch({ type: 'close' })}
      size="60vw"
      centered
      styles={{ body: { paddingBottom: 0 } }}
    >
      <StepComponent />
    </Modal>
  );
};
