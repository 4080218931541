import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, TranslateFunction, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import CustomTable from 'shared/components/CustomTable';
import ListResModal from 'shared/components/DeleteModal';
import { IconName } from 'shared/components/Icon';
import { useProfile } from 'shared/hooks';
import {
  SimilarClient,
  SimilarClientsSearchParams,
  useLoadSimilarClients,
} from 'shared/hooks/use-load-similar-clients';
import { Button } from 'shared/io';
import { clientActions } from 'store/client/client.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import DuplicatesClientListItem from './DuplicatesClientListItem';

import styleIdentifiers from './DuplicatesClients.module.scss';

const styles = classNames.bind(styleIdentifiers);

const EnhancedTable = CustomTable(DuplicatesClientListItem);

const CLIENTS_TABLE_HEADERS = (t: TranslateFunction) => [
  {
    title: `${t(i18nKeys.CONTACT)} 1`,
    width: 160,
  },
  {
    title: `${t(i18nKeys.CONTACT)} 2`,
    width: 140,
  },
  {
    title: t(i18nKeys.INVOICING.ACTION),
    width: 120,
  },
];

export const DuplicatesClients = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const profile = useProfile();

  const [params, setParams] = useState<SimilarClientsSearchParams>({
    pageLimit: profile.preferences.itemsPerPage,
    page: 1,
  });

  const [{ selectAll: isAllSelected, selectedItems }, setSelection] = useState({
    selectAll: false,
    selectedItems: [] as SimilarClient[],
  });

  const { similarClients, isSimilarClientsLoading, refetchSimilarClients } =
    useLoadSimilarClients(params);

  useEffect(() => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick<SimilarClientsSearchParams>(
        Object.fromEntries(new URLSearchParams(location.search)),
        ['page', 'page_limit'],
      ),
    }));
  }, []);

  useEffect(() => {
    if (similarClients && similarClients.data.length === 0) {
      history.push('/clients');
    }
  }, [similarClients]);

  const handleFilters = (data: SimilarClientsSearchParams) => {
    const filters = {
      ...pick(params, ['page_limit', 'page']),
      ...data,
    };
    setParams(filters);
    return filters;
  };

  const onSortChange = (newParams) => {
    setParams((currentParams) => ({
      ...currentParams,
      ...pick(newParams, ['page_limit', 'page']),
    }));
  };

  const orderField = (field: string) => field;

  const onCheckBoxChange = (newSelectedItems: SimilarClient[]) => {
    setSelection((currentState) => ({
      ...currentState,
      selectedItems: newSelectedItems,
    }));
  };

  const onSelectAll = (isSelected: boolean) => {
    setSelection((state) => ({
      ...state,
      isSelectAllActive: isSelected,
    }));
  };

  const ignoreDuplicates = (action) => {
    const data = {} as any;
    data.debtor_ids = isAllSelected ? null : selectedItems?.map(({ id }) => id);
    action(() => {
      clientActions.batchAction({
        data: {
          ...data,
          action_type: 'ignore_duplicates',
        },
        callback: (response) => {
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.RESULT),
            children: <ListResModal data={response} />,
          });
          setSelection((currentState) => ({
            ...currentState,
            selectedItems: [],
            selectAll: false,
          }));
          refetchSimilarClients();
        },
      });
    });
  };

  const aysModal = (action_type) => (onSubmit) => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: <AYSModal text={t(action_type)} onConfirm={() => onSubmit({})} />,
    });
  };

  return (
    <div className={styles('client-list-to-confirm')}>
      <EnhancedTable
        notSortable
        title={t(i18nKeys.DUPLICATES)}
        orderField={orderField}
        loading={isSimilarClientsLoading}
        loaded={!isSimilarClientsLoading}
        pagination={similarClients?.metadata.pagination}
        headers={CLIENTS_TABLE_HEADERS(t)}
        handleFilters={handleFilters}
        onSortChange={onSortChange}
        items={similarClients?.data || []}
        itemProps={{ checkbox: true, refetch: refetchSimilarClients }}
        onCheckBoxChange={onCheckBoxChange}
        setSelectAll={onSelectAll}
        actions={
          (isAllSelected || !!selectedItems.length) && (
            <Button
              className={styles('action-button')}
              noMargin
              label={t(i18nKeys.CLIENT.MERGE.IGNORE)}
              onClick={() => ignoreDuplicates(aysModal(i18nKeys.AYS.IGNORE_DUPLICATES))}
              iconRight={IconName.SIMPLE_REMOVE}
            />
          )
        }
      />
    </div>
  );
};
