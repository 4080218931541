import { i18nKeys, useTranslation } from 'locales/index';

import { Box, Tooltip, useMantineTheme } from '@mantine/core';
import { IconCircleArrowUpFilled } from '@tabler/icons-react';

export const UpsellIcon = () => {
  const theme = useMantineTheme();
  const { t } = useTranslation();
  return (
    <Tooltip label={t(i18nKeys.NAV.UPSELL)} position="top" withArrow arrowSize={8} radius={6}>
      <Box
        color="blue"
        h={20}
        style={{ pointerEvents: 'auto', filter: 'opacity(1)', cursor: 'default' }}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
        variant="transparent"
      >
        <IconCircleArrowUpFilled stroke={1.5} size={20} color={theme.colors.blue[4]} />
      </Box>
    </Tooltip>
  );
};
