import { AxiosInstance } from 'axios';
import { CurrencyCodeSchema } from 'types/currency';
import { LegacyPaginationSchema } from 'types/pagination';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { addResourceNameToQueryResult, ApiError, useLegacyAxiosInstance } from './utils';

const SimilarClientsSearchParamsSchema = z.object({
  page: z.coerce.number().optional(),
  pageLimit: z.coerce.number().optional(),
});

const SimilarClientsMetadataSchema = z.object({
  total: z.number(),
  pagination: LegacyPaginationSchema,
});

const SimilarClientSchema = z.object({
  id: z.string(),
  attributes: z.object({
    full_name: z.string(),
    emails: z.array(z.string()).optional(),
    currency: CurrencyCodeSchema,
  }),
});

const SimilarClientsSchema = z.object({
  data: z.array(
    z.object({
      id: z.string(),
      debtors: z.array(SimilarClientSchema).length(2),
    }),
  ),
  metadata: SimilarClientsMetadataSchema,
});

export type SimilarClients = z.infer<typeof SimilarClientsSchema>;
export type SimilarClient = z.infer<typeof SimilarClientSchema>;
export type SimilarClientsSearchParams = z.infer<typeof SimilarClientsSearchParamsSchema>;

export async function loadSimilarClientsFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/similar_debtors', { params: queryKey[1] });

  return SimilarClientsSchema.parse(data);
}

export function useLoadSimilarClients(params?: SimilarClientsSearchParams) {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<SimilarClients, ApiError>({
    queryKey: ['similar-clients', params] as const,
    queryFn: (context) => loadSimilarClientsFn(axiosInstance, context),
  });

  return addResourceNameToQueryResult<SimilarClients, ApiError, 'similarClients'>(
    'similarClients',
    queryResult,
  );
}
