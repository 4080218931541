import { useSelector } from 'react-redux';
import { CompanyConfiguration } from 'shared/hooks';
import { Debtor } from 'types/store/client-state';
import { StoreState } from 'types/storeTypes';

export const getCgv = (company?: CompanyConfiguration, debtor?: Debtor) =>
  company?.terms_and_conditions_attributes.find(
    (e) => Number(e.id) === Number(debtor?.terms_and_conditions_id),
  ) || company?.terms_and_conditions_attributes?.find((e) => e.default === true);

export const useGetConstants = () => useSelector((state: StoreState) => state.app.constants);

export const useGetDialogs = () => useSelector((state: StoreState) => state.view.dialogs);

export const useGetViewsByResource = () =>
  useSelector((_state: StoreState) => _state.app.viewsByResource);

export const useGetClientState = () => useSelector((state: StoreState) => state.client);

export const useGetInvoiceState = () => useSelector((state: StoreState) => state.invoice);
