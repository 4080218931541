import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider } from 'react-hook-form';
import BankAccount from 'shared/components/BankAccount';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { AmountFields, CustomSelect, DateSelector, Input } from 'shared/io';
import { currencySymbol } from 'shared/utils';
import { useFilterForm } from 'shared/utils/hooks';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './paymentsFiltersForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues: any;
  filterObject: any;
};

export default function PaymentsFiltersForm({ initialValues, onSubmit, filterObject }: Props) {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const { submit, resetForm, form, formData } = useFilterForm({
    onSubmit,
    initialValues,
    fieldToRegister: [],
  });
  const { setValue, register } = form;

  const bank_account_ids: number[] = [];
  const bankAccount = ({ item }) => (
    <div className={styles('bank-account', bank_account_ids?.indexOf(item.id) > -1 && 'selected')}>
      <BankAccount value={item.iban} image={item.bic} small />
      {formData.bank_account_ids?.indexOf(item.id) > -1 && (
        <Icon name={IconName.CHECK} className={styles('check-icon')} size="20px" />
      )}
    </div>
  );
  useEffect(() => {
    if (filterObject) {
      filterObject.reset = () => {
        resetForm();
      };
    }
  }, [filterObject]);

  useEffect(() => {
    submit?.();
  }, [formData.bank_account_ids]);
  return (
    <FormProvider {...form}>
      <form
        className={styles('PaymentsFiltersForm')}
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <div className={styles('container-fields')}>
          <div className={styles('fields')}>
            <DateSelector
              className={styles('input', 'date-selector')}
              name="start_date"
              endName="end_date"
              placeholder={t(i18nKeys.OF)}
              endPlaceholder={t(i18nKeys.FORM.TO)}
              withBorder
              shadow
              noMinDate
              label={t(i18nKeys.DATE)}
              handleChange={(value) => {
                setValue('start_date', value);
                submit();
              }}
              handleEndChange={(value) => {
                setValue('end_date', value);
                submit();
              }}
            />
            <AmountFields
              onChange={submit}
              className={styles('spacing')}
              operatorName="operator"
              name="amount"
              label={t(i18nKeys.AMOUNT_CURRENCY, {
                text: currencySymbol(undefined, company),
              })}
            />

            <div className={styles('medium', 'spacing')}>
              <Input
                register={register('communication')}
                className={styles('spacing')}
                noMargin
                shadow
                withBorder
                label={t(i18nKeys.COMMUNICATION)}
                onValueChanged={submit}
              />
            </div>

            <div className={styles('large', 'spacing')}>
              <Controller
                defaultValue=""
                name="bank_account_ids"
                render={() => (
                  <CustomSelect
                    name="bank_account_ids"
                    onChange={(value) => value}
                    className={styles('select-wrapper')}
                    selectClassName={styles('bank-account-select', 'spacing')}
                    valueClassName={styles('bank-account-select-value')}
                    label={t(i18nKeys.COMMON.BANK_ACCOUNT)}
                    noMargin
                    removeAll={t(i18nKeys.FORM.SELECT_ALL)}
                    withBorder
                    multiple
                    shadow
                    noTags
                    valueRendering={(item) =>
                      item.value &&
                      (item.value.length === 0
                        ? ''
                        : item.value.length > 1
                          ? t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.SEVERAL_ACCOUNT_SELECTED)
                          : bankAccount(item.value[0]))
                    }
                    itemRendering={bankAccount}
                    keyText="iban"
                    keyValue="id"
                    items={company.bank_accounts_attributes || []}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles('fields')}>
            <div className={styles('medium', 'spacing')}>
              <Input
                register={register('counterparty_name')}
                className={styles('spacing')}
                noMargin
                shadow
                withBorder
                label={t(i18nKeys.FORM.HISTORY.SENDER)}
                onValueChanged={submit}
              />
            </div>
            <div className={styles('medium', 'spacing')}>
              <Controller
                defaultValue=""
                name="ignored"
                render={() => (
                  <CustomSelect
                    name="ignored"
                    keyText="description"
                    keyValue="value"
                    noMargin
                    withBorder
                    noBorder
                    shadow
                    items={[
                      {
                        description: t(i18nKeys.CLIENT.FILTER.ALL),
                        value: 'all',
                      },
                      {
                        description: t(i18nKeys.BANK_ACCOUNT.HISTORY.IGNORED),
                        value: 'ignored',
                      },
                      {
                        description: t(i18nKeys.BANK_ACCOUNT.HISTORY.UNIGNORED),
                        value: 'unignored',
                      },
                    ]}
                    label={t(i18nKeys.FORM.TYPE)}
                    onValueChanged={submit}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
