import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { set } from 'date-fns';
import dayjs from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { RouteChildrenProps } from 'react-router';
import { isDefined } from 'remeda';
import {
  ChiftConfigurationVariables,
  ChiftConfigurationVariablesSchema,
  useLoadChiftConfigurationLink,
  useLoadCompanyConfiguration,
  useUpdateChiftConfiguration,
} from 'shared/hooks';

import { Button, Center, Group, Stack, Switch } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Chift = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();
  const { company, refetchCompany } = useLoadCompanyConfiguration();
  const { updateChiftConfiguration } = useUpdateChiftConfiguration();
  const { isActive, canActivate, isConnected } = getIntegrationRights(Integration.CHIFT, company);

  const [isImportInvoicesChecked, setIsImportInvoicesChecked] = useState(
    company?.tp_accounting.use_import,
  );
  const [shouldFetchChiftLink, setShouldFetchChiftLink] = useState(false);

  const { chiftConfigurationLink, isChiftConfigurationLinkFetching } =
    useLoadChiftConfigurationLink({
      enabled: shouldFetchChiftLink,
    });

  useEffect(() => {
    if (chiftConfigurationLink?.link && shouldFetchChiftLink) {
      window.open(chiftConfigurationLink.link, '_blank');
      setShouldFetchChiftLink(false);
      refetchCompany();
    }
  }, [chiftConfigurationLink, shouldFetchChiftLink, refetchCompany]);

  useEffect(() => {
    if (!canActivate && !isActive) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, isActive, replace]);

  const form = useForm<ChiftConfigurationVariables>({
    initialValues: {
      tp_accounting_automatic_sync: company?.tp_accounting.automatic_sync || false,
      tp_accounting_issue_date_limit: company?.tp_accounting.issue_date_limit
        ? dayjs(company.tp_accounting.issue_date_limit).toDate()
        : null,
      fetch_pdf: company?.tp_accounting.additional_parameters?.fetch_pdf || false,
    },
    validate: {
      tp_accounting_issue_date_limit: (value, values) => {
        if (values.use_chift_import_integration && !value) {
          return t(i18nKeys.SETTINGS.INTEGRATIONS.CHIFT.ERROR_MISSING_DATE);
        }
        return null;
      },
    },
  });

  form.watch('use_chift_import_integration', ({ value }) => {
    if (isDefined(value)) setIsImportInvoicesChecked(value);
    if (!value) form.setFieldValue('tp_accounting_issue_date_limit', null);
  });

  const handleSubmit = (values: typeof form.values) => {
    form.setSubmitting(true);
    const data = ChiftConfigurationVariablesSchema.parse(values);
    updateChiftConfiguration(data, { onSuccess: () => form.setSubmitting(false) });
  };

  if (!company?.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  if (!isConnected) {
    return (
      <>
        <IntegrationHeader
          name={Integration.CHIFT}
          goBack={goBack}
          isActive={isActive}
          isConnected={isConnected}
        />
        <Center>
          <Button
            loading={isChiftConfigurationLinkFetching}
            onClick={() => setShouldFetchChiftLink(true)}
          >
            {t(i18nKeys.SETTINGS.INTEGRATIONS.CHIFT.CONNECT_CHIFT)}
          </Button>
        </Center>
      </>
    );
  }

  return (
    <article className={styles('chift')}>
      <IntegrationHeader
        name={Integration.CHIFT}
        goBack={goBack}
        isActive={isActive}
        isConnected={isConnected}
      />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack gap="lg">
          <Switch
            {...form.getInputProps('use_chift_import_integration', { type: 'checkbox' })}
            checked={isImportInvoicesChecked}
            disabled={form.submitting}
            label={t(i18nKeys.SETTINGS.INTEGRATIONS.CHIFT.IMPORT_INVOICES)}
          />
          {isImportInvoicesChecked && (
            <DatePickerInput
              ml="md"
              {...form.getInputProps('tp_accounting_issue_date_limit')}
              w="30%"
              required={isImportInvoicesChecked}
              disabled={form.submitting}
              placeholder={t(i18nKeys.SETTINGS.INTEGRATIONS.CHIFT.DATEPICKER_PLACEHOLDER)}
              label={t(i18nKeys.SETTINGS.INTEGRATIONS.CHIFT.IMPORT_INVOICES_FROM)}
            />
          )}
          <Switch
            {...form.getInputProps('tp_accounting_automatic_sync', { type: 'checkbox' })}
            label={t(i18nKeys.SETTINGS.INTEGRATIONS.CHIFT.AUTOMATIC_SYNC)}
            disabled={form.submitting}
          />
          <Switch
            {...form.getInputProps('fetch_pdf', { type: 'checkbox' })}
            label={t(i18nKeys.SETTINGS.INTEGRATIONS.CHIFT.FETCH_PDF)}
            disabled={form.submitting}
          />
          <Group justify="flex-end">
            <Button disabled={!form.isDirty()} type="submit" loading={form.submitting}>
              {t(i18nKeys.SAVE)}
            </Button>
          </Group>
        </Stack>
      </form>
    </article>
  );
};
