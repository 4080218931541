import { Currency } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { CurrencyCode } from 'types/currency';
import { ProductLine } from 'types/store/invoice-state';

import TableDetailsItem from './TableDetailsItem';

import styleIdentifiers from './tableDetails.module.scss';

const styles = classNames.bind(styleIdentifiers);

export type TableDetailsProps = {
  items: ProductLine[];
  suffix: CurrencyCode;
  noTva?: boolean;
};

export default function TableDetails({ items = [], ...rest }: TableDetailsProps) {
  const { t } = useTranslation();

  return (
    <div className={styles('TableDetails')}>
      <table>
        <thead>
          <tr>
            <th className={styles('th-item')}>{t(i18nKeys.INVOICE.PRODUCT)}</th>
            <th className={styles('th-qty')}> {t(i18nKeys.INVOICE.QTE)}</th>
            <th className={styles('th-price')}> {t(i18nKeys.PRICE)}</th>
            <th className={styles('th-disc')}>{t(i18nKeys.INVOICE.DISCOUNT)}</th>
            <th className={styles('th-tva-htva')}>{t(i18nKeys.TOTAL_NOT_VAT_PRICE)}</th>
            <th className={styles('th-tva')}>{t(i18nKeys.VAT)}</th>
            <th className={styles('th-total')}>{t(i18nKeys.TOTAL_VAT_PRICE)}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <TableDetailsItem key={item.id} {...rest} productLine={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
