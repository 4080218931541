import { chunk, join, map, pipe } from 'remeda';
import { TvaTypes } from 'shared/io/VatInput/VatInput.types';

import { extractNumber } from '.';

export const maskVat: {
  [key in TvaTypes]: string;
} = {
  [TvaTypes.BE]: 'BE ____ ___ ___',
  [TvaTypes.FR]: 'FR __ _________',
  [TvaTypes.NL]: 'NL _________ B__',
  [TvaTypes.LU]: 'LU ________',
};

// Value should be string but the input arg is typed as any in a few of the callsites
// Caused an issue when undefined was passed in
export function formatCommunication(value: unknown) {
  if (typeof value !== 'string') return '';

  const isFrenchCommunication = value.startsWith('RF');

  if (isFrenchCommunication) {
    // prettier-ignore
    return pipe(
      value,
      () => value.split(''),
      chunk(4),
      map(join('')),
      join(' '),
    );
  }

  const clearValue = extractNumber(value);

  let output = `+++${clearValue.slice(0, 3)}`;

  if (clearValue.length > 3) {
    output += `/${clearValue.slice(3, 7)}`;
  }

  if (clearValue.length > 7) {
    output += `/${clearValue.slice(7, 12)}`;
  }

  if (clearValue.length >= 12) {
    output += '+++';
  }

  return output;
}
