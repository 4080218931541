import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { first, isNullish } from 'remeda';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { ThirdParties, ThirdPartyType, useLoadThirdParties } from 'shared/hooks';
import { Button } from 'shared/io';
import { Invoice } from 'types/store/invoice-state';

import { Center, Loader as MantineLoader } from '@mantine/core';

import debtCollectorIcon from './debt-collector.svg';

import styleIdentifiers from './ChooseThirdPartyTypeForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

interface ThirdPartyCardProps {
  isDisabled: boolean;
  title: string;
  onClick: VoidFunction;
  isSelected: boolean;
  illustration: React.ReactNode;
  errorText?: string;
}

const ThirdPartyCard = ({
  isDisabled: _isDisabled,
  title,
  onClick,
  isSelected,
  illustration,
  errorText,
}: ThirdPartyCardProps) => {
  const isDisabled = _isDisabled || errorText != null;
  return (
    <div
      className={styles('card', isDisabled && 'disabled', isSelected && 'selected')}
      onClick={isDisabled ? () => {} : onClick}
    >
      <div className={styles('picture')}>{illustration}</div>
      <div className={styles('container-info')}>
        <div className={styles('title')}>{title}</div>
        {errorText != null && <div className={styles('error')}>{errorText}</div>}
      </div>
    </div>
  );
};

type Props = {
  onSubmit: (selectedThirdPartyType: ThirdPartyType, thirdParties: ThirdParties) => void;
  invoices: Array<Invoice>;
  inheritedThirdParties?: ThirdParties;
};

export function ChooseThirdPartyTypeForm({ onSubmit, invoices, inheritedThirdParties }: Props) {
  const { t } = useTranslation();
  const [selectedThirdPartyType, setSelectedThirdPartyType] = useState<ThirdPartyType>();

  /**
   * When this modal is triggered from the invoice detail page (ToHandleDetail.tsx), the third parties
   * for that invoice have already been loaded and passed to this component as a prop (inheritedThirdParties).
   * When it is triggered from the batch action menu, the third parties have not been loaded yet, so we
   * load the ones available for the set of invoices selected by the user.
   * */
  const isBatchAction = inheritedThirdParties == null;
  const { thirdParties: localThirdParties } = useLoadThirdParties({
    enabled: isBatchAction,
    ids: invoices.map((i) => i.id),
  });
  const thirdParties = isBatchAction ? localThirdParties : inheritedThirdParties;

  const isSingleInvoice = invoices.length === 1;
  const isOriginalInvoice = isSingleInvoice ? first(invoices)?.has_pdf : true;

  if (isNullish(thirdParties)) {
    return (
      <Center>
        <MantineLoader />
      </Center>
    );
  }

  return (
    <div className={styles('choose-third-party-form')}>
      <div className={styles('description')}>
        <HTML html={t(i18nKeys.FORM.CHOOSE_THIRD_PARTY_TYPE.DESCRIPTION)} />
      </div>
      <div className={styles('thirdparties')}>
        <div className={styles('cards')}>
          {thirdParties.callcenter.canBeAccessed && (
            <ThirdPartyCard
              isDisabled={!thirdParties.callcenter.canBeAccessed}
              title={t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE.CALLCENTER)}
              onClick={() => setSelectedThirdPartyType(ThirdPartyType.CALL_CENTER)}
              isSelected={selectedThirdPartyType === ThirdPartyType.CALL_CENTER}
              illustration={<Icon name={IconName.PHONE_CALL} size="25px" />}
            />
          )}
          <ThirdPartyCard
            isDisabled={!thirdParties.lawyer.canBeAccessed}
            title={t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE.LAWYER)}
            onClick={() => setSelectedThirdPartyType(ThirdPartyType.LAWYER)}
            isSelected={selectedThirdPartyType === ThirdPartyType.LAWYER}
            errorText={
              isOriginalInvoice
                ? undefined
                : t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.ORIGINAL_INVOICE_REQUIRED)
            }
            illustration={<Icon name={IconName.LAW} size="25px" />}
          />
          <ThirdPartyCard
            isDisabled={!thirdParties.bailiff.canBeAccessed}
            title={t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE.BAILIFF)}
            onClick={() => setSelectedThirdPartyType(ThirdPartyType.BAILIFF)}
            isSelected={selectedThirdPartyType === ThirdPartyType.BAILIFF}
            errorText={
              isOriginalInvoice
                ? undefined
                : t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.ORIGINAL_INVOICE_REQUIRED)
            }
            illustration={<Icon name={IconName.BALANCE} size="25px" />}
          />
          <ThirdPartyCard
            isDisabled={!thirdParties.debtCollector.canBeAccessed}
            title={t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE.DEBT_COLLECTOR)}
            onClick={() => setSelectedThirdPartyType(ThirdPartyType.DEBT_COLLECTOR)}
            isSelected={selectedThirdPartyType === ThirdPartyType.DEBT_COLLECTOR}
            errorText={
              isOriginalInvoice
                ? undefined
                : t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.ORIGINAL_INVOICE_REQUIRED)
            }
            illustration={<img src={debtCollectorIcon} alt="Debt collector" />}
          />
        </div>
        <div className={styles('tp-description')}>
          {selectedThirdPartyType && (
            <div>
              <h3>{t(i18nKeys.THIRD_PARTY_CASES.DESCRIPTIONS.WHAT)}</h3>
              <p>
                {t(
                  i18nKeys.THIRD_PARTY_CASES.DESCRIPTIONS[selectedThirdPartyType.toUpperCase()]
                    .FIRST,
                )}
              </p>
              <h3>{t(i18nKeys.THIRD_PARTY_CASES.DESCRIPTIONS.WHEN)}</h3>
              <HTML
                html={t(
                  i18nKeys.THIRD_PARTY_CASES.DESCRIPTIONS[selectedThirdPartyType.toUpperCase()]
                    .SECOND,
                )}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles('button-wrapper')}>
        <Button
          noMargin
          disabled={selectedThirdPartyType == null}
          label={t(i18nKeys.FORM.VALIDATE)}
          onClick={() => {
            onSubmit(selectedThirdPartyType!, thirdParties);
          }}
        />
      </div>
    </div>
  );
}
