import { useAtom } from 'jotai';
import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { useIgnoreReminder } from 'modules/Reminders/api/use-ignore-reminder';
import { useHistory, useParams } from 'react-router';

import { Button, Group, Text } from '@mantine/core';

import { resendReminderModalAtom } from './state';

export const IgnoreStep = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { ignoreReminder, isIgnoreReminderLoading } = useIgnoreReminder();
  const { id } = useParams<{ id: string }>();

  const [state, dispatch] = useAtom(resendReminderModalAtom);

  const onClickCancel = () => dispatch({ type: 'close' });
  const onClickIgnore = () => {
    ignoreReminder(
      { id: String(state.reminder!.id) },
      {
        onSuccess: () => {
          onClickCancel();
          if (id) history.goBack();
        },
      },
    );
  };

  return (
    <>
      <Text>{t(i18nKeys.FAILED_REMINDERS.MODALS.IGNORE.DESCRIPTION)}</Text>
      <Group py="md" justify="end">
        <Button
          loading={isIgnoreReminderLoading}
          color="orange"
          variant="light"
          onClick={onClickCancel}
        >
          {t(i18nKeys.FAILED_REMINDERS.MODALS.CANCEL)}
        </Button>
        <Button loading={isIgnoreReminderLoading} onClick={onClickIgnore}>
          {t(i18nKeys.FAILED_REMINDERS.ACTION_IGNORE)}
        </Button>
      </Group>
    </>
  );
};

IgnoreStep.title = 'Ignore reminder'; // @translate
