import { useState } from 'react';
import { useAtom } from 'jotai';
import { i18nKeys, TranslateFunction, useTranslation } from 'locales/index';
import { useHistory } from 'react-router';
import { useProfile } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { Id } from 'types/index';

import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Combobox,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
  Tooltip,
  useCombobox,
} from '@mantine/core';
import { useSessionStorage } from '@mantine/hooks';
import {
  IconChevronDown,
  IconSend,
  IconSendOff,
  IconSortAscending,
  IconSortDescending,
} from '@tabler/icons-react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { LightReminder, useLoadFailedReminders } from '../api';
import { useIgnoreReminder } from '../api/use-ignore-reminder';
import { SentMediumBadge } from '../components';
import { ResendReminderModal, resendReminderModalAtom } from '../components/ResendReminderModal';

const columnHelper = createColumnHelper<LightReminder>();

const useBuildColumns = (
  order: 'asc' | 'desc',
  onToggleOrder: VoidFunction,
  t: TranslateFunction,
) => {
  const { ignoreReminderVariables } = useIgnoreReminder();
  const [_, dispatch] = useAtom(resendReminderModalAtom);

  const onClickResend = (reminder: LightReminder) => {
    dispatch({ type: 'open', reminder });
  };

  const onClickIgnore = (reminder: LightReminder) => {
    dispatch({ type: 'ignore', reminder });
  };

  return [
    columnHelper.accessor('reference', {
      header: t(i18nKeys.REMINDER_LIST.HEADERS.REFERENCE),
      cell: (reference) => {
        const row = reference.row.original;

        const shouldShowBadge = row.invoiceCount > 1;
        return (
          <Group>
            {reference.getValue()}
            {shouldShowBadge && <Badge variant="light">{row.invoiceCount}</Badge>}
          </Group>
        );
      },
    }),
    columnHelper.accessor('sentAt', {
      header: () => (
        <Group onClick={onToggleOrder} style={{ cursor: 'pointer', userSelect: 'none' }}>
          {t(i18nKeys.REMINDER_LIST.HEADERS.SENT_AT)}
          <ActionIcon variant="transparent" color="gray.6">
            {order === 'asc' ? <IconSortAscending size={14} /> : <IconSortDescending size={14} />}
          </ActionIcon>
        </Group>
      ),
      cell: (sentAt) => sentAt.getValue().format('DD/MM/YYYY'),
    }),
    columnHelper.accessor('debtorFullName', {
      header: t(i18nKeys.REMINDER_LIST.HEADERS.DEBTOR),
    }),
    columnHelper.accessor('reminderType', {
      header: t(i18nKeys.REMINDER_LIST.HEADERS.REMINDER_TYPE),
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => (
        <Group>
          <Tooltip label={t(i18nKeys.FAILED_REMINDERS.ACTION_IGNORE)} position="left" withArrow>
            <ActionIcon
              loading={ignoreReminderVariables?.id === String(row.original.id)}
              size="md"
              color="gray"
              variant="light"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onClickIgnore(row.original);
              }}
            >
              <IconSendOff stroke={1.5} size={16} />
            </ActionIcon>
          </Tooltip>

          <Button
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              onClickResend(row.original);
            }}
            variant="light"
            size="xs"
            leftSection={<IconSend stroke={1.5} size={16} />}
          >
            {t(i18nKeys.FAILED_REMINDERS.ACTION_RESEND)}
          </Button>
        </Group>
      ),
    }),
  ];
};

interface FailedRemindersListProps {}

export const FailedRemindersList = ({}: FailedRemindersListProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const profile = useProfile();
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const [pageLimit, setPageLimit] = useSessionStorage({
    key: 'remindersIndexPageLimit',
    defaultValue: profile.preferences.itemsPerPage,
  });

  const { failedRemindersIndex } = useLoadFailedReminders({
    page: currentPage,
    pageLimit,
  });

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleToggleOrder = () => {
    setOrder((currentOrder) => (currentOrder === 'asc' ? 'desc' : 'asc'));
  };

  const columns = useBuildColumns(order, handleToggleOrder, t);

  const table = useReactTable({
    data: failedRemindersIndex!.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const options = [10, 20, 50, 100].map((item) => (
    <Combobox.Option value={item.toString()} key={item}>
      {item}
    </Combobox.Option>
  ));

  const firstItem = (currentPage - 1) * failedRemindersIndex!.pagination.pageLimit + 1;
  const lastItem =
    (currentPage - 1) * failedRemindersIndex!.pagination.pageLimit +
    failedRemindersIndex!.data.length;

  const handleClickRow = (event: React.MouseEvent, rowId: Id) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(`/reminders/failed/${rowId}`, '_blank');
    } else {
      history.push(`/reminders/failed/${rowId}`);
    }
  };

  const handleMiddleClickRow = (event: React.MouseEvent, rowId: Id) => {
    if (event.button === 1) {
      window.open(`/reminders/failed/${rowId}`, '_blank');
    }
  };

  return (
    <>
      <Stack h="100%" gap={0}>
        <PageTitle>{t(i18nKeys.FAILED_REMINDERS.MENU_LABEL)}</PageTitle>
        <Card radius={8} p={0} shadow="xs">
          <Group style={{ overflowY: 'auto' }}>
            <Table
              highlightOnHover
              horizontalSpacing="md"
              verticalSpacing="sm"
              stickyHeader
              fz={12}
            >
              <Table.Thead bg="#fafafa" style={{ borderBottom: '2px solid #e3e3e3', fontSize: 14 }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Table.Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Table.Th key={header.id} fw={450}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </Table.Th>
                    ))}
                  </Table.Tr>
                ))}
              </Table.Thead>
              <Table.Tbody>
                {table.getRowModel().rows.map((row) => (
                  <Table.Tr
                    key={row.id}
                    onClick={(event) => handleClickRow(event, row.original.id)}
                    onAuxClick={(event) => handleMiddleClickRow(event, row.original.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <Table.Td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Table.Td>
                    ))}
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </Group>
          <Group
            justify="space-between"
            p="sm"
            style={(theme) => ({
              border: `1px solid ${theme.colors.gray[2]}`,
            })}
          >
            <Group>
              <Text size="sm">
                {firstItem} - {lastItem} {t(i18nKeys.OF)}{' '}
                {failedRemindersIndex!.pagination.totalObjects}
              </Text>
              <Combobox
                store={combobox}
                width={250}
                position="bottom-start"
                withArrow
                onOptionSubmit={(val) => {
                  setPageLimit(Number(val));
                  combobox.closeDropdown();
                }}
              >
                <Combobox.Target>
                  <Group
                    onClick={() => combobox.toggleDropdown()}
                    style={{ cursor: 'pointer' }}
                    gap={4}
                  >
                    <Text size="sm">
                      {pageLimit} {t(i18nKeys.PER_PAGE)}
                    </Text>
                    <IconChevronDown stroke={1.5} size={16} />
                  </Group>
                </Combobox.Target>

                <Combobox.Dropdown>
                  <Combobox.Options>{options}</Combobox.Options>
                </Combobox.Dropdown>
              </Combobox>
            </Group>

            <Pagination
              value={currentPage}
              onChange={(page) => setCurrentPage(page)}
              total={failedRemindersIndex!.pagination.totalPages}
              size="xs"
            />
          </Group>
        </Card>
      </Stack>
      <ResendReminderModal />
    </>
  );
};
