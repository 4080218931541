import { Id } from 'api/models';
import { AxiosInstance } from 'axios';
import { mapKeys, omit, toCamelCase } from 'remeda';
import { CamelCasedProperties } from 'type-fest';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import { ApiError } from './utils/types';
import { addResourceNameToQueryResult, useLegacyAxiosInstance } from './utils';

const SaldoSchema = z
  .object({
    localized_total: z.coerce.number(),
    EUR: z.string().optional(),
    USD: z.string().optional(),
    GBP: z.string().optional(),
    CHF: z.string().optional(),
    SEK: z.string().optional(),
    JPY: z.string().optional(),
    NOK: z.string().optional(),
  })
  .transform((obj) => ({
    ...omit(obj, ['localized_total']),
    localizedTotal: obj.localized_total,
  }));

const FinancialDataSchema = z
  .object({
    saldo: z.record(z.union([z.string().regex(/^\d+$/), z.literal('total')]), SaldoSchema),
    saldo_one_week: SaldoSchema,
    saldo_one_month: SaldoSchema,
  })
  .transform((obj) => mapKeys(obj, toCamelCase) as CamelCasedProperties<typeof obj>);

const RecentActivitiesSchema = z
  .object({
    invoices_to_treat: z.number(),
    payment_count: z.number(),
    ongoing_tasks_count: z.number(),
    unprocessed_emails_count: z.number(),
    unread_third_party_messages_count: z.number(),
    failed_reminders_count: z.number(),
  })
  .transform((obj) => mapKeys(obj, toCamelCase) as CamelCasedProperties<typeof obj>);

const DashboardSchema = z.object({
  data: z
    .object({
      financial_data: FinancialDataSchema,
      recent_activities: RecentActivitiesSchema,
    })
    .transform((obj) => mapKeys(obj, toCamelCase) as CamelCasedProperties<typeof obj>),
});

export type Dashboard = z.infer<typeof DashboardSchema>;
export type FinancialData = z.infer<typeof FinancialDataSchema>;
export type RecentActivities = z.infer<typeof RecentActivitiesSchema>;
export type Saldo = z.infer<typeof SaldoSchema>;

export async function LoadDashboardQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;
  const { data } = await instance.get('/dashboard', {
    params: { account_manager_id: queryKey[1] },
  });
  return DashboardSchema.parse(data);
}

export const useLoadDashboard = (variables: { accountManagerId?: Id }) => {
  const axiosInstance = useLegacyAxiosInstance();

  const queryResult = useQuery<Dashboard, ApiError>({
    queryKey: ['dashboard', variables.accountManagerId],
    queryFn: (context) => LoadDashboardQueryFn(axiosInstance, context),

    placeholderData: (previousData) => previousData,
  });

  return addResourceNameToQueryResult<Dashboard, ApiError, 'dashboard'>('dashboard', queryResult);
};
