import { RequestPaymentPlanMutationBody } from 'api';
import { Id } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { SubmitHandler } from 'react-hook-form';
import Amount from 'shared/components/Amount';
import { Button, ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { CurrencyCode } from 'types/currency';

import styleIdentifiers from './RequestPaymentPlanModal.module.scss';

const styles = classNames.bind(styleIdentifiers);

type RequestPaymentPlanModalProps = {
  invoices: any;
  invoiceId: Id;
  token: string;
  currency: CurrencyCode;
  amount: number;
  onSubmit: SubmitHandler<RequestPaymentPlanMutationBody>;
};

export default function RequestPaymentPlanModal({
  invoices,
  invoiceId,
  token,
  currency,
  amount,
  onSubmit,
}: RequestPaymentPlanModalProps) {
  const { t, currentLang } = useTranslation();

  const invoice_ids = invoices.map(({ id }) => id);

  const handleSubmit = () => {
    onSubmit({ invoice_ids, invoice_id: invoiceId, token });
  };

  const onCancel = () => {
    dialogHide(DialogShowId.CUSTOM);
  };

  return (
    <div className={styles('request-payment-plan-modal')}>
      {t(i18nKeys.AYS.REQUEST_PAYMENT_PLAN)}
      <ul className={styles('invoices-list')}>
        <li className={styles('list-header')}>
          <span>{t(i18nKeys.FORM.REFERENCE)}</span>
          <span>{t(i18nKeys.ISSUE_DATE)}</span>
          <span>{t(i18nKeys.DUE_DATE)}</span>
          <span>{t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE)}</span>
        </li>
        {invoices
          .filter(({ type }) => type === 'debtor_reaction')
          .map(
            ({
              id,
              reference,
              remaining_balance_with_fees,
              issue_date,
              due_date,
              localized_money_object: { remaining_balance_with_fees: localizedRemaining },
            }) => (
              <li className={styles('invoice')} key={id}>
                <span>{reference}</span>
                <span>{formattedDate(issue_date, currentLang)}</span>
                <span>{formattedDate(due_date, currentLang)}</span>
                <Amount
                  value={remaining_balance_with_fees}
                  localizedValue={localizedRemaining}
                  suffix={currency}
                  className={styles('blue')}
                />
              </li>
            ),
          )}
        <li className={styles('list-footer')}>
          <Amount
            value={amount}
            localizedValue={amount}
            suffix={currency}
            className={styles('blue')}
          />
        </li>
      </ul>
      <div className={styles('buttons-wrapper')}>
        <Button
          color={ButtonColor.GREY}
          type="submit"
          label={t(i18nKeys.CANCEL)}
          onClick={onCancel}
        />
        <Button
          type="submit"
          color={ButtonColor.BLUE}
          label={t(i18nKeys.CONFIRM)}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
