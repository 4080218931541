import { AxiosInstance } from 'axios';
import {
  addActionNameToMutationResult,
  ApiError,
  ApiResponse,
  useAxiosInstance,
} from 'shared/hooks/utils';

import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type IgnoreReminderVariables = {
  id: string;
};

export async function ignoreReminderFn(
  axiosInstance: Promise<AxiosInstance>,
  { id }: IgnoreReminderVariables,
): Promise<ApiResponse> {
  const instance = await axiosInstance;

  return instance.put(`/payment_requests/${id}/ignore`);
}

export function useIgnoreReminder() {
  const client = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, IgnoreReminderVariables>({
    mutationFn: (variables) => ignoreReminderFn(axiosInstance, variables),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
      client.invalidateQueries({
        queryKey: ['reminders'],
      });
    },
    onError: (error: ApiError) => {
      notifications.show({
        color: 'red',
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
      });
    },
  });

  return addActionNameToMutationResult<
    ApiResponse,
    ApiError,
    IgnoreReminderVariables,
    'ignoreReminder'
  >('ignoreReminder', mutation);
}
