import accountReducer from './account/account.reducer';
import appReducer from './app/app.reducer';
import clientReducer from './client/client.reducer';
import colleagueReducer from './colleague/colleague.reducer';
import creditNoteReducer from './creditNote/creditnote.reducer';
import invoiceReducer from './invoice/invoice.reducer';
import onBoardingReducer from './onBoarding/onBoarding.reducer';
import productReducer from './product/product.reducer';
import settingsReducer from './settings/settings.reducer';
import transactionReducer from './transaction/transaction.reducer';
import viewReducer from './view/view.reducer';

export const account = accountReducer;
export const app = appReducer;
export const client = clientReducer;
export const colleague = colleagueReducer;
export const creditNote = creditNoteReducer;
export const invoice = invoiceReducer;
export const product = productReducer;
export const transaction = transactionReducer;
export const view = viewReducer;
export const onBoarding = onBoardingReducer;
export const settings = settingsReducer;
